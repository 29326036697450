/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
@media (min-width: 768px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
@media (min-width: 1440px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}
.container.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.spinnerContainer, .emptyText {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .spinnerContainer, .emptyText {
    padding-top: 32px;
  }
}

.emptyText {
  font-size: 18px;
  opacity: 0.7;
}