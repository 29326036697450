.container {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #181936;
    cursor: pointer;

    background-image: url("../../../img/icons/edit-profile-icon.svg");
    background-position: right;
    background-size: 15px 15px;
    background-repeat: no-repeat;

    margin-bottom: 7px;
    display: block;
}
