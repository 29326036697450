/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 65px;
  background-color: #EEEEEE;
  top: 0;
  color: #ffffff;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    background-color: #EEEEEE;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../img/icons/logo-mobile.svg");
  height: 100%;
  width: 130px;
}
@media (min-width: 1440px) {
  .logo {
    width: 166px;
    height: 44px;
    background-image: url("../../img/header-logo-grey.svg");
  }
}