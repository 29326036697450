/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 24px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .container {
    padding-top: 31px;
    padding-left: 60px;
    padding-right: 60px;
  }
}