/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.payBlockInfo {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #F5F5F5;
  cursor: pointer;
  user-select: none;
}
@media (max-width: calc(1024px - 1px)) {
  .payBlockInfo {
    max-width: 20%;
  }
}
.payBlockInfo p {
  font-size: 13px;
  line-height: 15px;
}
@media (max-width: calc(1024px - 1px)) {
  .payBlockInfo p {
    padding: 0;
    margin-right: 10px;
    font-weight: 500;
    font-size: 10px;
  }
}
.payBlockInfo div {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  width: 200px;
}
@media (max-width: calc(1024px - 1px)) {
  .payBlockInfo div {
    width: 130px;
  }
}

.active {
  border-bottom: 1px solid red;
}

.listContainer {
  min-height: 0 !important;
}

.payButton {
  background: #B6B6B6;
}

.buttons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  border-top: 1px solid #F5F5F5;
  margin-top: 170px;
}
@media (max-width: calc(1440px - 1px)) {
  .buttons {
    margin-top: 0;
  }
}
.buttons Button {
  width: 48%;
}
@media (max-width: calc(1024px - 1px)) {
  .buttons Button {
    width: 100%;
  }
}