/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.checkbox {
  line-height: 15px;
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox:not(:first-of-type) {
  margin-left: 24px;
}
.checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #FF3B30;
  border: none;
}
.checkbox input[type=checkbox]:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #989898;
  height: 12px;
  width: 12px;
}
@media (min-width: 1440px) {
  .checkbox .checkmark {
    height: 16px;
    width: 16px;
    top: 0;
  }
  .checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url("../../../img/icons/check-icon.svg");
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: center;
  }
}
.checkbox:hover .checkmark {
  background-color: #ccc;
}

.filterCheckbox {
  line-height: 15px;
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filterCheckbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filterCheckbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #FF3B30;
}
.filterCheckbox input[type=checkbox]:checked ~ .checkmark:after {
  display: block;
}
.filterCheckbox .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #DFDFDF;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .filterCheckbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
.filterCheckbox:hover .checkmark {
  background-color: #ccc;
}