/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.btn {
  display: flex;
  flex-direction: row;
  width: 10px;
  height: 10px;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  margin-right: 16px;
}

.elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 21px;
}

.dates {
  margin-top: 48px;
  width: 100%;
}

.dateRange {
  display: none;
}

.calendar {
  display: block;
}

.playlistTimelineItem {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-left: 50px;
}

.hours {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -25%;
  left: 0;
}

.playlistEmpty {
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #F8F8F8;
}
.playlistEmpty:hover {
  background: #dfdfdf;
  width: 100%;
}

.add {
  background-color: inherit;
  outline: none;
  border: 0;
  cursor: pointer;
}

.href {
  width: 100%;
}

.linkPlaylist {
  width: 100%;
}