/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.popupCloseOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
.container .popup {
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.container .popup:after, .container .popup:before {
  bottom: 100%;
  right: 4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.container .popup:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 20px;
  margin-left: -8px;
}
.container .popup:before {
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}
@media (min-width: 1440px) {
  .container .popup {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    right: -13px;
    top: 39px;
  }
  .container .popup:before, .container .popup:after {
    content: none;
  }
}