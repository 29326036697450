/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  width: 100%;
  height: 32px;
  position: relative;
  padding-right: 16px;
  padding-left: 28px;
  display: flex;
  justify-content: flex-end;
  user-select: none;
}
@media (min-width: 1440px) {
  .container {
    width: 160px;
    padding-right: 16px;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    margin-top: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.checkbox {
  position: relative;
}
@media (min-width: 1440px) {
  .checkbox {
    position: absolute;
    left: 8px;
  }
}

.text {
  display: none;
}
@media (min-width: 1440px) {
  .text {
    display: block;
    margin-left: 12px;
    font-size: 16px;
    line-height: 19px;
    color: #6C6C6C;
  }
}