@import "breakpoints";

@mixin min-width($class) {
    @if $class == sm {
        @media (min-width: $min-sm) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: $min-md) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: $min-lg) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: $min-xl) {
            @content;
        }
    } @else {
        @warn "min-width mixin only supports: sm, md, lg, xl";
    }
}

@mixin max-width($class) {
    @if $class == xs {
        @media (max-width: calc(#{$min-sm} - 1px)) {
            @content;
        }
    } @else if $class == sm {
        @media (max-width: calc(#{$min-md} - 1px)) {
            @content;
        }
    } @else if $class == md {
        @media (max-width: calc(#{$min-lg} - 1px)) {
            @content;
        }
    } @else if $class == lg {
        @media (max-width: calc(#{$min-xl} - 1px)) {
            @content;
        }
    } @else {
        @warn "max-width mixin only supports: xs, sm, md, lg";
    }
}

@mixin media($class) {
    @if $class == xs {
        @include max-width(xs) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: $min-sm) and (max-width: calc(#{$min-md} - 1px)) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: $min-md) and (max-width: calc(#{$min-lg} - 1px)) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: $min-lg) and (max-width: calc(#{$min-xl} - 1px)) {
            @content;
        }
    } @else if $class == xl {
        @include min-width(xl) {
            @content;
        }
    } @else {
        @warn "media mixin only supports: xs, sm, md, lg, xl";
    }
}

@mixin lg-standard-padding() {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
}

@mixin xs-standard-padding() {
    padding-top: 20px;
}

@mixin standard-page() {
    @include xs-standard-padding();

    @include min-width(lg) {
        @include lg-standard-padding();
    }
}

@mixin block-padding() {
    padding-left: 16px;
    padding-right: 16px;

    @include min-width(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin standard-list() {
    padding-left: 16px;
    padding-right: 16px;

    @include min-width(sm) {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    @include min-width(lg) {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin responsive-card($sm-row-padding: 16px, $lg-row-padding: 26px) {
    @include min-width(sm) {
        width: calc((100% - #{$sm-row-padding})/2);

        &:nth-child(odd) {
            margin-right: $sm-row-padding;
        }
    }

    @include min-width(lg) {
        width: calc((100% - #{$lg-row-padding})/2);

        &:nth-child(odd) {
            margin-right: $lg-row-padding;
        }
    }
}

/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
