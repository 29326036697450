/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background-color: #FAFAFA;
  height: 106px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 30px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 30px;
  }
}
@media (min-width: 1440px) {
  .card {
    height: 106px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 13px;
  }
}

.head {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background-image: url("../../../img/icons/broadcast-card-pause-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dataContainer {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  position: relative;
}
@media (min-width: 1440px) {
  .dataContainer {
    padding-left: 14px;
    padding-right: 14px;
    justify-content: space-between;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
}
.infoBlock:not(:first-of-type) {
  margin-top: 9px;
}

.infoTitle {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
}

.deleteButton {
  position: absolute;
  height: 15px;
  width: 15px;
  top: calc(50% - 7px);
  right: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjEwLjk2IiB5MT0iMC4zNTM1NTMiIHgyPSIwLjM1MzM5NiIgeTI9IjEwLjk2MDIiIHN0cm9rZT0iYmxhY2siLz4KPGxpbmUgeDE9IjEwLjk1OTkiIHkxPSIxMC45NjAxIiB4Mj0iMC4zNTMzMjMiIHkyPSIwLjM1MzUxOCIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4K");
  cursor: pointer;
}