/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.backgroundContainer {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../../img/authBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 68px;
  width: 95%;
  background-image: url("../../img/header-logo.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.modalContainer {
  margin-top: 72px;
}

.passwordOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rememberPassword {
  display: flex;
  font-size: 12px;
  line-height: 25px;
  color: #6C6C6C;
}
@media (min-width: 1440px) {
  .rememberPassword {
    font-size: 16px;
  }
}
.rememberPassword .checkbox {
  margin-bottom: 0;
}
@media (max-width: calc(1440px - 1px)) {
  .rememberPassword .checkbox {
    padding-top: 1px;
  }
}
@media (max-width: calc(768px - 1px)) {
  .rememberPassword .checkbox {
    padding-top: 2px;
  }
}

.restorePasswordLink {
  font-size: 12px;
  line-height: 25px;
  color: #E53935;
}
@media (min-width: 1440px) {
  .restorePasswordLink {
    font-size: 16px;
    line-height: 25px;
    color: #E53935;
  }
}