/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.button {
  position: fixed;
  right: 18px;
  bottom: -150px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-image: url("../../img/icons/scroll-top-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  transition: bottom 0.4s ease-in-out;
}
@media (min-width: 1440px) {
  .button {
    display: none;
  }
}
.button.visible {
  bottom: 100px;
}