/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.title {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
}

.weekDays {
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
}

.weekDayItem {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background-color: #F4F4F4;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 30;
}

.weekDayItemActive {
  background-color: #FF3B30;
  color: #FFFFFF;
}