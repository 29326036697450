/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  min-height: 100vh;
  padding-top: 65px;
}
@media (min-width: 1440px) {
  .container {
    padding-top: 103px;
    background-color: #FAFAFA;
  }
}