/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .pageTitle {
    border-bottom: none;
  }
}

@media (min-width: 1440px) {
  .pageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D7D7D7;
  }
}

.formControls {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
}
@media (min-width: 1440px) {
  .formControls {
    display: none;
  }
}

.formButton {
  height: 30px;
  font-size: 14px;
  width: 110px;
}

.datepickerContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.datepickerContainer.visible {
  display: flex;
}
@media (min-width: 1440px) {
  .datepickerContainer {
    display: flex;
    padding-top: 0;
    width: 327px;
  }
}

.datepickerButton {
  margin-top: 61px;
  width: 180px;
}

.dateInput {
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .dateInput {
    margin-top: 24px;
    max-width: 60%;
  }
}
@media (min-width: 1440px) {
  .dateInput {
    display: flex !important;
    align-items: baseline !important;
  }
  .dateInput label {
    color: #000000;
  }
  .dateInput input {
    text-align: center;
  }
  .dateInput input[type=time] {
    padding-right: 48px;
  }
}

.timePicker {
  margin-top: 24px;
}
@media (min-width: 1440px) {
  .timePicker {
    display: none;
  }
}

.hintText {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #8D8D8D;
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .hideMobile {
    display: none !important;
  }
}