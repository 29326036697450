/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  width: 100%;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  height: 91px;
}
@media (min-width: 768px) {
  .card.responsive {
    width: calc((100% - 16px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card.responsive {
    width: calc((100% - 32px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 32px;
  }
}

.cardHead {
  background-color: #FFFFE7;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.cardIncome {
  background-color: #E8EAF6;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  line-height: 20px;
  color: #000000;
}

.operationType {
  font-size: 13px;
}

.price {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.icon {
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.icon.income {
  background-image: url("../../../img/icons/balance-income-icon.svg");
}
.icon.payment {
  transform: rotate(180deg);
  background-image: url("../../../img/icons/balance-payment-icon.svg");
}