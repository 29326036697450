.select :global(.select__control) {
  background-color: #FFFFFF;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
  box-shadow: none;
}
.select :global(.select__control):hover, .select :global(.select__control):active {
  box-shadow: none;
  border-color: #E53935;
}
.select :global(.select__option):hover, .select :global(.select__option):active {
  background-color: #FAFAFA;
}
.select :global(.select__option--is-focused) {
  background-color: #FAFAFA;
}