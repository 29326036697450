/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  padding-left: 16px;
  padding-right: 48px;
  margin-bottom: 25px;
}
@media (min-width: 1440px) {
  .container {
    padding: 0;
    margin-bottom: 14px;
  }
}

.link:before {
  content: " / ";
  color: #000000;
}
.link:first-of-type:before {
  content: none;
}
.link:last-of-type {
  color: #989898;
}
.link:only-child {
  color: #000000;
}