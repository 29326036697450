.profileButton {
    box-shadow: none;
    border-radius: 5px;
    background-color: #FFFFFF;
    color: #E53935;
    border: 1px #E53935 solid;

    &:active, &:hover {
        color: #E53935;
    }

    &.primary {
        background-color: #E53935;
        color: #FFFFFF;
    }

    &.fullWidth {
        margin: 0;
        width: 100%;

        &:not(:first-of-type) {
            margin-top: 16px;
        }
    }
}
