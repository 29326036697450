@import '../../../styles/mixins';
@import url('https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap');

.container {
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
    @include standard-page();
}

.formContainer {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
}

.choice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.choiceObjects {
    display: flex;
    flex-direction: column;
}

.objects {
    font-size: 13px;
    line-height: 15px;
    font-weight: normal;
    margin: 0;
}

.objectsCount {
    opacity: 0.5;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 15px;
}

.choiceButton {
    width: 108px;
    height: 36px;
}

.profileButton {
    width: 100%;

    @include min-width(sm) {
        width: 20%;
    }
}

.resultContainer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 27px;
    width: 100%;
}

.resultTitle {
    width: 60%;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.resultDesc {
    width: 283px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #6C6C6C;
    margin-top: 12px;
}

.resultButtons {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 21px; 
}

.orderButton{
    width: 100%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;

    @include min-width(sm) {
        width: 343px;
    }
}

.link {
    width: 100%;
}