.react-calendar-timeline {

    .rct-header-root {
        background: transparent;
        border-bottom: 1px solid #BBBBBB;
    }

    .rct-dateHeader {
        background: transparent;
        border-bottom: none;
        border-left: 1px solid #BBBBBB;
        font-size: 16px;
        line-height: 16px;
    }

    .rct-calendar-header {
        border: none;
    }

    .rct-sidebar {
        .rct-sidebar-row {
            border-bottom: 1px solid #BBBBBB;
            font-size: 14px;
            line-height: 16px;
        }

        .rct-sidebar-row-even, .rct-sidebar-row-odd {
            background: transparent !important;
        }
    }

    .rct-horizontal-lines {
        .rct-hl-even, .rct-hl-odd {
            background: transparent;
        }
    }

    .rct-vertical-lines {
        .rct-vl {
            background: transparent !important;
        }

        .rct-vl-first {
            border-left-width: 1px !important;
        }

    }

    .rct-item {
        border-radius: 10px;
        cursor: pointer;
    }

}
