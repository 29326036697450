/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 1440px) {
  .container {
    width: 50%;
    justify-content: flex-end;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.addButton, .dropdownButton {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  user-select: none;
}

.addButton {
  height: 44px;
  width: 44px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url("../../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
}
@media (min-width: 1440px) {
  .addButton {
    margin-right: 25px;
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}

.dropdownButton {
  padding: 13px 58px 14px 40px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #E53935;
  background-image: url("../../../../img/icons/open-dropdown-icon.svg");
  background-repeat: no-repeat;
  background-size: 10px 7px;
  background-position: right 40px center;
  height: 44px;
}

.dropdownPopupContainer {
  width: 100%;
}

.dropdownPopup {
  top: 34px;
  border: 1px solid #D9D9D9;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .dropdownPopup {
    right: 0 !important;
    top: 36px !important;
  }
}
.dropdownPopup:before {
  border: none !important;
}
.dropdownPopup:after {
  border: none !important;
}

.popupButton {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  height: 50px;
  border-top: 1px solid #B3B3B3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupButton:first-of-type {
  border-top: none;
}