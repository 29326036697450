/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: 100px;
    padding-right: 100px;
  }
}