/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.title {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
}

.monthRepeatSelect {
  display: flex;
  padding: 8px 10px;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .monthRepeatSelect {
    flex-wrap: nowrap;
  }
}

.selectionItem {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: 45px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #F4F4F4;
  cursor: pointer;
}
@media (max-width: calc(1440px - 1px)) {
  .selectionItem {
    width: 40%;
  }
}

.selectionItemActive {
  color: #FFFFFF;
  background-color: #E53935;
}