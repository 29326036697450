/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.sectionRoute {
  display: none;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
  margin-right: 10px;
  user-select: none;
}
.sectionRoute.active {
  display: block;
}
@media (min-width: 1440px) {
  .sectionRoute {
    display: block;
    font-size: 17.5px;
    line-height: 25px;
    color: #8B8C9A;
    margin-left: 51px;
    margin-right: 50px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: none;
  }
  .sectionRoute:hover {
    color: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
  }
  .sectionRoute.active {
    text-transform: none;
    color: #000000;
  }
  .sectionRoute.active:hover {
    color: #000000;
  }
}

.icon {
  height: 20px;
  width: 17px;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .icon {
    display: none;
  }
}

.menu {
  height: calc(100vh - 65px);
  padding-top: 10px;
  opacity: 0;
  position: fixed;
  top: 65px;
  background-color: #F5F5F5;
  font-size: 17.5px;
  right: -100%;
  width: 100vw;
  transition: right 0.3s;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .menu {
    width: 350px;
  }
}

.menuVisible {
  overflow-y: scroll;
  opacity: 1;
  right: 0;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .menuVisible {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .menuVisible {
    display: none;
  }
}

.item {
  color: #8B8C9A;
  background-color: #EEEEEE;
  padding: 16px;
  margin-top: 10px;
  display: block;
}
.item:focus, .item:active, .item:hover {
  color: #8B8C9A;
}

.item:first-of-type {
  margin-top: 0;
}

.profile {
  margin-top: 10px;
  padding-left: 48px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-image: url("../../../img/icons/profile-icon.svg");
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
  color: #8B8C9A;
}
.profile:hover {
  background-image: url("../../../img/icons/profile-icon-active.svg");
}

.subItem {
  display: block;
  color: #000000;
  padding: 28px 40px;
}

.tabs {
  display: none;
}
@media (min-width: 1440px) {
  .tabs {
    display: flex;
    justify-content: center;
    position: absolute;
    background: #FAFAFA;
    color: #BBBBBB;
    overflow: hidden;
    height: 41px;
    top: 100%;
    left: calc((100vw - 1150px) / 2);
    right: calc((100vw - 1150px) / 2);
  }
}

.tabsItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-basis: 0;
  flex-grow: 1;
  cursor: pointer;
  white-space: nowrap;
}
.tabsItem:last-of-type {
  border-right: none;
}
.tabsItem:hover {
  color: #E53935;
}

.tabsItemSelected {
  border-bottom: 2px solid #E53935;
  color: #E53935;
}

@media (min-width: 1440px) {
  .profileIcon {
    display: block;
    background-image: url("../../../img/icons/profile-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
  .profileIcon.active, .profileIcon:hover {
    background-image: url("../../../img/icons/profile-icon-active.svg");
  }
}

.backgroundFill {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  right: 0;
  left: 0;
  top: 65px;
  transition: opacity 0.4s linear;
}
.backgroundFill.visible {
  opacity: 1;
}
@media (min-width: 1440px) {
  .backgroundFill {
    display: none;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}