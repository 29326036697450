/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: calc(768px - 1px)) {
  .breadcrumbs {
    padding-right: 10px;
  }
}

.search {
  width: 100%;
}
@media (min-width: 1440px) {
  .search {
    width: 100% !important;
  }
}

.playlists {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  column-gap: 38px;
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .playlists {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media (max-width: calc(1024px - 1px)) {
  .playlists {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}

.playlistLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noPlaylists {
  padding-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: 0.7;
}