/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .form {
    padding: 0;
  }
}
.form textarea {
  min-height: 287px;
}

.nameInput {
  font-size: 19px;
  line-height: 22px;
  color: #000000;
  font-weight: 500;
}

.buttonsContainer {
  margin-top: 12px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    padding: 0;
    margin-top: 67px;
    justify-content: center;
  }
}

@media (min-width: 1440px) {
  .saveButton {
    width: 200px !important;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none !important;
  }
}