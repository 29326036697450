/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background-color: #FAFAFA;
  height: 126px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 40px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .card {
    border: 1px solid #D9D9D9;
    height: 161px !important;
    box-shadow: none;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.head {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1440px) {
  .head {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../img/icons/maintenance-card-icon.svg");
}
@media (min-width: 1440px) {
  .icon {
    width: 30px;
    height: 30px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
}
@media (min-width: 1440px) {
  .infoContainer {
    padding-left: 18px;
    padding-right: 18px;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
  }
}

.infoBlock {
  display: flex;
  justify-content: space-between;
}
.infoBlock:not(:first-of-type) {
  margin-top: 9px;
}

.infoTitle {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
}