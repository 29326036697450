/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.pageContent {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .pageContent {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1440px) {
  .responsivePart {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .infoContainer {
    width: 330px;
    min-width: 330px;
  }
}

@media (min-width: 1440px) {
  .descContainer {
    margin-left: 112px;
    padding-top: 11px;
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  background-image: url("../../../../img/icons/mediaplan-icon-grey.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 22px;
  width: 22px;
  margin-right: 14px;
}

.menuButton {
  position: relative;
  background-image: url("../../../../img/icons/dots-menu-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .menuButton {
    display: none;
  }
}

.id {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.titleContainer {
  margin-top: 22px;
  margin-bottom: 12px;
}
@media (min-width: 1440px) {
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
    margin-bottom: 20px;
  }
}

.title {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #000000;
}

.buttonsContainer {
  display: none;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    display: flex;
  }
}

.button {
  height: 36px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: linear-gradient(79.8deg, #FF1744 0%, #FF5252 100%);
  border-radius: 2px;
  margin-left: 14px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  user-select: none;
}
@media (min-width: 768px) {
  .button:first-child {
    margin-left: 0;
  }
}
@media (min-width: 1440px) {
  .button:first-child {
    margin-left: 14px;
  }
}
.button:hover {
  color: #FFFFFF;
}

.standardText {
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #6C6C6C;
}

.dateContainer {
  margin-bottom: 18px;
}
@media (min-width: 1440px) {
  .dateContainer {
    margin-bottom: 30px;
  }
}

.date {
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 8px;
}

.loadStatus {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #989898;
  color: #3C2E2E;
  font-weight: 300;
  font-size: 13px;
}
@media (min-width: 1440px) {
  .loadStatus {
    width: 40px;
    height: 40px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
  }
}

.mediaplanContentContainer {
  background: #FFF8F8;
  border-radius: 12px;
  border: 1px solid #FF1744;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 4px 4px rgba(255, 23, 68, 0.25);
  overflow: hidden;
  height: 92px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .mediaplanContentContainer {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .mediaplanContentContainer {
    margin-top: 21px;
  }
}

.mediaplanContentTitle {
  color: #FF1744;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
}

.mediaplanContentItem {
  width: 50%;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  height: 48px;
  background-image: linear-gradient(286.41deg, #FF1744 0%, #FF5252 99.99%, rgba(255, 23, 68, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  user-select: none;
}
.mediaplanContentItem:last-child {
  border-left: 1px solid #FFFFFF;
}
.mediaplanContentItem:hover {
  color: #FFFFFF;
}

.mediaplanContentNumber {
  font-weight: 500;
  font-size: 32px;
  color: #FFFFFF;
}

.objectsIcon, .recordIcon {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
  width: 26px;
}

.objectsIcon {
  background-image: url("../../../../img/icons/object-icon-white.svg");
}

.recordIcon {
  background-image: url("../../../../img/icons/record-icon-white.svg");
}

.descTitle {
  margin-top: 21px;
  color: rgba(17, 0, 0, 0.4);
  margin-bottom: 21px;
}
@media (min-width: 1440px) {
  .descTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
    color: #110000;
  }
}

@media (min-width: 0) and (max-width: 1440px) {
  .hideMobile {
    display: none;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}

@media (min-width: 1440px) {
  .timelineButton {
    width: 100%;
    margin-left: 0;
    margin-top: 36px;
  }
  .timelineButton:hover {
    color: #FFFFFF;
  }
}

.popup {
  right: -16px;
  top: 30px;
}

@media (min-width: 768px) {
  .tabletColumnsContainer {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .tabletColumnsContainer {
    display: block;
    width: auto;
  }
}

@media (min-width: 768px) {
  .tabletColumn {
    width: 50%;
    padding-top: 22px;
  }
  .tabletColumn:nth-child(odd) {
    padding-right: 28px;
  }
  .tabletColumn:nth-child(even) {
    padding-left: 28px;
  }
}
@media (min-width: 1024px) {
  .tabletColumn:nth-child(odd) {
    padding-right: 100px;
  }
  .tabletColumn:nth-child(even) {
    padding-left: 100px;
  }
}
@media (min-width: 1440px) {
  .tabletColumn {
    width: auto;
    padding-top: 0;
  }
  .tabletColumn:nth-child(odd) {
    padding-right: 0;
  }
  .tabletColumn:nth-child(even) {
    padding-left: 0;
  }
}

.tabletButtons {
  display: none;
}
@media (min-width: 768px) {
  .tabletButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
  }
}
@media (min-width: 1440px) {
  .tabletButtons {
    display: none;
  }
}