/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.stickyWrapper {
  height: 82px;
  width: 100%;
}
@media (min-width: 1440px) {
  .stickyWrapper {
    height: 50px;
  }
}

.container {
  height: 82px;
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #C4C4C4;
  padding: 8px 16px 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: calc(1440px - 1px)) {
  .container.noWrap {
    flex-wrap: nowrap;
    align-items: flex-end;
  }
  .container.noWrap .recordsCount {
    flex-grow: unset;
  }
}
@media (min-width: 768px) {
  .container.fixed {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    position: absolute;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
  }
}

.buttonContainer {
  width: 100%;
}
@media (min-width: 1440px) {
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    width: 160px;
    order: 3;
  }
}

.button {
  cursor: pointer;
  width: 150px;
  color: #808080;
  background-color: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  user-select: none;
  text-align: center;
}
@media (min-width: 1440px) {
  .button {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.recordsCount {
  flex-grow: 1;
  margin-top: 10px;
  color: #C6C6C6;
  font-size: 16px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .recordsCount {
    order: 2;
    margin-left: 320px;
    margin-top: 0;
  }
}
.recordsCount .recordsNumber {
  color: #808080;
  font-weight: 500;
}
.recordsCount.right {
  text-align: right;
}

.pages {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .pages {
    margin-top: 0;
    order: 1;
  }
}

.pageNumber {
  margin-left: 12px;
  color: #C6C6C6;
  cursor: pointer;
  user-select: none;
}
.pageNumber:last-of-type {
  margin-right: 12px;
}
.pageNumber:first-child {
  margin-left: 18px;
}
.pageNumber:last-child {
  margin-right: 18px;
}
.pageNumber:hover {
  color: #808080;
}

.pageCurrent {
  color: #808080;
  font-weight: 500;
}

.backIcon, .nextIcon {
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 10px;
}

.backIcon {
  background-image: url("../../img/icons/back-page-icon.svg");
}

.nextIcon {
  background-image: url("../../img/icons/next-page-icon.svg");
}