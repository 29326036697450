/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  margin-top: 16px;
}
.container:first-of-type {
  margin-top: 0;
}

.title {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.rangeContainer {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

.value {
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  justify-content: center;
  color: #FF1744;
  width: 80px;
}

.range {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  margin-left: 20px;
  flex-grow: 1;
  background-color: transparent;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.range:focus {
  outline: none;
  background: none;
}
.range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.range::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.range::-ms-track {
  height: 2px;
  border: transparent;
}
.range::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.range::-ms-fill-lower {
  background: #E53935;
}
.range::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}