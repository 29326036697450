/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .container {
    justify-content: flex-start;
  }
}

.infoBlock {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.infoBlock.half {
  width: calc((100% - 16px) / 2);
}
@media (min-width: 1440px) {
  .infoBlock.half {
    width: 25%;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;
}

.name {
  display: flex;
  flex-direction: row;
}

.tariff {
  margin-left: 14px;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
}

.tariffBlock {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.value {
  color: #212121;
  margin-left: 40px;
}

.tariffBlockImage {
  width: 23px;
  height: 18px;
  margin-top: 10px;
  margin-right: 14px;
}

.documentLoad {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.documentBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: calc(1024px - 1px)) {
  .documentBlock {
    flex-direction: column;
    justify-content: center;
  }
}

.payment {
  width: 369.34px;
  margin-top: 17px;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: calc(1024px - 1px)) {
  .payment {
    width: 100%;
  }
}

.sectionTitle {
  padding-top: 7px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-size: 13px;
  color: #413333;
  margin-bottom: 16px;
  width: 100%;
}

.rateIcon, .maintenanceIcon {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1440px) {
  .rateIcon, .maintenanceIcon {
    display: none;
  }
}

.rateIcon {
  background-image: url("../../../img/icons/rate-icon.svg");
}

.maintenanceIcon {
  background-image: url("../../../img/icons/maintenance-icon.svg");
}

.maintenanceLink {
  display: block;
  width: 100%;
}
@media (min-width: 1440px) {
  .maintenanceLink {
    display: none;
  }
}