/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pageTitle {
  border-bottom: none;
  margin-top: 0;
}
@media (min-width: 1440px) {
  .pageTitle {
    border-bottom: 1px solid #D7D7D7;
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }
}

.form {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
}
@media (min-width: 1440px) {
  .form {
    padding-left: calc((100% - 545px) / 2);
    padding-right: calc((100% - 545px) / 2);
  }
}
.form textarea {
  min-height: 287px;
}

.selectWrapper {
  margin-top: 0;
}
.selectWrapper select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  margin-top: 48px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    justify-content: center;
  }
}

@media (min-width: 1440px) {
  .submitButton {
    width: 154px;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}