/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.elements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 21px;
}

.btn {
  display: flex;
  flex-direction: row;
  width: 10px;
  height: 10px;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  margin-right: 16px;
}

.dates {
  margin-top: 48px;
  width: 100%;
}

.dateRange {
  display: none;
}

.calendar {
  display: block;
}

.timeBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #d9d9d9;
}

.choiceTime {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkboxDay, .checkboxTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.listOptions {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
}

.blockTime {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .blockTime {
    flex-direction: row;
  }
}

.blockCheckbox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .blockCheckbox {
    width: 50%;
  }
}

.blockCheckboxTitle {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.endBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.row {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}
.row input {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 43.9px;
  height: 20px;
  background: #F4F4F4;
  border-radius: 5px;
  margin-right: 14px;
  margin-left: 14px;
  outline: none;
}

.buttons {
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #d9d9d9;
  margin-top: 30px;
}
@media (max-width: calc(1024px - 1px)) {
  .buttons {
    justify-content: space-between;
  }
}

.button {
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
}
@media (min-width: 1440px) {
  .button {
    display: none;
  }
}

.back, .ready, .save {
  width: 152px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  border: 0;
  cursor: pointer;
  margin-top: 11px;
}

.save {
  width: 30%;
}
@media (max-width: calc(768px - 1px)) {
  .save {
    width: 100%;
  }
}

.ready, .save {
  background: #FF3B30;
  color: #fff;
}

.back {
  margin-right: 10px;
}

.input {
  border: 0;
  outline: none;
  margin-left: 29px;
  margin-right: 29px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
  padding-right: 0;
  text-align: center;
  width: 70px;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (max-width: calc(1024px - 1px)) {
  .input {
    padding-right: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.input::-webkit-inner-spin-button, .input::-webkit-outer-spin-button, .input::-webkit-clear-button, .input::-webkit-calendar-picker-indicator {
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.lastInput {
  margin-right: 0;
  width: 80px;
}

.listCheckbox {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
}
.listCheckbox:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

.dropdownIcon {
  background-image: url("../../../../../img/icons/dropdown-icon-black.svg");
  transition: transform 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 10px;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
}
.dropdownIcon.open {
  transform: rotate(0);
}
@media (min-width: 1440px) {
  .dropdownIcon {
    transform: rotate(0);
  }
  .dropdownIcon.open {
    transform: rotate(180deg);
  }
}

.calendarPlaylistRepeat {
  background: #bebdbd;
}