/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 25px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #E53935;
  cursor: pointer;
  margin-top: 0;
}
.container:first-of-type {
  margin-top: 0;
}
@media (min-width: 768px) {
  .container {
    font-size: 18px;
    height: 40px;
  }
}

.primary {
  font-weight: bold;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #E53935;
}
@media (min-width: 1440px) {
  .primary {
    font-size: 20px;
  }
}