/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background-color: #FAFAFA;
  height: 120px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow: visible;
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 42px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 42px;
  }
}
@media (min-width: 1440px) {
  .card {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 190px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.head {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
@media (max-width: calc(1920px - 1px)) {
  .head {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.head.headOnline {
  background-color: #FCE9D4;
}

.headInfo {
  font-size: 11px;
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .headInfo {
    font-size: 14px;
  }
}

.menuButton {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url("../../../img/icons/dots-menu-icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.icon {
  width: 25px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1440px) {
  .icon {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.checkboxLeftContainer {
  position: relative;
  left: 14px;
  top: -16px;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .checkboxLeftContainer {
    margin-right: 16px;
  }
}

.contentContainer {
  padding-top: 7px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .contentContainer {
    padding-top: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  max-height: 50px;
}

.title {
  color: #000000;
}
@media (min-width: 1440px) {
  .title {
    font-size: 16px;
    line-height: 19px;
    width: 70%;
    display: block;
    min-height: 35px;
    margin-bottom: 40px;
  }
}

.player {
  vertical-align: top;
  display: flex;
  height: 100%;
  width: 25%;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  margin-top: 16px;
}
@media (min-width: 1440px) {
  .player {
    width: 60%;
  }
}

.playerIcon {
  height: 18px;
  width: 18px;
  background-image: url("../../../img/icons/play-icon-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.playerRange {
  display: none;
}
@media (min-width: 1440px) {
  .playerRange {
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    width: 70%;
    margin-left: 12px;
  }
}
.playerRange::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #E53935;
}
.playerRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange:focus {
  outline: none;
  background: none;
}
.playerRange::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.playerRange::-ms-track {
  height: 2px;
  border: transparent;
}
.playerRange::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.playerRange::-ms-fill-lower {
  background: #E53935;
}
.playerRange::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.playerIconDisabled {
  background-image: url("../../../img/icons/play-icon-disabled.svg");
}

.infoBlock {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  max-width: 71%;
}
@media (min-width: 1440px) {
  .infoBlock {
    max-width: 40%;
    line-height: 16px;
  }
}

.infoTitle {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
}

.recordPopup {
  right: -16px;
  top: 36px;
  z-index: 1000;
}
@media (min-width: 1440px) {
  .recordPopup {
    top: 36px !important;
  }
}