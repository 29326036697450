/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
@import url("https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap");
.container {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: calc(1024px - 1px)) {
  .breadcrumbs {
    margin-bottom: 5px;
  }
}

.playlistInfo {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.blockPlaylist {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.block {
  display: flex;
  flex-direction: column;
}

.image {
  margin-top: 20px;
  width: 100%;
}

.leftBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.title {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.titleLinks {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
@media (max-width: calc(768px - 1px)) {
  .titleLinks {
    flex-direction: column;
  }
}
.titleLinks .link {
  width: 100%;
  display: flex;
  justify-content: center;
}
.titleLinks .links {
  width: 100%;
  margin-left: 32px;
}
@media (max-width: calc(768px - 1px)) {
  .titleLinks .links {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .text p {
    margin-bottom: 35px;
  }
}

.infoPlaylist {
  display: flex;
  justify-content: space-between;
}

.infoButton {
  background: linear-gradient(266.23deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 100px;
  width: 199.03px;
  height: 39px;
  border: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
@media (max-width: calc(1024px - 1px)) {
  .infoButton {
    width: 129px;
    height: 29px;
  }
  .infoButton p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
}

.infoBlock,
.infoBlockObjects,
.play {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
}
.infoBlock span,
.infoBlockObjects span,
.play span {
  margin-top: 0px;
  margin-left: 4.4px;
  font-weight: 500;
}
.infoBlock p,
.infoBlockObjects p,
.play p {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.infoBlockObjects {
  user-select: none;
}
.infoBlockObjects img {
  width: 13px;
  height: 13px;
  margin-left: 8px;
}

.active, .noActive {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.activeCircle, .noActiveCircle {
  margin-left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.activeCircle {
  background: #02E30B;
}

.noActiveCircle {
  background: #FF3B30;
}

.icon {
  margin-right: 10px;
}

.player {
  vertical-align: top;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  margin-top: 8px;
  margin-bottom: 27px;
}
@media (min-width: 1440px) {
  .player {
    width: 100%;
  }
}

.imagePlaylistPlay {
  color: #FF3B30;
}

.playerIcon {
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-image: url("../../../img/icons/play-icon-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.playerRange {
  width: 100%;
}
@media (min-width: 1440px) {
  .playerRange {
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    margin-left: 12px;
  }
}
.playerRange::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #E53935;
}
.playerRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange:focus {
  outline: none;
  background: none;
}
.playerRange::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.playerRange::-ms-track {
  height: 2px;
  border: transparent;
}
.playerRange::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.playerRange::-ms-fill-lower {
  background: #E53935;
}
.playerRange::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.buttonDefault {
  cursor: pointer;
  width: 50%;
  background-color: #FF3B30;
  color: #fff;
  border: 0;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
}
@media (max-width: calc(1024px - 1px)) {
  .buttonDefault {
    width: 100%;
  }
}

.buttonNoStart {
  display: flex;
  align-items: center;
  width: 50%;
  background: rgba(0, 0, 0, 0.1);
  color: #B6B6B6;
  border: 0;
  height: 40px;
  border-radius: 5px;
  padding-right: 13px;
  margin-bottom: 20px;
  cursor: pointer;
}
@media (max-width: calc(1024px - 1px)) {
  .buttonNoStart {
    width: 100%;
  }
}
.buttonNoStart p {
  margin: 0 auto;
}

.buttonRightBlock {
  width: 3%;
  height: 10px;
}

.question {
  cursor: pointer;
  color: #B6B6B6;
  border: 1px solid #9F9F9F;
  width: 13px;
  height: 13px;
  border-radius: 20px;
  font-size: 9px;
}

.objects p {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  text-transform: uppercase;
}

.listContainer {
  padding: 0;
}