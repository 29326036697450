.profileButton {
  box-shadow: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
}
.profileButton:active, .profileButton:hover {
  color: #E53935;
}
.profileButton.primary {
  background-color: #E53935;
  color: #FFFFFF;
}
.profileButton.fullWidth {
  margin: 0;
  width: 100%;
}
.profileButton.fullWidth:not(:first-of-type) {
  margin-top: 16px;
}