/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.auth-modal__head {
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
@media (min-width: 768px) {
  .auth-modal__head {
    font-size: 26px;
    line-height: 25px;
    margin-bottom: 43px;
  }
}
@media (min-width: 1440px) {
  .auth-modal__head {
    font-size: 32px;
    margin-bottom: 31px;
  }
}
.auth-modal__form {
  width: 100%;
  margin-bottom: 12px;
}
.auth-modal__buttons-container {
  margin-top: 16px;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 768px) {
  .auth-modal__buttons-container {
    margin-top: 24px;
  }
}
@media (min-width: 1440px) {
  .auth-modal__buttons-container {
    padding-left: 85px;
    padding-right: 85px;
  }
}