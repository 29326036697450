/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  border-top: 1px solid #989898;
  width: 100%;
  margin-top: 10px;
  padding: 6px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    margin-top: 30px;
    border-top: none;
  }
}
.container.open .content {
  display: block;
}
.container .content {
  display: none;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 16px;
}
@media (min-width: 1440px) {
  .container .content {
    display: flex;
    flex-wrap: wrap;
  }
}

.dropdownIcon {
  background-image: url("../../../img/icons/dropdown-icon-black.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 15px;
  transition: transform 0.3s;
}
@media (min-width: 1440px) {
  .dropdownIcon {
    display: none;
  }
}
.dropdownIcon.open {
  transform: rotate(-180deg);
}

.section {
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  margin-top: 24px;
}
.section:first-of-type {
  margin-top: 0;
}
@media (min-width: 1440px) {
  .section {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-left: 64px;
    max-height: 170px;
    flex-wrap: wrap;
  }
  .section:first-of-type {
    margin-left: 0;
  }
}

.sectionLabel {
  color: #212121;
  margin-bottom: 16px;
}
.infoContainer {
  width: 50%;
  display: inline-block;
  margin-bottom: 16px;
}
@media (min-width: 1440px) {
  .infoContainer {
    width: unset;
  }
  .infoContainer:nth-child(2n+4) {
    margin-top: 32px;
  }
  .infoContainer:nth-child(n+4) {
    margin-left: 40px;
  }
}

.name {
  color: rgba(0, 0, 0, 0.539261);
}

.value {
  color: #212121;
}