/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  height: 106px !important;
  width: 100%;
}
@media (min-width: 768px) {
  .card.responsive {
    width: calc((100% - 16px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card.responsive {
    width: calc((100% - 26px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .card {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px !important;
  }
}

.headOnline, .headOffline {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.headOnline {
  background-color: #7A7A7A;
  color: #FFFFFF;
}

.headOnlineColorNew {
  background: #E53935;
}

.headOffline {
  background-color: #EEEEEE;
  color: #989898;
}
.headOffline .icon {
  background-image: url("../../../img/icons/object-icon-inactive.svg");
}

.icon {
  background-image: url("../../../img/icons/object-icon-active.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 8px;
}

.status {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #02E30B;
  margin-right: 6px;
  margin-left: 6px;
}

.contentContainer {
  padding: 5px 10px 13px;
}
@media (min-width: 1440px) {
  .contentContainer {
    padding-top: 10px;
  }
}

.title {
  color: #000000;
}
@media (min-width: 1440px) {
  .title {
    font-size: 16px;
  }
}

.infoBlock {
  margin-top: 10px;
  display: flex;
}

.infoBlockPair {
  display: flex;
  justify-content: space-between;
}

.infoTitle {
  color: #BBBBBB;
  margin-right: 6px;
}

.infoValue {
  color: #7D7D7D;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}