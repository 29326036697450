/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background-color: #FAFAFA;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  overflow: visible;
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 40px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .card {
    border: 1px solid #D9D9D9;
    height: 104px;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.head {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 10px;
}

.icon {
  width: 25px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../img/icons/object-dark-grey-icon.svg");
}
@media (min-width: 1440px) {
  .icon {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.headInfo {
  font-size: 11px;
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .headInfo {
    font-size: 14px;
  }
}

.checkboxContainer {
  position: relative;
  left: 14px;
  top: -16px;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .checkboxContainer {
    margin-right: 16px;
  }
}

.menuButton {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url("../../../img/icons/dots-menu-icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .menuButton {
    display: none;
  }
}

.popup {
  right: -16px;
  top: 36px;
  z-index: 1000;
}

.dataContainer {
  display: flex;
  max-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 16px;
}
@media (min-width: 1440px) {
  .dataContainer {
    padding-left: 14px;
    padding-right: 14px;
    justify-content: space-between;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
}
.infoBlock:not(:first-of-type) {
  margin-top: 9px;
}
@media (min-width: 1440px) {
  .infoBlock {
    line-height: 16px;
    font-size: 16px;
  }
}

.infoTitle {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.infoValue {
  color: rgba(0, 0, 0, 0.5);
}

.deleteButton {
  display: none;
}
@media (min-width: 1440px) {
  .deleteButton {
    display: block;
    width: 90px;
  }
}