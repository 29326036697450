/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
@import url("https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap");
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  height: 106px !important;
  width: 100%;
  mix-blend-mode: normal;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
}
@media (min-width: 768px) {
  .card.responsive {
    width: calc((100% - 16px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card.responsive {
    width: calc((100% - 26px) / 2);
  }
  .card.responsive:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .card {
    box-shadow: none;
    height: 125px !important;
  }
}

.headOnline, .headOffline {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.headOnline {
  background: #F4F4F4;
  color: #989898;
  font-size: 11px;
  line-height: 13px;
}

.headOffline {
  background-color: #EEEEEE;
  color: #989898;
}
.headOffline .icon {
  background-image: url("../../../img/icons/object-icon-inactive.svg");
}

.icon {
  background-image: url("../../../img/icons/object-icon-active.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 8px;
}

.status {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #989898;
  margin-right: 4px;
  margin-left: 4px;
}

.statusRate {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FF3B30;
  margin-right: 6px;
  margin-left: 24px;
}

.rate {
  font-size: 11px;
  line-height: 13px;
  color: #000000;
}

.contentContainer {
  padding: 5px 10px 13px;
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .contentContainer {
    padding-top: 10px;
  }
}

.title {
  color: #000000;
  font-size: 12px;
  line-height: 14px;
}

.infoBlock {
  margin-top: 10px;
  display: flex;
}

.infoBlockPair {
  display: flex;
  justify-content: space-between;
}

.infoTitle {
  color: #BBBBBB;
  margin-right: 6px;
}

.infoValue {
  color: #7D7D7D;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}