.container {

}

.button {
    background-position: center;
    background-size: 16px 8px;
    background-repeat: no-repeat;
    height: 8px;
    user-select: none;
}

.buttonIncrease {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwTDE2IC0yLjc5NzUzZS0wNkw4IDhMMCAwWiIgZmlsbD0iI0U1MzkzNSIvPgo8L3N2Zz4K");
}

.buttonDecrease {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgOEwwIDhMOCAtNi45OTM4MmUtMDdMMTYgOFoiIGZpbGw9IiNFNTM5MzUiLz4KPC9zdmc+Cg==");
}

.value, .inactiveValue {
    font-size: 18px;
    line-height: 21px;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.value {
    color: #E53935;
    border-top: 1px solid #E53935;
    border-bottom: 1px solid #E53935;
}

.inactiveValue {
    color: #BBBBBB;
}
