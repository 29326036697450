/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 35px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .container {
    padding-top: 28px;
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: calc(1440px - 1px)) {
  .controlsContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1440px) {
  .controlsContainer {
    border-bottom: 1px solid #D7D7D7;
    justify-content: flex-start;
  }
}

@media (min-width: 1440px) {
  .searchContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

@media (min-width: 768px) {
  .searchControls {
    display: flex;
    justify-content: space-between;
  }
}

.searchInput {
  width: 100%;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .searchInput {
    width: 50%;
    margin-bottom: 0;
  }
}
@media (min-width: 1440px) {
  .searchInput {
    width: 40%;
  }
}

.createButton {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 44px;
  background-image: url("../../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .createButton {
    background-position: left 12px center;
    padding-left: 22px;
  }
}
@media (min-width: 768px) {
  .createButton {
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .createButton {
    order: 3;
    width: 214px;
    background-size: 20px;
    font-size: 14px;
    padding-left: 56px;
    justify-content: flex-start;
    margin-left: 32px;
  }
}

.countContainer {
  display: none;
}
@media (min-width: 1440px) {
  .countContainer {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
}

.count {
  color: #E53935;
  margin-left: 32px;
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 20px;
  }
}