/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: none;
  position: relative;
}
@media (min-width: 1440px) {
  .container {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    margin-top: 25px;
    border-radius: 10px;
    overflow: hidden;
  }
}
.container.open {
  display: block;
}

.flexContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 27px;
}
@media (min-width: 1440px) {
  .flexContainer {
    padding-top: 8px;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    height: 604px;
  }
}

@media (min-width: 1440px) {
  .datePicker {
    order: 2;
  }
}

.form {
  width: 70%;
}
@media (min-width: 1440px) {
  .form {
    height: 100%;
    order: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
  }
}

.section {
  margin-top: 22px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}
@media (min-width: 1440px) {
  .section {
    max-width: 200px;
  }
}

.sectionTitle {
  padding-bottom: 7px;
  border-bottom: 1px solid #D9D9D9;
}

.sectionContent {
  padding-top: 13px;
}

.buttonsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #FFFFFF;
  height: 60px;
  border-top: 1px solid #D9D9D9;
  z-index: 10;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    position: relative;
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: none;
    padding-right: 50px;
  }
}

.button {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #E53935;
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .button {
    width: 100px;
    margin-left: 22px;
  }
}