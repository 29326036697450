/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
@import '~react-h5-audio-player/lib/styles.css';
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  /*@include mobile-portrait() {
      height: 100vw;
      width: 100vh;
      transform: rotate(90deg);
  }*/
}

html {
  box-sizing: border-box;
}
@media (min-width: 1440px) {
  html {
    background-color: #FAFAFA;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}