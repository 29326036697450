/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: block;
  border-radius: 12px;
  margin-bottom: 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
}

.head {
  height: 30px;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}