/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: inline-block;
  text-transform: uppercase;
  padding-left: 6px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: 8px;
  }
}

.status {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: inline-block;
  background-color: #989898;
  margin-right: 5px;
}
@media (min-width: 1440px) {
  .status {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
}

.statusOnline {
  background-color: #02E30B;
}