/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.checkbox {
  width: 100%;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox:not(:first-of-type) {
  margin-left: 24px;
}
.checkbox input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #FF3B30;
  border: none;
}
.checkbox input[type=checkbox]:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #989898;
  height: 12px;
  width: 12px;
}
@media (min-width: 1440px) {
  .checkbox .checkmark {
    height: 16px;
    width: 16px;
  }
  .checkbox .checkmark:after {
    content: "";
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url("../../../../img/icons/check-icon.svg");
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: center;
  }
}
.checkbox:hover .checkmark {
  background-color: #ccc;
}

.filterCheckbox {
  line-height: 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filterCheckbox input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filterCheckbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #FF3B30;
}
.filterCheckbox input[type=checkbox]:checked ~ .checkmark:after {
  display: block;
}
.filterCheckbox .checkmark {
  height: 10px;
  width: 10px;
  background-color: #DFDFDF;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .filterCheckbox .checkmark:after {
    content: "";
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
.filterCheckbox:hover .checkmark {
  background-color: #ccc;
}