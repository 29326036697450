$selection-padding: 2px;

.rdrCalendarWrapper {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
}

.rdrDateDisplay {
    background-color: #FFFFFF !important;
}

.rdrMonthPicker, .rdrYearPicker {
    select, option {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 0 !important;
    }

    select {
        text-align: right;
    }

    select:hover {
        background-color: #FFFFFF !important;
    }
}

.rdrDay {
    height: 44px !important;
    width: 44px !important;
}

.rdrDayNumber {
    span {
        font-size: 12px !important;
        color: rgba(0, 0, 0, 0.867782);

        &:after {
            background-color: #E53935 !important;
        }
    }
}

.rdrStartEdge, .rdrDayStartPreview {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
    left: $selection-padding !important;
}

.rdrEndEdge, .rdrDayEndPreview {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
    right: $selection-padding !important;
}

.rdrInRange, .rdrEndEdge, .rdrStartEdge,
.rdrDayInPreview, .rdrDayStartPreview, .rdrDayEndPreview {
    top: $selection-padding !important;
    bottom: $selection-padding !important;
}

.rdrDayEndOfWeek {
    .rdrInRange {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
        right: $selection-padding !important;
    }
}

.rdrDayStartOfWeek {
    .rdrInRange, .rdrEndEdge {
        border-top-left-radius: 50% !important;
        border-bottom-left-radius: 50% !important;
        left: $selection-padding !important;
    }
}
