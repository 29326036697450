/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 35px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .container {
    padding-top: 28px;
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .listContainer {
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .searchContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

@media (min-width: 768px) {
  .searchControls {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .searchInput {
    width: 40%;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .searchInput {
    width: 40%;
  }
}

.listOptions {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (max-width: calc(768px - 1px)) {
  .listOptions {
    display: none;
  }
}
@media (min-width: 1440px) {
  .listOptions {
    padding-left: 0;
    padding-right: 0;
  }
}

.checkbox {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-right: 16px;
  margin-bottom: 0;
}
@media (min-width: 1440px) {
  .checkbox {
    font-size: 14px;
    line-height: 16px;
    margin-right: 24px;
  }
}