/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  background-color: #DCDCDC;
  height: 51px;
  width: 100%;
  background-image: url("../../img/icons/inpro-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 114px 37px;
}