/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 1440px) {
  .container {
    width: 382px;
    margin-top: 22px;
  }
}

.labelContainer {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .labelContainer {
    margin-bottom: 12px;
  }
}

.label {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.icon {
  background-image: url("../../../img/icons/calendar-icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}