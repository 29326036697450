/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  background-color: #FAFAFA;
  min-height: 250px;
  padding: 12px 16px 24px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 42px;
}
@media (min-width: 768px) {
  .container {
    padding-top: 24px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 460px;
  }
}