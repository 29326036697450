/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.tabsHeadContainer {
  display: flex;
  color: #BBBBBB;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .tabsHeadContainer {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1440px) {
  .tabsHeadContainer {
    font-size: 14px;
    line-height: 16px;
  }
}

.tabTitle {
  margin-left: 12px;
  cursor: pointer;
  user-select: none;
}
.tabTitle.active {
  color: #000000;
}
@media (min-width: 1440px) {
  .tabTitle.active {
    padding-bottom: 5px;
    border-bottom: 2px solid #FF1744;
  }
}
@media (min-width: 1440px) {
  .tabTitle {
    padding-bottom: 7px;
    margin-left: 40px;
  }
  .tabTitle:first-of-type {
    margin-left: 0;
  }
}
.tabTitle:first-of-type {
  margin-left: 0;
}