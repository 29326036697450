/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 11px;
  padding-bottom: 25px;
}
@media (min-width: 1440px) {
  .container {
    padding-bottom: 31px;
  }
}

@media (min-width: 1440px) {
  .controlsContainer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.searchContainer {
  width: 100%;
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .searchContainer {
    width: 40%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.inputContainer {
  flex-grow: 1;
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.input {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 30px 8px 22px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}
.input::placeholder {
  color: #D9D9D9;
}
.input:focus {
  outline: none;
  border-color: #E53935;
}

.searchIcon, .filterIcon {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.searchIcon {
  background-image: url("../../img/icons/search-icon.svg");
  position: absolute;
  right: 8px;
  top: 12px;
  height: 20px;
  width: 20px;
}

.filterIcon {
  margin: 11px 0 11px 16px;
  background-image: url("../../img/icons/filter-icon.svg");
  height: 22px;
  width: 22px;
}
.filterIcon.open {
  background-image: url("../../img/icons/filter-icon-active.svg");
}