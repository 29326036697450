/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 65px);
}
@media (min-width: 1440px) {
  .container {
    padding-top: 24px;
    min-height: calc(100vh - 103px);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.controlsContainer {
  display: none;
}
@media (min-width: 1440px) {
  .controlsContainer {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    display: block;
    border-bottom: 1px solid #D7D7D7;
  }
}

.mainContainer {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1440px) {
  .mainContainer {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    padding-top: 14px;
  }
}

.backLink {
  display: none;
}
@media (min-width: 1440px) {
  .backLink {
    display: block;
    padding-left: 44px;
    font-size: 17.5px;
    line-height: 25px;
    color: #E53935;
    background-image: url("../../img/icons/arrow-back-icon.svg");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 33px 22px;
  }
}
.backLink:hover {
  color: #E53935;
}

.statusInfo {
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 30px;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
  background-image: url("../../img/icons/record-icon.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px 16px;
}
@media (min-width: 1440px) {
  .statusInfo {
    margin-top: 26px;
  }
}

.hintText {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #989898;
  margin-bottom: 10px;
}

@media (min-width: 1440px) {
  .flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.title {
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 1440px) {
  .title {
    order: 1;
    margin-bottom: 0;
  }
}

.player {
  vertical-align: top;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 1440px) {
  .player {
    width: 100%;
    order: 3;
    margin-top: 24px;
  }
}
.player .hintText {
  width: 100%;
  text-align: left;
  margin-bottom: 17px;
}

.playerIcon {
  height: 18px;
  width: 18px;
  background-image: url("../../img/icons/play-icon-active.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.playerRange {
  display: inline-block;
  appearance: none;
  width: 80%;
  margin-left: 12px;
}
@media (min-width: 1440px) {
  .playerRange {
    width: 90%;
  }
}
.playerRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.playerRange:focus {
  outline: none;
  background: none;
}
.playerRange::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.playerRange::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.playerRange::-ms-track {
  height: 2px;
  border: transparent;
}
.playerRange::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.playerRange::-ms-fill-lower {
  background: #E53935;
}
.playerRange::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 26px;
}
@media (min-width: 1440px) {
  .buttonsContainer {
    order: 2;
    width: max-content;
    margin-bottom: 0;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  margin-left: 21px;
  cursor: pointer;
  height: 100%;
  background-color: #E53935;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  padding: 0 21px;
  border-radius: 2px;
  user-select: none;
}
.button:nth-child(2) {
  margin-left: 0;
}
@media (min-width: 1440px) {
  .button:first-child {
    margin-left: 0;
  }
  .button:nth-child(2) {
    margin-left: 21px;
  }
}

.buttonGray {
  display: none;
  background-color: #6C6C6C;
  color: #ffffff;
}
@media (min-width: 1440px) {
  .buttonGray {
    display: inline-flex;
  }
}

.mobileButton {
  cursor: pointer;
  width: 100%;
  background-color: #6C6C6C;
  border-radius: 2px;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 7px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10px;
}
@media (min-width: 1440px) {
  .mobileButton {
    display: none;
  }
}

.mobileButtonLight {
  background-color: #ffffff;
  border: 1px solid #989898;
  color: #000000;
}

.mobileButtonActive {
  color: #ffffff;
  background-color: #E53935;
}

.createdAt {
  width: 100%;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  padding-right: 22px;
  height: 81px;
  background-color: #FAFAFA;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
}
@media (min-width: 1440px) {
  .createdAt {
    height: 50px;
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    bottom: 0;
    left: 0;
  }
}