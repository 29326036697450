/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.link {
  height: 40px;
  width: 40px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url("../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  margin-left: 30px;
  margin-top: 12px;
}
@media (max-width: calc(1440px - 1px)) {
  .link p {
    display: none;
  }
}
@media (min-width: 1440px) {
  .link {
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}
.link:hover {
  color: #000;
}

.searchBackground {
  width: 100%;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .searchContainer {
    align-items: baseline;
  }
}

.playlists {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  column-gap: 38px;
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .playlists {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media (max-width: calc(1024px - 1px)) {
  .playlists {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}

.playlistLink {
  display: flex;
  justify-content: center;
  align-items: center;
}