.playlist {
  width: 100%;
  height: 41px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #F8F8F8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.playlist:hover {
  background: #dfdfdf;
}

.icon {
  margin-right: 10px;
}

.titleContainer {
  display: flex;
  align-items: center;
}
.titleContainer .image {
  margin-left: 7px;
  margin-right: 5px;
}
.titleContainer .image img {
  width: 30px;
  height: 30px;
}
.titleContainer p {
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
}

.image {
  width: 32px;
  height: 30px;
}

.button {
  background-color: inherit;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.link {
  width: 100%;
}