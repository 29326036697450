/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  border-bottom: 1px solid #D7D7D7;
}

.hint {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-left: 100px;
  color: #6C6C6C;
}