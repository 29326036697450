/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.pageContent {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .pageContent {
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1440px) {
  .pageContent {
    padding: 0;
  }
}

.blockInfo {
  display: flex;
  flex-direction: column;
}
@media (max-width: calc(768px - 1px)) {
  .blockInfo {
    order: 2;
  }
}
@media (min-width: 768px) {
  .blockInfo {
    width: 50%;
    padding-right: 16px;
  }
}

.content {
  height: 130px;
  border: 1px solid #FF5252;
  border-radius: 5px;
  padding: 13px 19px;
  margin-bottom: 46px;
}
.content p {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.text {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
}
.text p {
  margin-right: 22px;
}

.blockLinks {
  margin-bottom: 8px;
  padding-bottom: 6px;
}
@media (max-width: calc(1024px - 1px)) {
  .blockLinks {
    border-bottom: 1px solid #E6E6E6;
  }
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.link:hover {
  color: #E53935;
}
.link p {
  margin: 0;
  margin-left: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
.linkIcon {
  width: 28px;
  height: 24px;
}

.historyContainer {
  order: 1;
}
@media (min-width: 768px) {
  .historyContainer {
    width: 50%;
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .historyList {
    padding-left: 0;
    padding-right: 0;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.buttonPay {
  margin-bottom: 10px;
}