/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  box-shadow: 0px;
  display: none;
  position: absolute;
  z-index: 1;
  top: 225px;
  right: 10px;
  user-select: none;
}
.container.open {
  display: block;
  width: 94%;
}
@media (min-width: 768px) {
  .container.open {
    width: 48%;
    top: 215px;
  }
}
@media (min-width: 1024px) {
  .container.open {
    width: 35%;
  }
}
@media (min-width: 1440px) {
  .container.open {
    width: 550px;
    right: 51%;
    top: 233px;
  }
}

@media (min-width: 1440px) {
  .containerFilter {
    left: 51%;
  }
}

.flexContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 27px;
}
@media (min-width: 1440px) {
  .flexContainer {
    padding-top: 16px;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.form {
  width: 86%;
}
@media (min-width: 1440px) {
  .form {
    height: 100%;
    order: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
  }
}

.section {
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}

.activeSection {
  background-image: url("../../../img/icons/check-icon-background.svg");
  background-position: right;
  background-repeat: no-repeat;
}

.sortBottom, .sortTop {
  background-position: left;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.sortTop {
  transform: rotate(180deg);
  margin-bottom: 3px;
}

.none {
  opacity: 0;
}

.sectionTitle {
  margin-top: 14px;
  padding-bottom: 7px;
  border-bottom: 1px solid #D9D9D9;
  cursor: pointer;
  user-select: none;
}

.filters {
  font-size: 12px;
  margin-top: 22px;
  text-transform: uppercase;
  font-weight: 500;
  user-select: none;
}

.sectionContent {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  height: 100%;
  width: 100%;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 9px;
}

.button {
  height: 28px;
  margin-bottom: 9px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  background-color: #F4F4F4;
  outline: none;
  cursor: pointer;
}
.active {
  color: #fff;
  background-color: #E53935;
}