.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-right: 12px;
  padding-left: 12px;
  color: #FFFFFF;
  border: none;
  background-color: #E53935;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  user-select: none;
}
.button:not(:first-of-type) {
  margin-left: 12px;
}
.button:focus {
  outline: none;
}
.button:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24), 0px 0px 1px rgba(0, 0, 0, 0.12);
}
.button:hover {
  color: #FFFFFF;
}