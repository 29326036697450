.container {
  display: flex;
  align-items: center;
  height: 35px;
  padding-right: 16px;
}
.container.disabled {
  background-color: #FFBDBB;
  height: 46px;
}
.container.disabled .colorMarker {
  display: none;
}

.infoText {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-left: 10px;
}

.infoTitle {
  flex-grow: 1.4;
}

.colorMarkerContainer {
  height: 100%;
  width: 15px;
  position: relative;
}
.colorMarkerContainer.first {
  padding-top: 10px;
  margin-bottom: -1px;
}
.colorMarkerContainer.first .colorMarker {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.colorMarkerContainer.last {
  padding-bottom: 10px;
  margin-top: -1px;
}
.colorMarkerContainer.last .colorMarker {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.colorMarker {
  width: 100%;
  height: 100%;
}