/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  color: #000000;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #E5E5E5;
}
@media (min-width: 768px) {
  .container {
    height: 60px;
  }
}
@media (max-width: calc(1024px - 1px)) {
  .container {
    border-bottom: 0px;
  }
}
@media (min-width: 1440px) {
  .container {
    justify-content: space-between;
  }
}

.label {
  margin-bottom: 8px;
  width: 100%;
}

.value {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  width: 60%;
}

.button {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}
@media (min-width: 1440px) {
  .button {
    border: 1px solid #000000;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
}

.buttonIcon {
  height: 16px;
  width: 11px;
  background-image: url("../../../img/icons/profile-balance-menu.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: center;
}
@media (min-width: 1440px) {
  .buttonIcon {
    display: none;
  }
}

.buttonIconBack {
  transform: rotate(180deg);
  margin-right: 12px;
}

.buttonText {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}