.react-calendar-timeline .rct-header-root {
  background: transparent;
  border-bottom: 1px solid #BBBBBB;
}
.react-calendar-timeline .rct-dateHeader {
  background: transparent;
  border-bottom: none;
  border-left: 1px solid #BBBBBB;
  font-size: 16px;
  line-height: 16px;
}
.react-calendar-timeline .rct-calendar-header {
  border: none;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid #BBBBBB;
  font-size: 14px;
  line-height: 16px;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row-even, .react-calendar-timeline .rct-sidebar .rct-sidebar-row-odd {
  background: transparent !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: transparent;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  background: transparent !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl-first {
  border-left-width: 1px !important;
}
.react-calendar-timeline .rct-item {
  border-radius: 10px;
  cursor: pointer;
}