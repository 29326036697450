/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 28px;
}
@media (min-width: 1440px) {
  .container {
    width: 300px;
  }
}

.visualiser {
  width: calc(100vw - 150px);
  height: calc(100vw - 150px);
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
  background-color: rgba(108, 108, 108, 0.07);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .visualiser {
    order: 1;
    width: 270px;
    height: 270px;
  }
}
.visualiser .innerCircle {
  width: 100%;
  height: 100%;
  padding: 25px;
  background-color: rgba(255, 52, 75, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .visualiser .innerCircle {
    padding: 32px;
  }
}
.visualiser .innerCircle:nth-child(even) {
  animation-direction: reverse;
  animation-duration: 5s;
}
.visualiser .centerCircle {
  background-color: rgb(255, 52, 75);
  animation: none;
}

.volumeContainer {
  position: absolute;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  justify-content: center;
}

.visualiserAnimated .innerCircle {
  animation: visualizeSound 1s;
  animation-iteration-count: infinite;
}

.visualiserMuted .innerCircle {
  background-color: rgba(108, 108, 108, 0.07);
}
.visualiserMuted .centerCircle {
  background-color: #6C6C6C;
}

.titleContainer {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-top: 28px;
}
@media (min-width: 1024px) {
  .titleContainer {
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .titleContainer {
    order: 3;
    margin-top: 21px;
  }
}

.settingsIcon, .lightningIcon, .playButton {
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.settingsIcon {
  background-image: url("../../../img/icons/player-settings-icon.svg");
  height: 18px;
  width: 20px;
}
@media (min-width: 1024px) {
  .settingsIcon {
    display: none;
  }
}

.lightningIcon {
  background-image: url("../../../img/icons/player-lightning-icon.svg");
  height: 22px;
  width: 13px;
}
@media (min-width: 1024px) {
  .lightningIcon {
    display: none;
  }
}

.backgroundIcon {
  background-image: url("../../../img/icons/speaker-icon 1.svg");
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
  margin-right: 5px;
}
@media (min-width: 1024px) {
  .backgroundIcon {
    display: none;
  }
}

.playerControls {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;
}
@media (max-width: calc(1024px - 1px)) {
  .playerControls {
    margin-top: 14px;
  }
}
@media (min-width: 1440px) {
  .playerControls {
    order: 2;
  }
}

.playButton {
  background-image: url("../../../img/icons/player-pause-icon.svg");
  height: 30px;
  width: 22px;
}
.playButton.paused {
  background-image: url("../../../img/icons/player-play-icon.svg");
}
@media (min-width: 1440px) {
  .playButton {
    background-image: url("../../../img/icons/player-pause-icon-white.svg"), linear-gradient(44.51deg, #FF1744 0%, #FF5252 100%);
    border-radius: 50%;
    background-size: 35px 24px, cover;
    background-position: center, center;
    height: 58px;
    width: 58px;
  }
  .playButton.paused {
    background-image: url("../../../img/icons/player-play-icon-white.svg"), linear-gradient(44.51deg, #FF1744 0%, #FF5252 100%);
    background-position: left 14px center, center;
  }
}

.plusButton, .minusButton {
  color: #FF344B;
  font-size: 38px;
  font-weight: 200;
  user-select: none;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .plusButton, .minusButton {
    width: 32px;
    height: 32px;
    background-color: #FF1744;
    color: transparent;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    user-select: none;
  }
  .plusButton:focus, .minusButton:focus {
    outline: none;
  }
}

@media (min-width: 1440px) {
  .plusButton {
    background-image: url("../../../img/icons/player-plus-white.svg");
  }
}

@media (min-width: 1440px) {
  .minusButton {
    background-image: url("../../../img/icons/player-minus-white.svg");
  }
}

@media (max-width: calc(1024px - 1px)) {
  .titleText {
    flex-basis: 100%;
    margin-top: 24px;
    text-align: center;
  }
}

.scrollingTitle {
  white-space: nowrap;
  overflow: hidden;
}
.scrollingTitle span {
  display: inline-block;
  padding-left: 100%;
  animation: scroll 5s infinite linear;
}

.microphoneIcon {
  background-image: url("../../../img/icons/microphone-icon-red.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 130px;
  width: 90px;
  animation: shake 0.8s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes visualizeSound {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(0.94);
  }
  60% {
    transform: scale(0.92);
  }
  75% {
    transform: scale(0.98);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, 0px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-1px, 0px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
}