/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playerContainer {
  padding-left: 32px;
  padding-right: 32px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .playerContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .playerContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px;
  }
}
@media (min-width: 1440px) {
  .playerContainer {
    padding: 0 0 40px;
  }
}

.title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .title {
    margin-right: 48px;
  }
}
@media (min-width: 1024px) {
  .title {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    line-height: 23px;
  }
}

.address {
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: rgba(33, 33, 33, 0.8);
}
@media (min-width: 1024px) {
  .address {
    font-size: 12px;
    margin-left: 6px;
  }
}

.muteContainer {
  user-select: none;
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .muteContainer {
    flex-grow: 1;
  }
}
@media (min-width: 1440px) {
  .muteContainer {
    border-radius: 20px;
    background-color: #C4C4C4;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    height: 40px;
    width: 205px;
    cursor: pointer;
    margin-right: 160px;
  }
  .muteContainer.muted {
    background: linear-gradient(77.58deg, #FF1744 0%, #FF5252 100%);
  }
}
.muteContainer .muteText {
  display: none;
}
@media (min-width: 1440px) {
  .muteContainer .muteText {
    display: block;
  }
}

@media (min-width: 768px) {
  .soundButtonContainer {
    padding: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 1440px) {
  .soundButtonContainer {
    margin-right: 20px;
    background-color: #FFFFFF;
  }
}

.soundButton {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .soundButton {
    width: 100%;
    height: 100%;
  }
}

.priorityButton,
.backgroundButton {
  display: none;
}
@media (min-width: 1024px) {
  .priorityButton,
.backgroundButton {
    display: flex;
    background: linear-gradient(81.57deg, #FF1744 0%, #FF5252 99.68%);
    border-radius: 10px;
    height: 46px;
    padding: 15px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .priorityButton:hover,
.backgroundButton:hover {
    color: #FFFFFF;
  }
}

.priorityButton {
  margin-right: 10px;
}

.priorityIcon {
  background-image: url("../../../../img/icons/lightning-icon-white.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .headContent {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #D7D7D7;
  }
}
@media (min-width: 1440px) {
  .headContent {
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
  }
}

.flexCenter,
.flexContainer {
  display: flex;
  align-items: center;
}

.flexContainer {
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .flexContainer {
    order: 2;
  }
}

.info {
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #989898;
  margin-top: 16px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .info {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .info {
    order: 1;
    width: 20%;
    margin-bottom: 0;
  }
}

.objectIcon {
  background-image: url("../../../../img/icons/object-icon-inactive.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 6px;
}

.statusContainer {
  display: flex;
  align-items: baseline;
}

.onlineStatus {
  background-color: #02E30B;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-left: 6px;
  margin-right: 6px;
}

@media (min-width: 1024px) {
  .pageContent {
    display: flex;
    order: 3;
    padding-left: 48px;
    padding-top: 24px;
  }
}

.favoriteAdContainer {
  display: block;
}
@media (max-width: calc(768px - 1px)) {
  .favoriteAdContainer {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .favoriteAdContainer {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .favoriteAdContainer {
    margin-bottom: 42px;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .favoriteAdContainer {
    margin-left: 72px;
  }
}
@media (min-width: 1440px) {
  .favoriteAdContainer {
    margin-left: 72px;
    margin-top: 52px;
  }
}

.favoriteAdTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #6C6C6C;
  padding-left: 42px;
  margin-bottom: 13px;
  width: 100%;
}
@media (min-width: 1024px) {
  .favoriteAdTitle {
    padding-left: 0;
  }
}
@media (min-width: 1440px) {
  .favoriteAdTitle {
    padding-left: 42px;
  }
}

.favoriteAd {
  min-width: 205px;
  width: 75%;
  cursor: pointer;
  border-radius: 30px;
  height: 60px;
  padding-left: 25px;
  padding-right: 15px;
  align-items: center;
  color: #FFFFFF;
  background: linear-gradient(239.07deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  box-shadow: 0 4px 15px rgba(255, 52, 75, 0.4);
  display: inline-flex;
  user-select: none;
  font-size: 12px;
  line-height: 14px;
}
@media (min-width: 768px) {
  .favoriteAd {
    width: 70%;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .favoriteAd {
    width: 80%;
  }
}
@media (min-width: 1440px) {
  .favoriteAd {
    width: 537px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding-left: 42px;
    padding-right: 22px;
  }
}

.favoriteAdIcon {
  background-image: url("../../../../img/icons/player-play-icon-white.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 23px;
  width: 29px;
}
@media (min-width: 768px) {
  .favoriteAdIcon {
    height: 28px;
  }
}
@media (min-width: 1440px) {
  .favoriteAdIcon {
    height: 30px;
    width: 23px;
  }
}

.microphoneIconContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 59px;
  width: 59px;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .microphoneIconContainer {
    margin-left: 25px;
  }
}
@media (min-width: 1440px) {
  .microphoneIconContainer {
    vertical-align: top;
  }
}

.microphoneIcon {
  background-image: url("../../../../img/icons/player-microphone-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 33px;
  width: 23px;
}
.microphoneIcon.active {
  background-image: url("../../../../img/icons/microphone-icon-red.svg");
}

.tabsContainer {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 1440px) {
  .tabsContainer {
    padding-left: 56px;
    padding-top: 24px;
  }
}

@media (max-width: calc(768px - 1px)) {
  .hideMobile {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .hideTablet {
    display: none;
  }
}

.maintenanceButton {
  display: none !important;
}
@media (min-width: 1440px) {
  .maintenanceButton {
    display: flex !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 2px solid #FF1744 !important;
    color: #FF1744 !important;
    background-image: url("../../../../img/icons/object-maintenance-icon-red.svg");
    background-size: 30px 33px;
    background-position: 8px center;
    background-repeat: no-repeat;
    width: 270px !important;
    height: 46px !important;
    margin-bottom: 18px;
  }
}