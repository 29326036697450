/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  margin-bottom: 121px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.formContainer {
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1440px) {
  .formContainer {
    border-bottom: 1px solid #D7D7D7;
    padding-bottom: 20px;
  }
}

.formInputs {
  width: 100%;
}
@media (min-width: 1440px) {
  .formInputs {
    display: flex;
    justify-content: space-between;
  }
}

.input:not(:first-of-type) {
  margin-top: 39px;
}
@media (min-width: 1440px) {
  .input:not(:first-of-type) {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .input {
    padding-top: 0 !important;
    width: 450px;
  }
}

.addButton {
  margin-top: 34px;
  width: auto;
}
@media (min-width: 1440px) {
  .addButton {
    width: 210px;
  }
}

.listContainer {
  padding-top: 34px;
}
@media (min-width: 1440px) {
  .listContainer {
    padding-top: 20px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 37px;
  padding-left: calc((100% - 154px) / 2);
  padding-right: calc((100% - 154px) / 2);
}