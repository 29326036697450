/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 25px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .container {
    padding-top: 28px;
  }
}

.controlsContainer {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1440px) {
  .controlsContainer {
    border-bottom: 1px solid #D7D7D7;
    flex-wrap: nowrap;
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }
}

.createButton {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: 100%;
  height: 44px;
  background-image: url("../../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 36px;
  font-size: 14px;
}
@media (min-width: 1440px) {
  .createButton {
    width: 303px;
    padding-left: 56px;
    justify-content: flex-start;
    order: 2;
  }
}

.countContainer {
  margin-top: 22px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 1440px) {
  .countContainer {
    order: 1;
    width: auto;
    margin-top: 0;
  }
}

@media (min-width: 1440px) {
  .countValue {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    color: #E53935;
  }
}

.listContainer {
  padding-top: 20px;
}