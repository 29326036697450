/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 15px;
  position: relative;
}

.title {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.filtersButton, .popupItem {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.filtersButton {
  cursor: pointer;
  padding-right: 16px;
  background-image: url("../../../img/icons/select-triangle.svg");
  background-position: right 4px center;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  z-index: 9;
}

.popup {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 340px;
  padding-top: 27px;
  padding-bottom: 21px;
}
.popup:before, .popup:after {
  display: none;
}

.popupContainer {
  right: -6px;
  top: -6px;
}
@media (min-width: 1440px) {
  .popupContainer .popup {
    right: 0;
    top: 0;
  }
}

.popupItem {
  padding: 10px 26px;
}
.popupItem:not(:last-child) {
  border-bottom: 1px solid #F5F5F5;
}

.popupDates {
  background: #F4F4F4;
  border-radius: 5px;
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.dateInput {
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.dateInput:focus {
  outline: none;
}
.dateInput::-webkit-inner-spin-button, .dateInput::-webkit-clear-button {
  display: none;
}
.dateInput::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
.dateInput:last-child {
  text-align: right;
}