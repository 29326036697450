/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 21px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .container {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .container {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 55%;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .container {
    flex-grow: 2;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .container {
    flex-grow: 2;
  }
}
@media (min-width: 1440px) {
  .container {
    justify-content: space-between;
    padding-left: 32px;
  }
}

.createButton {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: calc(100% - 19px - 18px);
  height: 44px;
  background-image: url("../../../../img/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .createButton {
    background-position: left 12px center;
    padding-left: 22px;
  }
}
@media (min-width: 1440px) {
  .createButton {
    order: 3;
    width: 214px;
    background-size: 20px;
    font-size: 14px;
    padding-left: 56px;
    justify-content: flex-start;
  }
}

.sortIcon {
  cursor: pointer;
  background-image: url("../../../../img/icons/sort-by-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 18px;
  height: 44px;
  width: 18px;
  margin-left: 19px;
  position: relative;
}
@media (min-width: 768px) {
  .sortIcon {
    display: none;
  }
}

.popup {
  top: 100%;
}

.loadStatusContainer {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 3;
  width: 100%;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .loadStatusContainer {
    margin-top: 0;
    white-space: nowrap;
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .loadStatusContainer {
    width: auto;
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .loadStatusContainer {
    margin-left: 24px;
  }
}
@media (min-width: 1440px) {
  .loadStatusContainer {
    order: 1;
    width: auto;
    margin-top: 0;
  }
}

.loadStatusValue {
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .loadStatusValue {
    font-size: 16px;
    margin-left: 8px;
  }
}
@media (min-width: 1440px) {
  .loadStatusValue {
    color: #E53935;
    margin-left: 24px;
  }
}