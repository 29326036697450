/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
}

.pageTitle {
  border-bottom: none;
}
@media (min-width: 1024px) {
  .pageTitle {
    display: none;
  }
}

.pageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .pageContainer {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.currentValueContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 14px;
  margin-top: 30px;
}
@media (min-width: 1024px) {
  .currentValueContainer {
    width: 100%;
  }
}

.currentValue {
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin-top: 16px;
}

.pickerContainer {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .pickerContainer {
    margin-left: 28px;
  }
}

.timePicker {
  margin-top: 24px;
}

.contentLabel {
  font-size: 15px;
  line-height: 15px;
  color: #000000;
}
.contentLabel .selectWrapper {
  margin-top: 0;
  display: inline-block;
}
.contentLabel .selectWrapper select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  padding-right: 24px;
  width: 100%;
}

.buttonContainer {
  width: 180px;
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
@media (min-width: 1024px) {
  .buttonContainer {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .submitButton {
    background: linear-gradient(79.8deg, #FF1744 0%, #FF5252 100.15%);
    width: 160px !important;
  }
}

@media (min-width: 1024px) {
  .hideDesktop {
    display: none !important;
  }
}