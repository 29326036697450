/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 81px;
}
@media (min-width: 1024px) {
  .container {
    display: flex;
    align-items: flex-start;
    padding-top: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 26px;
}
@media (min-width: 1024px) {
  .title {
    display: none;
  }
}

@media (min-width: 1024px) {
  .breadcrumbs {
    display: none;
  }
}

.broadcastManagement {
  padding-left: 22px;
  padding-right: 22px;
}
@media (min-width: 1024px) {
  .broadcastManagement {
    width: 50%;
  }
}

.objectTitle {
  margin: 20px 0;
  padding-left: calc((100% - 245px) / 2);
  padding-right: calc((100% - 245px) / 2);
  font-weight: 300;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
@media (min-width: 1024px) {
  .objectTitle {
    display: none;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 1024px) {
  .buttonsContainer {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.button {
  cursor: pointer;
  height: 59px;
  width: 78px;
  background: linear-gradient(265.03deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  box-shadow: 0 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .button {
    width: 160px;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 16px;
    padding-right: 16px;
  }
  .button:not(:first-child) {
    margin-left: 26px;
  }
}
@media (min-width: 1024px) {
  .button {
    width: 260px;
  }
}
.button .buttonText {
  display: none;
}
@media (min-width: 1024px) {
  .button .buttonText {
    display: block;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    margin-left: 12px;
    color: #FFFFFF;
  }
}

.timeIcon, .pauseIcon, .calendarIcon {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  height: 26px;
  width: 26px;
}
@media (min-width: 1024px) {
  .timeIcon, .pauseIcon, .calendarIcon {
    height: 35px;
    width: 35px;
  }
}

.timeIcon {
  background-image: url("../../../../img/icons/broadcast-time-icon.svg");
}

.pauseIcon {
  background-image: url("../../../../img/icons/broadcast-pause-icon.svg");
}

.calendarIcon {
  background-image: url("../../../../img/icons/broadcast-calendar-icon.svg");
}

@media (min-width: 1024px) {
  .hideDesktop {
    display: none;
  }
}

.hideMobile {
  display: none;
}
@media (min-width: 1024px) {
  .hideMobile {
    display: block;
  }
}