.popup-menu-item {
  width: 236px;
  height: 45px;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  align-items: center;
  border: 1px solid #EDEDED;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  user-select: none;
}
.popup-menu-item:first-of-type {
  border-top: none;
}