/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  margin-top: 31px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #6C6C6C;
}
@media (min-width: 1440px) {
  .container {
    padding-bottom: 18px;
  }
}

.icon {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 12px;
}
@media (min-width: 1440px) {
  .icon {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.info {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
  margin-bottom: 5px;
}
@media (min-width: 1440px) {
  .info {
    margin-bottom: 12px;
  }
}

.title {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #000000;
}