/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  position: relative;
  width: 100%;
  height: 32px;
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  overflow: hidden;
  display: flex;
}
@media (min-width: 768px) {
  .container {
    height: 40px;
  }
}
@media (min-width: 1440px) {
  .container {
    height: 50px;
    margin-top: 12px;
  }
}
.container:first-of-type {
  margin-top: 0;
}

.icon {
  width: 32px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
}
@media (min-width: 768px) {
  .icon {
    background-size: 16px 16px;
    width: 48px;
  }
}
@media (min-width: 1440px) {
  .icon {
    width: 70px;
  }
}

.textLabel {
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 90px;
  height: 100%;
  font-size: 12px;
  color: #000;
  background-color: #F0F0F0;
}
@media (min-width: 768px) {
  .textLabel {
    font-size: 16px;
    line-height: 16px;
    width: 102px;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) {
  .textLabel {
    font-size: 20px;
    line-height: 25px;
    font-weight: normal;
    padding-left: 18px;
    padding-right: 20px;
    width: 124px;
  }
}

.input {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  padding-left: 12px;
  font-size: 12px;
  line-height: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #FFFFFF;
  outline: none;
}
@media (min-width: 768px) {
  .input {
    font-size: 14px;
    line-height: 25px;
  }
}
@media (min-width: 1440px) {
  .input {
    font-size: 18px;
  }
}
.input:focus {
  outline: 0;
}
.input:-webkit-autofill {
  background-color: #FFFFFF !important;
}
.input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
}
.input::-ms-clear {
  display: none;
}
.input::-ms-reveal {
  display: none;
}

.eyeIcon {
  position: absolute;
  right: 8px;
  width: 14px;
  height: 100%;
  background-image: url("../../../img/icons/eye-icon.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (min-width: 768px) {
  .eyeIcon {
    width: 20px;
    right: 12px;
  }
}
@media (min-width: 1440px) {
  .eyeIcon {
    width: 25px;
    right: 12px;
  }
}

.hint {
  font-size: 10px;
  color: #989898;
  line-height: 16px;
  padding-left: 8px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .hint {
    padding-right: 30%;
  }
}
@media (min-width: 1440px) {
  .hint {
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
  }
}