/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.backgroundContainer {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url("../../img/authBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 68px;
  width: 95%;
  background-image: url("../../img/logo.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.modalContainer {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .modalContainer {
    margin-top: 72px;
  }
}

.inputWithMargin {
  margin-bottom: 40px;
}