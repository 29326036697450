/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding: 18px 13px 13px;
  color: #000000;
  background-color: #FFFFFF;
  z-index: 150;
}
@media (min-width: 1024px) {
  .container {
    max-width: 50vw;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 25vw;
  }
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.text {
  font-size: 13px;
  line-height: 15px;
  margin-top: 17px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.button {
  font-size: 14px;
  line-height: 16px;
  color: #E53935;
  cursor: pointer;
}
.button:not(:first-child) {
  margin-left: 24px;
}
.button:hover {
  text-decoration: underline;
}