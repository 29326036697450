/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.card {
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 11px;
}
@media (min-width: 768px) {
  .card {
    width: calc((100% - 16px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: calc((100% - 33px) / 2);
  }
  .card:nth-child(odd) {
    margin-right: 33px;
  }
}
.card:not(:first-child) .star {
  background: none;
}

.head {
  display: none;
}

.playButton {
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #FFFFFF;
  background-image: url("../../../img/icons/object-priority-card-play.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 20px;
}

.text {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #989898;
  width: 169px;
  margin-left: 16px;
  flex-grow: 1;
}

.star {
  background-image: url("../../../img/icons/object-priority-card-star.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
}