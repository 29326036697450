/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-bottom: 13px;
}
.container.date {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .container.date {
    display: block;
  }
}
.container.date label {
  white-space: nowrap;
  margin-bottom: 0;
}
.container.date input {
  font-family: "Roboto", sans-serif;
  border-bottom: none;
  padding-bottom: 0;
  width: auto;
  font-size: 13px;
  line-height: 15px;
  background-color: transparent;
}
@media (min-width: 1440px) {
  .container.date input {
    padding-left: 0;
    width: 100%;
    color: #6C6C6C;
  }
}
.container.time {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .container.time {
    display: block;
  }
}
.container.time label {
  white-space: nowrap;
  margin-bottom: 0;
}
.container.time input {
  font-family: "Roboto", sans-serif;
  border-bottom: none;
  padding-bottom: 0;
  width: auto;
  font-size: 13px;
  line-height: 15px;
}
@media (min-width: 1440px) {
  .container.time input {
    padding-left: 0;
    width: 100%;
    color: #6C6C6C;
  }
}
.container:not(:first-of-type) {
  padding-top: 15px;
}
.container label {
  display: block;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 15px;
}
@media (min-width: 1440px) {
  .container label {
    color: rgba(17, 0, 0, 0.4);
  }
}
.container input {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
  padding-bottom: 4px;
  border-bottom: 1px solid #D9D9D9;
}
@media (min-width: 1440px) {
  .container input:not([type=date]):not([type=time]) {
    background-color: #FFFFFF;
    border: 1px solid #BBBBBB;
    border-radius: 5px;
  }
}
.container textarea {
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  resize: none;
  min-height: 94px;
  padding: 12px;
}
@media (min-width: 1440px) {
  .container textarea {
    min-height: 184px;
  }
}
.container input, .container textarea, .container label {
  outline: none;
  display: block;
}
.container input:focus, .container textarea:focus {
  border-color: #E53935;
  caret-color: #E53935;
}
@media (min-width: 1440px) {
  .container input, .container textarea {
    padding: 8px 14px;
  }
}
.container input[type=date], .container input[type=time] {
  background-color: transparent;
}
.container input[type=date]::-webkit-calendar-picker-indicator, .container input[type=time]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-image: url("../../../img/icons/calendar-icon.svg");
  background-repeat: no-repeat;
  height: 18px;
  width: 20px;
  background-size: contain;
  padding: 0;
  cursor: pointer;
}
.container input[type=date]::-webkit-inner-spin-button, .container input[type=time]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
.container input[type=date]::-webkit-clear-button, .container input[type=time]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}