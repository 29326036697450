.select {
    :global(.select__control) {
        background-color: #FFFFFF;
        border: 1px solid #BBBBBB;
        border-radius: 5px;
        box-shadow: none;

        &:hover, &:active {
            box-shadow: none;
            border-color: #E53935;
        }
    }

    :global(.select__option) {
        &:hover, &:active {
            background-color: #FAFAFA;
        }
    }

    :global(.select__option--is-focused) {
        background-color: #FAFAFA;
    }
}
