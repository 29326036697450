/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
@media (min-width: 1024px) {
  .container {
    padding-left: 32px;
  }
}
@media (min-width: 1024px) {
  .container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding-right: 30px;
    margin-left: 28px;
    padding-bottom: 24px;
    width: 50%;
  }
}
@media (max-width: calc(1024px - 1px)) {
  .container {
    width: 100%;
  }
}

.title {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.periodEndTitle {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 12px;
}

.periodEndItem {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.periodInput {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border: none;
  text-align: center;
  background-color: #e0e0e0;
  padding: 6px;
  border-radius: 6px;
  margin-left: 4px;
  margin-right: 4px;
  outline: none;
  user-select: none;
  position: relative;
}
.periodInput::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
}

.countInput {
  width: 4ch;
  outline: none;
  position: relative;
}
.countInput::-webkit-outer-spin-button, .countInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}