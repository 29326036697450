.container {
  height: 46px;
  border-bottom: 1px solid #E5E5E5;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  flex-grow: 1;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
}

.documentIcon, .openIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
}

.documentIcon {
  background-image: url("../../../img/icons/document-icon.svg");
  margin-right: 14px;
}

.openIcon {
  background-image: url("../../../img/icons/open-document-icon.svg");
  cursor: pointer;
  margin-left: 14px;
}