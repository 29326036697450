/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  min-height: 100vh;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .pageContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}

.form {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .form {
    padding: 0;
    width: 50%;
  }
}
.form textarea {
  min-height: 287px;
}

.input {
  font-size: 13px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
}
@media (min-width: 768px) {
  .buttonsContainer {
    padding-top: 0;
    padding-bottom: 0;
    width: 350px;
    margin: 32px auto;
  }
}
@media (min-width: 1440px) {
  .buttonsContainer {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13px;
  }
}

@media (min-width: 1440px) {
  .searchContainer {
    border-bottom: 1px solid #D7D7D7;
  }
}

.search {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .search {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.listContainer {
  padding-top: 20px;
}

@media (max-width: calc(1440px - 1px)) {
  .hideMobile {
    display: none;
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}