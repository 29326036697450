.container {
    width: 104px;
}

.label {

}

.rangesContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
