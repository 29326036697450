@import url(https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap);
@import url(https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap);
@import url(https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap);
@import url(https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap);
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  /*@include mobile-portrait() {
      height: 100vw;
      width: 100vh;
      transform: rotate(90deg);
  }*/
}

html {
  box-sizing: border-box;
}
@media (min-width: 1440px) {
  html {
    background-color: #FAFAFA;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.LoginScene_backgroundContainer__1ezi1 {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(/static/media/authBackground.7b39504b.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.LoginScene_container__2zL7L {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginScene_logo__21psp {
  height: 68px;
  width: 95%;
  background-image: url(/static/media/header-logo.7572eaa4.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.LoginScene_modalContainer__3ZrR6 {
  margin-top: 72px;
}

.LoginScene_passwordOptions__3TMKL {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LoginScene_rememberPassword__2M8eR {
  display: flex;
  font-size: 12px;
  line-height: 25px;
  color: #6C6C6C;
}
@media (min-width: 1440px) {
  .LoginScene_rememberPassword__2M8eR {
    font-size: 16px;
  }
}
.LoginScene_rememberPassword__2M8eR .LoginScene_checkbox__2U9A7 {
  margin-bottom: 0;
}
@media (max-width: calc(1440px - 1px)) {
  .LoginScene_rememberPassword__2M8eR .LoginScene_checkbox__2U9A7 {
    padding-top: 1px;
  }
}
@media (max-width: calc(768px - 1px)) {
  .LoginScene_rememberPassword__2M8eR .LoginScene_checkbox__2U9A7 {
    padding-top: 2px;
  }
}

.LoginScene_restorePasswordLink__g7tm5 {
  font-size: 12px;
  line-height: 25px;
  color: #E53935;
}
@media (min-width: 1440px) {
  .LoginScene_restorePasswordLink__g7tm5 {
    font-size: 16px;
    line-height: 25px;
    color: #E53935;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.AuthModal_container__1s37C {
  background-color: #FAFAFA;
  min-height: 250px;
  padding: 12px 16px 24px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 42px;
}
@media (min-width: 768px) {
  .AuthModal_container__1s37C {
    padding-top: 24px;
  }
}
@media (min-width: 1440px) {
  .AuthModal_container__1s37C {
    max-width: 460px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.auth-modal__head {
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}
@media (min-width: 768px) {
  .auth-modal__head {
    font-size: 26px;
    line-height: 25px;
    margin-bottom: 43px;
  }
}
@media (min-width: 1440px) {
  .auth-modal__head {
    font-size: 32px;
    margin-bottom: 31px;
  }
}
.auth-modal__form {
  width: 100%;
  margin-bottom: 12px;
}
.auth-modal__buttons-container {
  margin-top: 16px;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
}
@media (min-width: 768px) {
  .auth-modal__buttons-container {
    margin-top: 24px;
  }
}
@media (min-width: 1440px) {
  .auth-modal__buttons-container {
    padding-left: 85px;
    padding-right: 85px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.AuthInput_container__20Dlk {
  position: relative;
  width: 100%;
  height: 32px;
  margin-top: 8px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  overflow: hidden;
  display: flex;
}
@media (min-width: 768px) {
  .AuthInput_container__20Dlk {
    height: 40px;
  }
}
@media (min-width: 1440px) {
  .AuthInput_container__20Dlk {
    height: 50px;
    margin-top: 12px;
  }
}
.AuthInput_container__20Dlk:first-of-type {
  margin-top: 0;
}

.AuthInput_icon__A7qgz {
  width: 32px;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
}
@media (min-width: 768px) {
  .AuthInput_icon__A7qgz {
    background-size: 16px 16px;
    width: 48px;
  }
}
@media (min-width: 1440px) {
  .AuthInput_icon__A7qgz {
    width: 70px;
  }
}

.AuthInput_textLabel__18c2E {
  display: flex;
  align-items: center;
  padding-left: 12px;
  width: 90px;
  height: 100%;
  font-size: 12px;
  color: #000;
  background-color: #F0F0F0;
}
@media (min-width: 768px) {
  .AuthInput_textLabel__18c2E {
    font-size: 16px;
    line-height: 16px;
    width: 102px;
    flex-shrink: 0;
  }
}
@media (min-width: 1440px) {
  .AuthInput_textLabel__18c2E {
    font-size: 20px;
    line-height: 25px;
    font-weight: normal;
    padding-left: 18px;
    padding-right: 20px;
    width: 124px;
  }
}

.AuthInput_input__muuAZ {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  padding-left: 12px;
  font-size: 12px;
  line-height: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #FFFFFF;
  outline: none;
}
@media (min-width: 768px) {
  .AuthInput_input__muuAZ {
    font-size: 14px;
    line-height: 25px;
  }
}
@media (min-width: 1440px) {
  .AuthInput_input__muuAZ {
    font-size: 18px;
  }
}
.AuthInput_input__muuAZ:focus {
  outline: 0;
}
.AuthInput_input__muuAZ:-webkit-autofill {
  background-color: #FFFFFF !important;
}
.AuthInput_input__muuAZ:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
}
.AuthInput_input__muuAZ::-ms-clear {
  display: none;
}
.AuthInput_input__muuAZ::-ms-reveal {
  display: none;
}

.AuthInput_eyeIcon__3k1PZ {
  position: absolute;
  right: 8px;
  width: 14px;
  height: 100%;
  background-image: url(/static/media/eye-icon.b088bd76.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
@media (min-width: 768px) {
  .AuthInput_eyeIcon__3k1PZ {
    width: 20px;
    right: 12px;
  }
}
@media (min-width: 1440px) {
  .AuthInput_eyeIcon__3k1PZ {
    width: 25px;
    right: 12px;
  }
}

.AuthInput_hint__1Dp4S {
  font-size: 10px;
  color: #989898;
  line-height: 16px;
  padding-left: 8px;
  font-weight: 300;
}
@media (min-width: 768px) {
  .AuthInput_hint__1Dp4S {
    padding-right: 30%;
  }
}
@media (min-width: 1440px) {
  .AuthInput_hint__1Dp4S {
    font-size: 12px;
    line-height: 16px;
    padding-top: 4px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.AuthButton_container__R9ONM {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 25px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #E53935;
  cursor: pointer;
  margin-top: 0;
}
.AuthButton_container__R9ONM:first-of-type {
  margin-top: 0;
}
@media (min-width: 768px) {
  .AuthButton_container__R9ONM {
    font-size: 18px;
    height: 40px;
  }
}

.AuthButton_primary__B1VZf {
  font-weight: bold;
  border-radius: 10px;
  color: #FFFFFF;
  background-color: #E53935;
}
@media (min-width: 1440px) {
  .AuthButton_primary__B1VZf {
    font-size: 20px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Checkbox_checkbox__2pR3O {
  line-height: 15px;
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.Checkbox_checkbox__2pR3O:not(:first-of-type) {
  margin-left: 24px;
}
.Checkbox_checkbox__2pR3O input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Checkbox_checkbox__2pR3O input[type=checkbox]:checked ~ .Checkbox_checkmark__10-z5 {
  background-color: #FF3B30;
  border: none;
}
.Checkbox_checkbox__2pR3O input[type=checkbox]:checked ~ .Checkbox_checkmark__10-z5:after {
  display: block;
}
.Checkbox_checkbox__2pR3O .Checkbox_checkmark__10-z5 {
  position: absolute;
  top: 2px;
  left: 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #989898;
  height: 12px;
  width: 12px;
}
@media (min-width: 1440px) {
  .Checkbox_checkbox__2pR3O .Checkbox_checkmark__10-z5 {
    height: 16px;
    width: 16px;
    top: 0;
  }
  .Checkbox_checkbox__2pR3O .Checkbox_checkmark__10-z5:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url(/static/media/check-icon.6bbc8e09.svg);
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: center;
  }
}
.Checkbox_checkbox__2pR3O:hover .Checkbox_checkmark__10-z5 {
  background-color: #ccc;
}

.Checkbox_filterCheckbox__EBPHq {
  line-height: 15px;
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.Checkbox_filterCheckbox__EBPHq input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.Checkbox_filterCheckbox__EBPHq input[type=checkbox]:checked ~ .Checkbox_checkmark__10-z5 {
  background-color: #FF3B30;
}
.Checkbox_filterCheckbox__EBPHq input[type=checkbox]:checked ~ .Checkbox_checkmark__10-z5:after {
  display: block;
}
.Checkbox_filterCheckbox__EBPHq .Checkbox_checkmark__10-z5 {
  position: absolute;
  top: 2px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #DFDFDF;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .Checkbox_filterCheckbox__EBPHq .Checkbox_checkmark__10-z5:after {
    content: "";
    position: absolute;
    display: none;
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
.Checkbox_filterCheckbox__EBPHq:hover .Checkbox_checkmark__10-z5 {
  background-color: #ccc;
}
.Spinner_spinner__1Nh-g {
  height: 36px;
  vertical-align: top;
}
.AuthScene_container__2Itbw {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordsListScene_container__N9i-o {
  position: relative;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .RecordsListScene_container__N9i-o {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .RecordsListScene_container__N9i-o {
    padding-top: 37px;
  }
}

@media (min-width: 1440px) {
  .RecordsListScene_recordControlsContainer__3xQ9e {
    border-bottom: 1px solid #D7D7D7;
  }
}

.RecordsListScene_spinnerContainer__14c6_ {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .RecordsListScene_listContainer__dgE8n {
    padding-top: 54px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Pagination_stickyWrapper__3NK-5 {
  height: 82px;
  width: 100%;
}
@media (min-width: 1440px) {
  .Pagination_stickyWrapper__3NK-5 {
    height: 50px;
  }
}

.Pagination_container__GPTNG {
  height: 82px;
  width: 100%;
  background-color: #FAFAFA;
  border: 1px solid #C4C4C4;
  padding: 8px 16px 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: calc(1440px - 1px)) {
  .Pagination_container__GPTNG.Pagination_noWrap__kGrsc {
    flex-wrap: nowrap;
    align-items: flex-end;
  }
  .Pagination_container__GPTNG.Pagination_noWrap__kGrsc .Pagination_recordsCount__UwRi2 {
    flex-grow: unset;
  }
}
@media (min-width: 768px) {
  .Pagination_container__GPTNG.Pagination_fixed__1l5GO {
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
@media (min-width: 1440px) {
  .Pagination_container__GPTNG {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    position: absolute;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    flex-wrap: nowrap;
    height: 50px;
    width: 100%;
  }
}

.Pagination_buttonContainer__cFwno {
  width: 100%;
}
@media (min-width: 1440px) {
  .Pagination_buttonContainer__cFwno {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    width: 160px;
    order: 3;
  }
}

.Pagination_button__2x96t {
  cursor: pointer;
  width: 150px;
  color: #808080;
  background-color: #FAFAFA;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  padding: 6px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
}
@media (min-width: 1440px) {
  .Pagination_button__2x96t {
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.Pagination_recordsCount__UwRi2 {
  flex-grow: 1;
  margin-top: 10px;
  color: #C6C6C6;
  font-size: 16px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .Pagination_recordsCount__UwRi2 {
    order: 2;
    margin-left: 320px;
    margin-top: 0;
  }
}
.Pagination_recordsCount__UwRi2 .Pagination_recordsNumber__3YOsL {
  color: #808080;
  font-weight: 500;
}
.Pagination_recordsCount__UwRi2.Pagination_right__Xp4g8 {
  text-align: right;
}

.Pagination_pages__1krIX {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .Pagination_pages__1krIX {
    margin-top: 0;
    order: 1;
  }
}

.Pagination_pageNumber__2zvG4 {
  margin-left: 12px;
  color: #C6C6C6;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.Pagination_pageNumber__2zvG4:last-of-type {
  margin-right: 12px;
}
.Pagination_pageNumber__2zvG4:first-child {
  margin-left: 18px;
}
.Pagination_pageNumber__2zvG4:last-child {
  margin-right: 18px;
}
.Pagination_pageNumber__2zvG4:hover {
  color: #808080;
}

.Pagination_pageCurrent__2oqjz {
  color: #808080;
  font-weight: 500;
}

.Pagination_backIcon__UFc-R, .Pagination_nextIcon__do35- {
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 10px;
}

.Pagination_backIcon__UFc-R {
  background-image: url(/static/media/back-page-icon.e503736f.svg);
}

.Pagination_nextIcon__do35- {
  background-image: url(/static/media/next-page-icon.9c4041bf.svg);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordsControls_container__2uGWe {
  padding-top: 11px;
  padding-bottom: 25px;
}
@media (min-width: 1440px) {
  .RecordsControls_container__2uGWe {
    padding-bottom: 31px;
  }
}

@media (min-width: 1440px) {
  .RecordsControls_controlsContainer__2PuLr {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.RecordsControls_searchContainer__1n7bU {
  width: 100%;
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .RecordsControls_searchContainer__1n7bU {
    width: 40%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.RecordsControls_inputContainer__2He5t {
  flex-grow: 1;
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.RecordsControls_input__243vm {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 30px 8px 22px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}
.RecordsControls_input__243vm::-webkit-input-placeholder {
  color: #D9D9D9;
}
.RecordsControls_input__243vm::placeholder {
  color: #D9D9D9;
}
.RecordsControls_input__243vm:focus {
  outline: none;
  border-color: #E53935;
}

.RecordsControls_searchIcon__2Xpx3, .RecordsControls_filterIcon__2s0D9 {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.RecordsControls_searchIcon__2Xpx3 {
  background-image: url(/static/media/search-icon.bbe8b952.svg);
  position: absolute;
  right: 8px;
  top: 12px;
  height: 20px;
  width: 20px;
}

.RecordsControls_filterIcon__2s0D9 {
  margin: 11px 0 11px 16px;
  background-image: url(/static/media/filter-icon.3476e906.svg);
  height: 22px;
  width: 22px;
}
.RecordsControls_filterIcon__2s0D9.RecordsControls_open__1XJz3 {
  background-image: url(/static/media/filter-icon-active.e586b60d.svg);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Filter_container__5puZU {
  display: block;
  position: relative;
}
@media (min-width: 1440px) {
  .Filter_container__5puZU {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    margin-top: 25px;
    border-radius: 10px;
    overflow: hidden;
  }
}

.Filter_flexContainer__13_yQ {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 27px;
}
@media (min-width: 1440px) {
  .Filter_flexContainer__13_yQ {
    padding-top: 8px;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    height: 604px;
  }
}

@media (min-width: 1440px) {
  .Filter_datePicker__2zWqU {
    order: 2;
  }
}

.Filter_form__2UOib {
  width: 70%;
}
@media (min-width: 1440px) {
  .Filter_form__2UOib {
    height: 100%;
    order: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
  }
}

.Filter_section__2l45u {
  margin-top: 22px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}
@media (min-width: 1440px) {
  .Filter_section__2l45u {
    max-width: 200px;
  }
}

.Filter_sectionTitle__2phIf {
  padding-bottom: 7px;
  border-bottom: 1px solid #D9D9D9;
}

.Filter_sectionContent__Q9eKi {
  padding-top: 13px;
  display: block;
}
@media (min-width: 1440px) {
  .Filter_sectionContent__Q9eKi.Filter_category__3w5uk {
    max-height: 600px;
    overflow-y: scroll;
  }
}

.Filter_buttonsContainer__Hkelc {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #FFFFFF;
  height: 60px;
  border-top: 1px solid #D9D9D9;
  z-index: 10;
}
@media (min-width: 1440px) {
  .Filter_buttonsContainer__Hkelc {
    position: relative;
    background-color: transparent;
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: none;
    padding-right: 50px;
  }
}

.Filter_button__yI2Us {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #E53935;
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .Filter_button__yI2Us {
    width: 100px;
    margin-left: 22px;
  }
}
.rdrCalendarWrapper {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
}

.rdrDateDisplay {
  background-color: #FFFFFF !important;
}

.rdrMonthPicker select, .rdrMonthPicker option, .rdrYearPicker select, .rdrYearPicker option {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 0 !important;
}
.rdrMonthPicker select, .rdrYearPicker select {
  text-align: right;
}
.rdrMonthPicker select:hover, .rdrYearPicker select:hover {
  background-color: #FFFFFF !important;
}

.rdrDay {
  height: 44px !important;
  width: 44px !important;
}

.rdrDayNumber span {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.867782);
}
.rdrDayNumber span:after {
  background-color: #E53935 !important;
}

.rdrStartEdge, .rdrDayStartPreview {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  left: 2px !important;
}

.rdrEndEdge, .rdrDayEndPreview {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  right: 2px !important;
}

.rdrInRange, .rdrEndEdge, .rdrStartEdge,
.rdrDayInPreview, .rdrDayStartPreview, .rdrDayEndPreview {
  top: 2px !important;
  bottom: 2px !important;
}

.rdrDayEndOfWeek .rdrInRange {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  right: 2px !important;
}

.rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  left: 2px !important;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.DatePicker_container__2XA5K {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 1440px) {
  .DatePicker_container__2XA5K {
    width: 382px;
    margin-top: 22px;
  }
}

.DatePicker_labelContainer__f2o0l {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .DatePicker_labelContainer__f2o0l {
    margin-bottom: 12px;
  }
}

.DatePicker_label__3ly5v {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
}

.DatePicker_icon__1d5FY {
  background-image: url(/static/media/calendar-icon.c44cc4bb.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordActions_container__3LEbc {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 1440px) {
  .RecordActions_container__3LEbc {
    width: 50%;
    justify-content: flex-end;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.RecordActions_addButton__3NpvG, .RecordActions_dropdownButton__2M9gG {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  -webkit-user-select: none;
          user-select: none;
}

.RecordActions_addButton__3NpvG {
  height: 44px;
  width: 44px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
}
@media (min-width: 1440px) {
  .RecordActions_addButton__3NpvG {
    margin-right: 25px;
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}

.RecordActions_dropdownButton__2M9gG {
  padding: 13px 58px 14px 40px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #E53935;
  background-image: url(/static/media/open-dropdown-icon.0799403a.svg);
  background-repeat: no-repeat;
  background-size: 10px 7px;
  background-position: right 40px center;
  height: 44px;
}

.RecordActions_dropdownPopupContainer__2x97R {
  width: 100%;
}

.RecordActions_dropdownPopup__1aR4o {
  top: 34px;
  border: 1px solid #D9D9D9;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
@media (min-width: 1440px) {
  .RecordActions_dropdownPopup__1aR4o {
    right: 0 !important;
    top: 36px !important;
  }
}
.RecordActions_dropdownPopup__1aR4o:before {
  border: none !important;
}
.RecordActions_dropdownPopup__1aR4o:after {
  border: none !important;
}

.RecordActions_popupButton__1jxJP {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  height: 50px;
  border-top: 1px solid #B3B3B3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.RecordActions_popupButton__1jxJP:first-of-type {
  border-top: none;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Popup_popupCloseOverlay__3uqMs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.Popup_container__2sKLz {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
.Popup_container__2sKLz .Popup_popup__weO_V {
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
.Popup_container__2sKLz .Popup_popup__weO_V:after, .Popup_container__2sKLz .Popup_popup__weO_V:before {
  bottom: 100%;
  right: 4px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.Popup_container__2sKLz .Popup_popup__weO_V:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
  border-width: 20px;
  margin-left: -8px;
}
.Popup_container__2sKLz .Popup_popup__weO_V:before {
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}
@media (min-width: 1440px) {
  .Popup_container__2sKLz .Popup_popup__weO_V {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    right: -13px;
    top: 39px;
  }
  .Popup_container__2sKLz .Popup_popup__weO_V:before, .Popup_container__2sKLz .Popup_popup__weO_V:after {
    content: none;
  }
}
.popup-menu-item {
  width: 236px;
  height: 45px;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  align-items: center;
  border: 1px solid #EDEDED;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  -webkit-user-select: none;
          user-select: none;
}
.popup-menu-item:first-of-type {
  border-top: none;
}
.CardCheckbox_container__2YUZj {
  display: block;
  position: absolute;
  top: 8px;
  right: 3px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Breadcrumbs_container__3gmdZ {
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  padding-left: 16px;
  padding-right: 48px;
  margin-bottom: 25px;
}
@media (min-width: 1440px) {
  .Breadcrumbs_container__3gmdZ {
    padding: 0;
    margin-bottom: 14px;
  }
}

.Breadcrumbs_link__1xERW:before {
  content: " / ";
  color: #000000;
}
.Breadcrumbs_link__1xERW:first-of-type:before {
  content: none;
}
.Breadcrumbs_link__1xERW:last-of-type {
  color: #989898;
}
.Breadcrumbs_link__1xERW:only-child {
  color: #000000;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ListContainer_container__389CM {
  padding-left: 16px;
  padding-right: 16px;
  opacity: 0;
  -webkit-transform: translate(0, 20px);
          transform: translate(0, 20px);
  transition: opacity 0.5s ease-out, -webkit-transform 0.5s ease-out;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
@media (min-width: 768px) {
  .ListContainer_container__389CM {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
@media (min-width: 1440px) {
  .ListContainer_container__389CM {
    padding-left: 0;
    padding-right: 0;
  }
}
.ListContainer_container__389CM.ListContainer_visible__3cwUl {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.ListContainer_spinnerContainer__1IW7r, .ListContainer_emptyText__2xDyG {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .ListContainer_spinnerContainer__1IW7r, .ListContainer_emptyText__2xDyG {
    padding-top: 32px;
  }
}

.ListContainer_emptyText__2xDyG {
  font-size: 18px;
  opacity: 0.7;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.GenericCard_container__3fc16 {
  display: block;
  border-radius: 12px;
  margin-bottom: 14px;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
}

.GenericCard_head__3HgML {
  height: 30px;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordCard_card__1j4me {
  background-color: #FAFAFA;
  height: 120px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow: visible;
}
@media (min-width: 768px) {
  .RecordCard_card__1j4me {
    width: calc((100% - 16px) / 2);
  }
  .RecordCard_card__1j4me:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .RecordCard_card__1j4me {
    width: calc((100% - 42px) / 2);
  }
  .RecordCard_card__1j4me:nth-child(odd) {
    margin-right: 42px;
  }
}
@media (min-width: 1440px) {
  .RecordCard_card__1j4me {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 190px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.RecordCard_head__28HjR {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
@media (max-width: calc(1920px - 1px)) {
  .RecordCard_head__28HjR {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.RecordCard_head__28HjR.RecordCard_headOnline__3713N {
  background-color: #FCE9D4;
}

.RecordCard_headInfo__1R_Uy {
  font-size: 11px;
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .RecordCard_headInfo__1R_Uy {
    font-size: 14px;
  }
}

.RecordCard_menuButton__1tGqd {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.RecordCard_icon__2Xp1b {
  width: 25px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1440px) {
  .RecordCard_icon__2Xp1b {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.RecordCard_checkboxLeftContainer__-iFtZ {
  position: relative;
  left: 14px;
  top: -16px;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .RecordCard_checkboxLeftContainer__-iFtZ {
    margin-right: 16px;
  }
}

.RecordCard_contentContainer__3opr- {
  padding-top: 7px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .RecordCard_contentContainer__3opr- {
    padding-top: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.RecordCard_dataContainer__3V_Xz {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  max-height: 50px;
}

.RecordCard_title__28hYG {
  color: #000000;
}
@media (min-width: 1440px) {
  .RecordCard_title__28hYG {
    font-size: 16px;
    line-height: 19px;
    width: 70%;
    display: block;
    min-height: 35px;
    margin-bottom: 40px;
  }
}

.RecordCard_player__1_dsZ {
  vertical-align: top;
  display: flex;
  height: 100%;
  width: 50%;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  margin-top: 16px;
}
@media (min-width: 1440px) {
  .RecordCard_player__1_dsZ {
    width: 60%;
  }
}

.RecordCard_playerIcon__2dQPh {
  height: 18px;
  width: 18px;
  background-image: url(/static/media/play-icon-active.27ea829c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.RecordCard_playerIconDisabled__2dn-P {
  background-image: url(/static/media/play-icon-disabled.fe71b4b5.svg);
}

.RecordCard_infoBlock__HMmt7 {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  max-width: 71%;
}
@media (min-width: 1440px) {
  .RecordCard_infoBlock__HMmt7 {
    max-width: 40%;
    line-height: 16px;
  }
}

.RecordCard_infoTitle__30Uvx {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.RecordCard_infoValue__3_Ut5 {
  color: rgba(0, 0, 0, 0.5);
}

.RecordCard_recordPopup__1_s53 {
  right: -16px;
  top: 36px;
  z-index: 1000;
}
@media (min-width: 1440px) {
  .RecordCard_recordPopup__1_s53 {
    top: 36px !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordStatus_container__-p5Td {
  display: inline-block;
  text-transform: uppercase;
  padding-left: 6px;
}
@media (min-width: 1440px) {
  .RecordStatus_container__-p5Td {
    padding-left: 8px;
  }
}

.RecordStatus_status__15CMw {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: inline-block;
  background-color: #989898;
  margin-right: 5px;
}
@media (min-width: 1440px) {
  .RecordStatus_status__15CMw {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
}

.RecordStatus_statusOnline__2ceAu {
  background-color: #02E30B;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.AudioPlayer_playerContainer__1uQfa {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  width: 100%;
}
.AudioPlayer_playerContainer__1uQfa .rhap_container {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_controls-section {
  flex: unset;
}
.AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_main-controls-button {
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_main-controls-button {
    justify-content: center;
  }
}
.AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_horizontal-reverse .rhap_controls-section {
  margin-right: 0;
}
@media (min-width: 1440px) {
  .AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 8px;
  }
}
.AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_progress-indicator, .AudioPlayer_playerContainer__1uQfa .rhap_container .rhap_progress-filled {
  background-color: #E53935;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RegisterScene_backgroundContainer__B0LIS {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(/static/media/authBackground.7b39504b.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.RegisterScene_container__101v0 {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RegisterScene_logo__R5X1m {
  height: 68px;
  width: 95%;
  background-image: url(/static/media/logo.4ccf4513.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.RegisterScene_modalContainer__1mHhR {
  margin-top: 32px;
}
@media (min-width: 768px) {
  .RegisterScene_modalContainer__1mHhR {
    margin-top: 72px;
  }
}

.RegisterScene_inputWithMargin__3-weQ {
  margin-bottom: 40px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Layout_container__16XPs {
  min-height: 100vh;
  padding-top: 65px;
}
@media (min-width: 1440px) {
  .Layout_container__16XPs {
    padding-top: 103px;
    background-color: #FAFAFA;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Header_container__21wXn {
  z-index: 10;
  position: fixed;
  width: 100vw;
  height: 65px;
  background-color: #EEEEEE;
  top: 0;
  color: #ffffff;
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .Header_container__21wXn {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    background-color: #EEEEEE;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.Header_logo__3T0lc {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/static/media/logo-mobile.8bda95c9.svg);
  height: 100%;
  width: 130px;
}
@media (min-width: 1440px) {
  .Header_logo__3T0lc {
    width: 166px;
    height: 44px;
    background-image: url(/static/media/header-logo-grey.3d84714f.svg);
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.HeaderMenu_container__3YswU {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.HeaderMenu_sectionRoute__2XUFq {
  display: none;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
  margin-right: 10px;
  -webkit-user-select: none;
          user-select: none;
}
.HeaderMenu_sectionRoute__2XUFq.HeaderMenu_active__2itXR {
  display: block;
}
@media (min-width: 1440px) {
  .HeaderMenu_sectionRoute__2XUFq {
    display: block;
    font-size: 17.5px;
    line-height: 25px;
    color: #8B8C9A;
    margin-left: 51px;
    margin-right: 50px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: none;
  }
  .HeaderMenu_sectionRoute__2XUFq:hover {
    color: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
  }
  .HeaderMenu_sectionRoute__2XUFq.HeaderMenu_active__2itXR {
    text-transform: none;
    color: #000000;
  }
  .HeaderMenu_sectionRoute__2XUFq.HeaderMenu_active__2itXR:hover {
    color: #000000;
  }
}

.HeaderMenu_icon__8F6Fk {
  height: 20px;
  width: 17px;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .HeaderMenu_icon__8F6Fk {
    display: none;
  }
}

.HeaderMenu_menu__E7mbs {
  height: calc(100vh - 65px);
  padding-top: 10px;
  opacity: 0;
  position: fixed;
  top: 65px;
  background-color: #F5F5F5;
  font-size: 17.5px;
  right: -100%;
  width: 100vw;
  transition: right 0.3s;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .HeaderMenu_menu__E7mbs {
    width: 350px;
  }
}

.HeaderMenu_menuVisible__2uRad {
  overflow-y: scroll;
  opacity: 1;
  right: 0;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .HeaderMenu_menuVisible__2uRad {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .HeaderMenu_menuVisible__2uRad {
    display: none;
  }
}

.HeaderMenu_item__3jyOK {
  color: #8B8C9A;
  background-color: #EEEEEE;
  padding: 16px;
  margin-top: 10px;
  display: block;
}
.HeaderMenu_item__3jyOK:focus, .HeaderMenu_item__3jyOK:active, .HeaderMenu_item__3jyOK:hover {
  color: #8B8C9A;
}

.HeaderMenu_item__3jyOK:first-of-type {
  margin-top: 0;
}

.HeaderMenu_profile__1nhsx {
  margin-top: 10px;
  padding-left: 48px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-image: url(/static/media/profile-icon.b346a4a2.svg);
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 20px 18px;
  color: #8B8C9A;
}
.HeaderMenu_profile__1nhsx:hover {
  background-image: url(/static/media/profile-icon-active.5cd3adb6.svg);
}

.HeaderMenu_subItem__tlDVz {
  display: block;
  color: #000000;
  padding: 28px 40px;
}

.HeaderMenu_tabs__3rTOn {
  display: none;
}
@media (min-width: 1440px) {
  .HeaderMenu_tabs__3rTOn {
    display: flex;
    justify-content: center;
    position: absolute;
    background: #FAFAFA;
    color: #BBBBBB;
    overflow: hidden;
    height: 41px;
    top: 100%;
    left: calc((100vw - 1150px) / 2);
    right: calc((100vw - 1150px) / 2);
  }
}

.HeaderMenu_tabsItem__10gx1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-basis: 0;
  flex-grow: 1;
  cursor: pointer;
  white-space: nowrap;
}
.HeaderMenu_tabsItem__10gx1:last-of-type {
  border-right: none;
}
.HeaderMenu_tabsItem__10gx1:hover {
  color: #E53935;
}

.HeaderMenu_tabsItemSelected__2ZBnL {
  border-bottom: 2px solid #E53935;
  color: #E53935;
}

@media (min-width: 1440px) {
  .HeaderMenu_profileIcon__VUvvU {
    display: block;
    background-image: url(/static/media/profile-icon.b346a4a2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
    margin-left: 24px;
    cursor: pointer;
  }
  .HeaderMenu_profileIcon__VUvvU.HeaderMenu_active__2itXR, .HeaderMenu_profileIcon__VUvvU:hover {
    background-image: url(/static/media/profile-icon-active.5cd3adb6.svg);
  }
}

.HeaderMenu_backgroundFill__1R_51 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  right: 0;
  left: 0;
  top: 65px;
  transition: opacity 0.4s linear;
}
.HeaderMenu_backgroundFill__1R_51.HeaderMenu_visible__hiNc9 {
  opacity: 1;
}
@media (min-width: 1440px) {
  .HeaderMenu_backgroundFill__1R_51 {
    display: none;
  }
}

@media (min-width: 1440px) {
  .HeaderMenu_hideDesktop__25reQ {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Footer_container__188QZ {
  background-color: #DCDCDC;
  height: 51px;
  width: 100%;
  background-image: url(/static/media/inpro-icon.40240ec7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 114px 37px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ScrollButton_button__3-fx- {
  position: fixed;
  right: 18px;
  bottom: -150px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-image: url(/static/media/scroll-top-icon.f4755052.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 16px;
  transition: bottom 0.4s ease-in-out;
}
@media (min-width: 1440px) {
  .ScrollButton_button__3-fx- {
    display: none;
  }
}
.ScrollButton_button__3-fx-.ScrollButton_visible__Gupj6 {
  bottom: 100px;
}
.ModalRoot_container__3ALvS {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 100;
}

.ModalRoot_background__1b7jH {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Modal_container__1xldP {
  padding: 18px 13px 13px;
  color: #000000;
  background-color: #FFFFFF;
  z-index: 150;
}
@media (min-width: 1024px) {
  .Modal_container__1xldP {
    max-width: 50vw;
  }
}
@media (min-width: 1440px) {
  .Modal_container__1xldP {
    max-width: 25vw;
  }
}

.Modal_title__3wrnG {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.Modal_text__23DNO {
  font-size: 13px;
  line-height: 15px;
  margin-top: 17px;
}

.Modal_buttonsContainer__26r-u {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.Modal_button__3qiHs {
  font-size: 14px;
  line-height: 16px;
  color: #E53935;
  cursor: pointer;
}
.Modal_button__3qiHs:not(:first-child) {
  margin-left: 24px;
}
.Modal_button__3qiHs:hover {
  text-decoration: underline;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordScene_container__3aFq5 {
  padding-top: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 65px);
}
@media (min-width: 1440px) {
  .RecordScene_container__3aFq5 {
    padding-top: 24px;
    min-height: calc(100vh - 103px);
  }
}

.RecordScene_spinnerContainer__2M6fO {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RecordScene_controlsContainer__3Wdwt {
  display: none;
}
@media (min-width: 1440px) {
  .RecordScene_controlsContainer__3Wdwt {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    display: block;
    border-bottom: 1px solid #D7D7D7;
  }
}

.RecordScene_mainContainer__2EQ5U {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1440px) {
  .RecordScene_mainContainer__2EQ5U {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    padding-top: 14px;
  }
}

.RecordScene_backLink__3CiYU {
  display: none;
}
@media (min-width: 1440px) {
  .RecordScene_backLink__3CiYU {
    display: block;
    padding-left: 44px;
    font-size: 17.5px;
    line-height: 25px;
    color: #E53935;
    background-image: url(/static/media/arrow-back-icon.e9d50a2f.svg);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 33px 22px;
  }
}
.RecordScene_backLink__3CiYU:hover {
  color: #E53935;
}

.RecordScene_statusInfo__3YC6d {
  padding-top: 8px;
  padding-bottom: 4px;
  padding-left: 30px;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
  background-image: url(/static/media/record-icon.60da39a7.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 25px 16px;
}
@media (min-width: 1440px) {
  .RecordScene_statusInfo__3YC6d {
    margin-top: 26px;
  }
}

.RecordScene_hintText__Y2gp_ {
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #989898;
  margin-bottom: 10px;
}

@media (min-width: 1440px) {
  .RecordScene_flexContainer__3bd1n {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.RecordScene_title__NzUjJ {
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (min-width: 1440px) {
  .RecordScene_title__NzUjJ {
    order: 1;
    margin-bottom: 0;
  }
}

.RecordScene_player__3Xz9I {
  vertical-align: top;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 1440px) {
  .RecordScene_player__3Xz9I {
    width: 100%;
    order: 3;
    margin-top: 24px;
  }
}
.RecordScene_player__3Xz9I .RecordScene_hintText__Y2gp_ {
  width: 100%;
  text-align: left;
  margin-bottom: 17px;
}

.RecordScene_playerIcon__3yC-K {
  height: 18px;
  width: 18px;
  background-image: url(/static/media/play-icon-active.27ea829c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.RecordScene_playerRange__Gq-r3 {
  display: inline-block;
  -webkit-appearance: none;
          appearance: none;
  width: 80%;
  margin-left: 12px;
}
@media (min-width: 1440px) {
  .RecordScene_playerRange__Gq-r3 {
    width: 90%;
  }
}
.RecordScene_playerRange__Gq-r3::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.RecordScene_playerRange__Gq-r3::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.RecordScene_playerRange__Gq-r3:focus {
  outline: none;
  background: none;
}
.RecordScene_playerRange__Gq-r3::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.RecordScene_playerRange__Gq-r3::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.RecordScene_playerRange__Gq-r3::-ms-track {
  height: 2px;
  border: transparent;
}
.RecordScene_playerRange__Gq-r3::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.RecordScene_playerRange__Gq-r3::-ms-fill-lower {
  background: #E53935;
}
.RecordScene_playerRange__Gq-r3::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.RecordScene_buttonsContainer__3qKPp {
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 26px;
}
@media (min-width: 1440px) {
  .RecordScene_buttonsContainer__3qKPp {
    order: 2;
    width: -webkit-max-content;
    width: max-content;
    margin-bottom: 0;
  }
}

.RecordScene_button__2LhTa {
  display: inline-flex;
  align-items: center;
  margin-left: 21px;
  cursor: pointer;
  height: 100%;
  background-color: #E53935;
  color: #ffffff;
  font-size: 14px;
  line-height: 16px;
  padding: 0 21px;
  border-radius: 2px;
  -webkit-user-select: none;
          user-select: none;
}
.RecordScene_button__2LhTa:nth-child(2) {
  margin-left: 0;
}
@media (min-width: 1440px) {
  .RecordScene_button__2LhTa:first-child {
    margin-left: 0;
  }
  .RecordScene_button__2LhTa:nth-child(2) {
    margin-left: 21px;
  }
}

.RecordScene_buttonGray__3s90R {
  display: none;
  background-color: #6C6C6C;
  color: #ffffff;
}
@media (min-width: 1440px) {
  .RecordScene_buttonGray__3s90R {
    display: inline-flex;
  }
}

.RecordScene_mobileButton__23-0h {
  cursor: pointer;
  width: 100%;
  background-color: #6C6C6C;
  border-radius: 2px;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 7px;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-top: 10px;
}
@media (min-width: 1440px) {
  .RecordScene_mobileButton__23-0h {
    display: none;
  }
}

.RecordScene_mobileButtonLight__38CNG {
  background-color: #ffffff;
  border: 1px solid #989898;
  color: #000000;
}

.RecordScene_mobileButtonActive__4M9kY {
  color: #ffffff;
  background-color: #E53935;
}

.RecordScene_createdAt__1zIM6 {
  width: 100%;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  padding-right: 22px;
  height: 81px;
  background-color: #FAFAFA;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
}
@media (min-width: 1440px) {
  .RecordScene_createdAt__1zIM6 {
    height: 50px;
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    bottom: 0;
    left: 0;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordInfoSection_container__1SCqL {
  border-top: 1px solid #989898;
  width: 100%;
  margin-top: 10px;
  padding: 6px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .RecordInfoSection_container__1SCqL {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
    margin-top: 30px;
    border-top: none;
  }
}
.RecordInfoSection_container__1SCqL.RecordInfoSection_open__2U-WX .RecordInfoSection_content__pbEZz {
  display: block;
}
.RecordInfoSection_container__1SCqL .RecordInfoSection_content__pbEZz {
  display: none;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 16px;
}
@media (min-width: 1440px) {
  .RecordInfoSection_container__1SCqL .RecordInfoSection_content__pbEZz {
    display: flex;
    flex-wrap: wrap;
  }
}

.RecordInfoSection_dropdownIcon__3se8K {
  background-image: url(/static/media/dropdown-icon-black.e44e7bd5.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 15px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
@media (min-width: 1440px) {
  .RecordInfoSection_dropdownIcon__3se8K {
    display: none;
  }
}
.RecordInfoSection_dropdownIcon__3se8K.RecordInfoSection_open__2U-WX {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.RecordInfoSection_section__1cBoL {
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  margin-top: 24px;
}
.RecordInfoSection_section__1cBoL:first-of-type {
  margin-top: 0;
}
@media (min-width: 1440px) {
  .RecordInfoSection_section__1cBoL {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-left: 64px;
    max-height: 170px;
    flex-wrap: wrap;
  }
  .RecordInfoSection_section__1cBoL:first-of-type {
    margin-left: 0;
  }
}

.RecordInfoSection_sectionLabel__19t9J {
  color: #212121;
  margin-bottom: 16px;
}
.RecordInfoSection_infoContainer__39sLP {
  width: 50%;
  display: inline-block;
  margin-bottom: 16px;
}
@media (min-width: 1440px) {
  .RecordInfoSection_infoContainer__39sLP {
    width: unset;
  }
  .RecordInfoSection_infoContainer__39sLP:nth-child(2n+4) {
    margin-top: 32px;
  }
  .RecordInfoSection_infoContainer__39sLP:nth-child(n+4) {
    margin-left: 40px;
  }
}

.RecordInfoSection_name__1k1rI {
  color: rgba(0, 0, 0, 0.539261);
}

.RecordInfoSection_value__3TSL8 {
  color: #212121;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ArchiveListScene_container__3joqq {
  position: relative;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .ArchiveListScene_container__3joqq {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .ArchiveListScene_container__3joqq {
    padding-top: 37px;
  }
}

@media (min-width: 1440px) {
  .ArchiveListScene_recordControlsContainer__XVCiS {
    border-bottom: 1px solid #D7D7D7;
  }
}

.ArchiveListScene_spinnerContainer__252PI {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .ArchiveListScene_recordsContainer__3ZL2G {
    padding-top: 54px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.DemoListScene_container__3IdN5 {
  position: relative;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .DemoListScene_container__3IdN5 {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .DemoListScene_container__3IdN5 {
    padding-top: 37px;
  }
}

.DemoListScene_spinnerContainer__Bc1Fv {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .DemoListScene_recordControlsContainer__1cJsD {
    border-bottom: 1px solid #D7D7D7;
  }
}

@media (min-width: 1440px) {
  .DemoListScene_recordsContainer__1_ApD {
    padding-top: 54px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectsListScene_container__1NfkD {
  padding-top: 20px;
  padding-top: 35px;
}
@media (min-width: 1440px) {
  .ObjectsListScene_container__1NfkD {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .ObjectsListScene_container__1NfkD {
    padding-top: 28px;
  }
}

.ObjectsListScene_spinnerContainer__3H0ie {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: calc(1440px - 1px)) {
  .ObjectsListScene_controlsContainer__1h6x_ {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 1440px) {
  .ObjectsListScene_controlsContainer__1h6x_ {
    border-bottom: 1px solid #D7D7D7;
    justify-content: flex-start;
  }
}

@media (min-width: 1440px) {
  .ObjectsListScene_searchContainer__qKF4O {
    border-bottom: 1px solid #D7D7D7;
  }
}

@media (min-width: 768px) {
  .ObjectsListScene_searchControls__2DrVR {
    display: flex;
    justify-content: space-between;
  }
}

.ObjectsListScene_searchInput__3TEE5 {
  width: 100%;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 8px;
}
@media (min-width: 768px) {
  .ObjectsListScene_searchInput__3TEE5 {
    width: 50%;
    margin-bottom: 0;
  }
}
@media (min-width: 1440px) {
  .ObjectsListScene_searchInput__3TEE5 {
    width: 40%;
  }
}

.ObjectsListScene_createButton__1VUZx {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 44px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectsListScene_createButton__1VUZx {
    background-position: left 12px center;
    padding-left: 22px;
  }
}
@media (min-width: 768px) {
  .ObjectsListScene_createButton__1VUZx {
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .ObjectsListScene_createButton__1VUZx {
    order: 3;
    width: 214px;
    background-size: 20px;
    font-size: 14px;
    padding-left: 56px;
    justify-content: flex-start;
    margin-left: 32px;
  }
}

.ObjectsListScene_countContainer__Mprgw {
  display: none;
}
@media (min-width: 1440px) {
  .ObjectsListScene_countContainer__Mprgw {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
}

.ObjectsListScene_count__2A_q5 {
  color: #E53935;
  margin-left: 32px;
}

@media (min-width: 1440px) {
  .ObjectsListScene_listContainer__3jDXc {
    padding-top: 20px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordsControls_container__3liwC {
  padding-top: 11px;
  padding-bottom: 25px;
}
@media (min-width: 1440px) {
  .RecordsControls_container__3liwC {
    padding-bottom: 31px;
  }
}

@media (min-width: 1440px) {
  .RecordsControls_controlsContainer__jFzZe {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.RecordsControls_searchContainer__15Zlf {
  width: 100%;
  height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .RecordsControls_searchContainer__15Zlf {
    width: 40%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.RecordsControls_inputContainer__2vCMV {
  flex-grow: 1;
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.RecordsControls_input__oqAFx {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 30px 8px 22px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}
.RecordsControls_input__oqAFx::-webkit-input-placeholder {
  color: #D9D9D9;
}
.RecordsControls_input__oqAFx::placeholder {
  color: #D9D9D9;
}
.RecordsControls_input__oqAFx:focus {
  outline: none;
  border-color: #E53935;
}

.RecordsControls_searchIcon__1h_G6, .RecordsControls_filterIcon__2NtKb {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.RecordsControls_searchIcon__1h_G6 {
  background-image: url(/static/media/search-icon.bbe8b952.svg);
  position: absolute;
  right: 8px;
  top: 12px;
  height: 20px;
  width: 20px;
}

.RecordsControls_filterIcon__2NtKb {
  margin: 11px 0 11px 16px;
  background-image: url(/static/media/filter-icon.3476e906.svg);
  height: 22px;
  width: 22px;
}
.RecordsControls_filterIcon__2NtKb.RecordsControls_open__S1afW {
  background-image: url(/static/media/filter-icon-active.e586b60d.svg);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Filter_container__3PDVc {
  display: none;
  position: relative;
}
@media (min-width: 1440px) {
  .Filter_container__3PDVc {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    margin-top: 25px;
    border-radius: 10px;
    overflow: hidden;
  }
}
.Filter_container__3PDVc.Filter_open__2bk1Y {
  display: block;
}

.Filter_flexContainer__3Bjm1 {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 27px;
}
@media (min-width: 1440px) {
  .Filter_flexContainer__3Bjm1 {
    padding-top: 8px;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 50px;
    padding-right: 50px;
    height: 604px;
  }
}

@media (min-width: 1440px) {
  .Filter_datePicker__yMfFM {
    order: 2;
  }
}

.Filter_form__30cdg {
  width: 70%;
}
@media (min-width: 1440px) {
  .Filter_form__30cdg {
    height: 100%;
    order: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
  }
}

.Filter_section__1Tr-L {
  margin-top: 22px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}
@media (min-width: 1440px) {
  .Filter_section__1Tr-L {
    max-width: 200px;
  }
}

.Filter_sectionTitle__q2yQ7 {
  padding-bottom: 7px;
  border-bottom: 1px solid #D9D9D9;
}

.Filter_sectionContent__vt1Er {
  padding-top: 13px;
}

.Filter_buttonsContainer__Psvap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #FFFFFF;
  height: 60px;
  border-top: 1px solid #D9D9D9;
  z-index: 10;
}
@media (min-width: 1440px) {
  .Filter_buttonsContainer__Psvap {
    position: relative;
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top: none;
    padding-right: 50px;
  }
}

.Filter_button__24TU0 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #E53935;
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .Filter_button__24TU0 {
    width: 100px;
    margin-left: 22px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectCard_card__2Kh52 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  height: 106px !important;
  width: 100%;
}
@media (min-width: 768px) {
  .ObjectCard_card__2Kh52.ObjectCard_responsive__nIBgH {
    width: calc((100% - 16px) / 2);
  }
  .ObjectCard_card__2Kh52.ObjectCard_responsive__nIBgH:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .ObjectCard_card__2Kh52.ObjectCard_responsive__nIBgH {
    width: calc((100% - 26px) / 2);
  }
  .ObjectCard_card__2Kh52.ObjectCard_responsive__nIBgH:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .ObjectCard_card__2Kh52 {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px !important;
  }
}

.ObjectCard_headOnline__jt6Ly, .ObjectCard_headOffline__3_9r3 {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.ObjectCard_headOnline__jt6Ly {
  background-color: #7A7A7A;
  color: #FFFFFF;
}

.ObjectCard_headOffline__3_9r3 {
  background-color: #EEEEEE;
  color: #989898;
}
.ObjectCard_headOffline__3_9r3 .ObjectCard_icon__3uowv {
  background-image: url(/static/media/object-icon-inactive.8a7f039a.svg);
}

.ObjectCard_icon__3uowv {
  background-image: url(/static/media/object-icon-active.fb09f8b7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 8px;
}

.ObjectCard_onlineIcon__rcJ3v {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #02E30B;
  margin-right: 6px;
  margin-left: 6px;
}

.ObjectCard_contentContainer__3wEJD {
  padding: 5px 10px 13px;
}
@media (min-width: 1440px) {
  .ObjectCard_contentContainer__3wEJD {
    padding-top: 10px;
  }
}

.ObjectCard_title__GFGNj {
  color: #000000;
}
@media (min-width: 1440px) {
  .ObjectCard_title__GFGNj {
    font-size: 16px;
  }
}

.ObjectCard_infoBlock__19l2G {
  margin-top: 10px;
  display: flex;
}

.ObjectCard_infoBlockPair__8WEjr {
  display: flex;
  justify-content: space-between;
}

.ObjectCard_infoTitle__2rnN7 {
  color: #BBBBBB;
  margin-right: 6px;
}

.ObjectCard_infoValue__3-RbT {
  color: #7D7D7D;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastListScene_container__2gMY0 {
  padding-top: 20px;
  padding-top: 35px;
}
@media (min-width: 1440px) {
  .BroadcastListScene_container__2gMY0 {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .BroadcastListScene_container__2gMY0 {
    padding-top: 28px;
  }
}

.BroadcastListScene_spinnerContainer__1-nid {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  .BroadcastListScene_listContainer__1jjh- {
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .BroadcastListScene_searchContainer__b6oCs {
    border-bottom: 1px solid #D7D7D7;
  }
}

@media (min-width: 768px) {
  .BroadcastListScene_searchControls__1NpuL {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastListScene_searchInput__3khhB {
    width: 40%;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .BroadcastListScene_searchInput__3khhB {
    width: 40%;
  }
}

.BroadcastListScene_listOptions__1Nuo2 {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (max-width: calc(768px - 1px)) {
  .BroadcastListScene_listOptions__1Nuo2 {
    display: none;
  }
}
@media (min-width: 1440px) {
  .BroadcastListScene_listOptions__1Nuo2 {
    padding-left: 0;
    padding-right: 0;
  }
}

.BroadcastListScene_checkbox__1JFmn {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-right: 16px;
  margin-bottom: 0;
}
@media (min-width: 1440px) {
  .BroadcastListScene_checkbox__1JFmn {
    font-size: 14px;
    line-height: 16px;
    margin-right: 24px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastActions_container__Jt7Y0 {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 21px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastActions_container__Jt7Y0 {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .BroadcastActions_container__Jt7Y0 {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 55%;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastActions_container__Jt7Y0 {
    flex-grow: 2;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .BroadcastActions_container__Jt7Y0 {
    flex-grow: 2;
  }
}
@media (min-width: 1440px) {
  .BroadcastActions_container__Jt7Y0 {
    justify-content: space-between;
    padding-left: 32px;
  }
}

.BroadcastActions_createButton__1IBRF {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: calc(100% - 19px - 18px);
  height: 44px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastActions_createButton__1IBRF {
    background-position: left 12px center;
    padding-left: 22px;
  }
}
@media (min-width: 1440px) {
  .BroadcastActions_createButton__1IBRF {
    order: 3;
    width: 214px;
    background-size: 20px;
    font-size: 14px;
    padding-left: 56px;
    justify-content: flex-start;
  }
}

.BroadcastActions_sortIcon__3PTAV {
  cursor: pointer;
  background-image: url(/static/media/sort-by-icon.4667cad1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 18px;
  height: 44px;
  width: 18px;
  margin-left: 19px;
  position: relative;
}
@media (min-width: 768px) {
  .BroadcastActions_sortIcon__3PTAV {
    display: none;
  }
}

.BroadcastActions_popup__1FYXh {
  top: 100%;
}

.BroadcastActions_loadStatusContainer__4_7Lj {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 3;
  width: 100%;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .BroadcastActions_loadStatusContainer__4_7Lj {
    margin-top: 0;
    white-space: nowrap;
    margin-left: 16px;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastActions_loadStatusContainer__4_7Lj {
    width: auto;
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  .BroadcastActions_loadStatusContainer__4_7Lj {
    margin-left: 24px;
  }
}
@media (min-width: 1440px) {
  .BroadcastActions_loadStatusContainer__4_7Lj {
    order: 1;
    width: auto;
    margin-top: 0;
  }
}

.BroadcastActions_loadStatusValue__39K8J {
  display: inline-block;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .BroadcastActions_loadStatusValue__39K8J {
    font-size: 16px;
    margin-left: 8px;
  }
}
@media (min-width: 1440px) {
  .BroadcastActions_loadStatusValue__39K8J {
    color: #E53935;
    margin-left: 24px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanCard_card__14HNG {
  background-color: #FFFBFB;
  height: 108px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
}
@media (min-width: 768px) {
  .MediaplanCard_card__14HNG {
    width: calc((100% - 16px) / 2);
  }
  .MediaplanCard_card__14HNG:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .MediaplanCard_card__14HNG {
    width: calc((100% - 26px) / 2);
  }
  .MediaplanCard_card__14HNG:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .MediaplanCard_card__14HNG {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px !important;
  }
}

.MediaplanCard_head__1J1yT {
  background-color: #F16360;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 8px;
  text-transform: uppercase;
}

.MediaplanCard_headInfo__24WN5 {
  display: flex;
  align-items: center;
}

.MediaplanCard_icon__2gc7J {
  background-image: url(/static/media/mediaplan-card-icon.b4786252.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 21px;
  width: 19px;
  margin-right: 13px;
}

.MediaplanCard_headCount__3_ZsE {
  border: 1px solid #989898;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  color: #989898;
}

.MediaplanCard_contentContainer__1bzlP {
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1440px) {
  .MediaplanCard_contentContainer__1bzlP {
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.MediaplanCard_title__-v1Xy {
  color: #000000;
}
@media (min-width: 1440px) {
  .MediaplanCard_title__-v1Xy {
    font-size: 16px;
  }
}

.MediaplanCard_infoBlock__i6_TT {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 75%;
}
@media (min-width: 1440px) {
  .MediaplanCard_infoBlock__i6_TT {
    max-width: 40%;
    line-height: 16px;
  }
}

.MediaplanCard_infoTitle__1MlxT {
  color: rgba(0, 0, 0, 0.25);
}

.MediaplanCard_infoValue__2uVrJ {
  color: rgba(0, 0, 0, 0.5);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PriorityListScene_container__2Nhby {
  padding-top: 20px;
  padding-top: 25px;
}
@media (min-width: 1440px) {
  .PriorityListScene_container__2Nhby {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}
@media (min-width: 1440px) {
  .PriorityListScene_container__2Nhby {
    padding-top: 28px;
  }
}

.PriorityListScene_controlsContainer__3OyLw {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1440px) {
  .PriorityListScene_controlsContainer__3OyLw {
    border-bottom: 1px solid #D7D7D7;
    flex-wrap: nowrap;
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
  }
}

.PriorityListScene_createButton__3Y-ck {
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: 100%;
  height: 44px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 36px;
  font-size: 14px;
}
@media (min-width: 1440px) {
  .PriorityListScene_createButton__3Y-ck {
    width: 303px;
    padding-left: 56px;
    justify-content: flex-start;
    order: 2;
  }
}

.PriorityListScene_countContainer__hkFdO {
  margin-top: 22px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 1440px) {
  .PriorityListScene_countContainer__hkFdO {
    order: 1;
    width: auto;
    margin-top: 0;
  }
}

@media (min-width: 1440px) {
  .PriorityListScene_countValue__10kLD {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
    color: #E53935;
  }
}

.PriorityListScene_listContainer__2FjdU {
  padding-top: 20px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PriorityAdCard_card__3o7Zg {
  height: 111px;
  width: 100%;
  background-color: #FFFEEC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow: initial;
}
@media (min-width: 768px) {
  .PriorityAdCard_card__3o7Zg {
    width: calc((100% - 16px) / 2);
  }
  .PriorityAdCard_card__3o7Zg:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .PriorityAdCard_card__3o7Zg {
    width: calc((100% - 26px) / 2);
  }
  .PriorityAdCard_card__3o7Zg:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .PriorityAdCard_card__3o7Zg {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px;
  }
}
.PriorityAdCard_card__3o7Zg:first-child .PriorityAdCard_starIcon__2zdtf {
  display: block;
}

.PriorityAdCard_head__1DpUl {
  position: relative;
  background-color: #FFF970;
  color: #989898;
  padding-right: 14px;
  padding-left: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PriorityAdCard_headInfo__29Miq {
  display: flex;
  align-items: center;
}

.PriorityAdCard_icon__3X4gn, .PriorityAdCard_starIcon__2zdtf {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 21px;
  width: 19px;
  margin-right: 13px;
}

.PriorityAdCard_icon__3X4gn {
  background-image: url(/static/media/priority-ad-icon.9fd28518.svg);
}

.PriorityAdCard_starIcon__2zdtf {
  display: none;
  background-image: url(/static/media/priority-ad-star.3b4647a0.svg);
}

.PriorityAdCard_menuButton__3evC5 {
  position: relative;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  cursor: pointer;
}

.PriorityAdCard_cardPopup__1AWJN {
  right: -16px;
  top: 30px;
}
@media (min-width: 1440px) {
  .PriorityAdCard_cardPopup__1AWJN {
    right: -3px;
    top: 28px !important;
  }
}

.PriorityAdCard_contentContainer__2W9HR {
  padding-top: 10px;
  padding-right: 11px;
  padding-left: 17px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .PriorityAdCard_contentContainer__2W9HR {
    padding-left: 18px;
    padding-right: 21px;
  }
}

.PriorityAdCard_textContainer__26rgz {
  max-width: 70%;
}

.PriorityAdCard_title__2jIxN {
  color: #000000;
}
@media (min-width: 1440px) {
  .PriorityAdCard_title__2jIxN {
    font-size: 16px;
  }
}

.PriorityAdCard_infoBlock__1WB65 {
  margin-top: 7px;
  color: #989898;
  display: flex;
}
@media (min-width: 1440px) {
  .PriorityAdCard_infoBlock__1WB65 {
    margin-top: 16px;
  }
}

.PriorityAdCard_infoValue__1AGX5 {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.PriorityAdCard_playButton__1E2pQ {
  height: 44px;
  width: 44px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background-color: #FFFFFF;
  background-image: url(/static/media/ad-play-icon.9f69fe07.svg);
  background-position: 18px center;
  background-repeat: no-repeat;
  background-size: 14px 20px;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .PriorityAdCard_playButton__1E2pQ {
    height: 55px;
    width: 55px;
    margin-top: 5px;
    background-position: center;
    background-size: 16px 25px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectScene_container__YN_uQ {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .ObjectScene_container__YN_uQ {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.ObjectScene_spinnerContainer__22mmB {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ObjectScene_playerContainer__mIeMd {
  padding-left: 32px;
  padding-right: 32px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_playerContainer__mIeMd {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1024px) {
  .ObjectScene_playerContainer__mIeMd {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_playerContainer__mIeMd {
    padding: 0 0 40px;
  }
}

.ObjectScene_title__1Ei2h {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .ObjectScene_title__1Ei2h {
    margin-right: 48px;
  }
}
@media (min-width: 1024px) {
  .ObjectScene_title__1Ei2h {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    line-height: 23px;
  }
}

.ObjectScene_address__2Y0r- {
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  color: rgba(33, 33, 33, 0.8);
}
@media (min-width: 1024px) {
  .ObjectScene_address__2Y0r- {
    font-size: 12px;
    margin-left: 6px;
  }
}

.ObjectScene_muteContainer__2yhuZ {
  -webkit-user-select: none;
          user-select: none;
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .ObjectScene_muteContainer__2yhuZ {
    flex-grow: 1;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_muteContainer__2yhuZ {
    border-radius: 20px;
    background-color: #C4C4C4;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    height: 40px;
    width: 205px;
    cursor: pointer;
    margin-right: 160px;
  }
  .ObjectScene_muteContainer__2yhuZ.ObjectScene_muted__2bG13 {
    background: linear-gradient(77.58deg, #FF1744 0%, #FF5252 100%);
  }
}
.ObjectScene_muteContainer__2yhuZ .ObjectScene_muteText__3fxg0 {
  display: none;
}
@media (min-width: 1440px) {
  .ObjectScene_muteContainer__2yhuZ .ObjectScene_muteText__3fxg0 {
    display: block;
  }
}

@media (min-width: 768px) {
  .ObjectScene_soundButtonContainer__3NO3Q {
    padding: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_soundButtonContainer__3NO3Q {
    margin-right: 20px;
    background-color: #FFFFFF;
  }
}

.ObjectScene_soundButton__3S77v {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .ObjectScene_soundButton__3S77v {
    width: 100%;
    height: 100%;
  }
}

.ObjectScene_priorityButton__1kwUA,
.ObjectScene_backgroundButton__39kH7 {
  display: none;
}
@media (min-width: 1024px) {
  .ObjectScene_priorityButton__1kwUA,
.ObjectScene_backgroundButton__39kH7 {
    display: flex;
    background: linear-gradient(81.57deg, #FF1744 0%, #FF5252 99.68%);
    border-radius: 10px;
    height: 46px;
    padding: 15px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .ObjectScene_priorityButton__1kwUA:hover,
.ObjectScene_backgroundButton__39kH7:hover {
    color: #FFFFFF;
  }
}

.ObjectScene_priorityButton__1kwUA {
  margin-right: 10px;
}

.ObjectScene_priorityIcon__JOMqC {
  background-image: url(/static/media/lightning-icon-white.c9322b33.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  margin-right: 15px;
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_headContent__3rK1O {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #D7D7D7;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_headContent__3rK1O {
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
  }
}

.ObjectScene_flexCenter__jKil8,
.ObjectScene_flexContainer__3ixXy {
  display: flex;
  align-items: center;
}

.ObjectScene_flexContainer__3ixXy {
  justify-content: space-between;
}
@media (min-width: 1024px) {
  .ObjectScene_flexContainer__3ixXy {
    order: 2;
  }
}

.ObjectScene_info__2D3hE {
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #989898;
  margin-top: 16px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_info__2D3hE {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 1024px) {
  .ObjectScene_info__2D3hE {
    order: 1;
    width: 20%;
    margin-bottom: 0;
  }
}

.ObjectScene_objectIcon__1ziK1 {
  background-image: url(/static/media/object-icon-inactive.8a7f039a.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 6px;
}

.ObjectScene_statusContainer__2OCee {
  display: flex;
  align-items: baseline;
}

.ObjectScene_onlineStatus__3hGNb {
  background-color: #02E30B;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-left: 6px;
  margin-right: 6px;
}

@media (min-width: 1024px) {
  .ObjectScene_pageContent__m3qq5 {
    display: flex;
    order: 3;
    padding-left: 48px;
    padding-top: 24px;
  }
}

.ObjectScene_favoriteAdContainer__3B7zD {
  display: block;
}
@media (max-width: calc(768px - 1px)) {
  .ObjectScene_favoriteAdContainer__3B7zD {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .ObjectScene_favoriteAdContainer__3B7zD {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_favoriteAdContainer__3B7zD {
    margin-bottom: 42px;
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (min-width: 1024px) and (max-width: calc(1440px - 1px)) {
  .ObjectScene_favoriteAdContainer__3B7zD {
    margin-left: 72px;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_favoriteAdContainer__3B7zD {
    margin-left: 72px;
    margin-top: 52px;
  }
}

.ObjectScene_favoriteAdTitle__3HIAA {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #6C6C6C;
  padding-left: 42px;
  margin-bottom: 13px;
  width: 100%;
}
@media (min-width: 1024px) {
  .ObjectScene_favoriteAdTitle__3HIAA {
    padding-left: 0;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_favoriteAdTitle__3HIAA {
    padding-left: 42px;
  }
}

.ObjectScene_favoriteAd__2FZJK {
  min-width: 205px;
  width: 75%;
  cursor: pointer;
  border-radius: 30px;
  height: 60px;
  padding-left: 25px;
  padding-right: 15px;
  align-items: center;
  color: #FFFFFF;
  background: linear-gradient(239.07deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  box-shadow: 0 4px 15px rgba(255, 52, 75, 0.4);
  display: inline-flex;
  -webkit-user-select: none;
          user-select: none;
  font-size: 12px;
  line-height: 14px;
}
@media (min-width: 768px) {
  .ObjectScene_favoriteAd__2FZJK {
    width: 70%;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_favoriteAd__2FZJK {
    width: 80%;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_favoriteAd__2FZJK {
    width: 537px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    padding-left: 42px;
    padding-right: 22px;
  }
}

.ObjectScene_favoriteAdIcon__21sLS {
  background-image: url(/static/media/player-play-icon-white.7524f3a4.svg);
  background-position: center;
  background-repeat: no-repeat;
  height: 23px;
  width: 29px;
}
@media (min-width: 768px) {
  .ObjectScene_favoriteAdIcon__21sLS {
    height: 28px;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_favoriteAdIcon__21sLS {
    height: 30px;
    width: 23px;
  }
}

.ObjectScene_microphoneIconContainer__17SWp {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 59px;
  width: 59px;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .ObjectScene_microphoneIconContainer__17SWp {
    margin-left: 25px;
  }
}
@media (min-width: 1440px) {
  .ObjectScene_microphoneIconContainer__17SWp {
    vertical-align: top;
  }
}

.ObjectScene_microphoneIcon__elsEN {
  background-image: url(/static/media/player-microphone-icon.a77ea080.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 33px;
  width: 23px;
}
.ObjectScene_microphoneIcon__elsEN.ObjectScene_active__1d7O8 {
  background-image: url(/static/media/microphone-icon-red.f98873cc.svg);
}

.ObjectScene_tabsContainer__1CFXA {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 1440px) {
  .ObjectScene_tabsContainer__1CFXA {
    padding-left: 56px;
    padding-top: 24px;
  }
}

@media (max-width: calc(768px - 1px)) {
  .ObjectScene_hideMobile__3iujs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .ObjectScene_hideTablet__14mC3 {
    display: none;
  }
}

.ObjectScene_maintenanceButton__10FJu {
  display: none !important;
}
@media (min-width: 1440px) {
  .ObjectScene_maintenanceButton__10FJu {
    display: flex !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 2px solid #FF1744 !important;
    color: #FF1744 !important;
    background-image: url(/static/media/object-maintenance-icon-red.5ee506b1.svg);
    background-size: 30px 33px;
    background-position: 8px center;
    background-repeat: no-repeat;
    width: 270px !important;
    height: 46px !important;
    margin-bottom: 18px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectScenePlayer_container__31-2p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 28px;
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_container__31-2p {
    width: 300px;
  }
}

.ObjectScenePlayer_visualiser__2Zgnv {
  width: calc(100vw - 150px);
  height: calc(100vw - 150px);
  max-width: 270px;
  max-height: 270px;
  border-radius: 50%;
  background-color: rgba(108, 108, 108, 0.07);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_visualiser__2Zgnv {
    order: 1;
    width: 270px;
    height: 270px;
  }
}
.ObjectScenePlayer_visualiser__2Zgnv .ObjectScenePlayer_innerCircle__1DWXb {
  width: 100%;
  height: 100%;
  padding: 25px;
  background-color: rgba(255, 52, 75, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_visualiser__2Zgnv .ObjectScenePlayer_innerCircle__1DWXb {
    padding: 32px;
  }
}
.ObjectScenePlayer_visualiser__2Zgnv .ObjectScenePlayer_innerCircle__1DWXb:nth-child(even) {
  animation-direction: reverse;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.ObjectScenePlayer_visualiser__2Zgnv .ObjectScenePlayer_centerCircle__3SPBJ {
  background-color: rgb(255, 52, 75);
  -webkit-animation: none;
          animation: none;
}

.ObjectScenePlayer_volumeContainer___95Yl {
  position: absolute;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  justify-content: center;
}

.ObjectScenePlayer_visualiserAnimated__2Vn2f .ObjectScenePlayer_innerCircle__1DWXb {
  -webkit-animation: ObjectScenePlayer_visualizeSound__P5m4z 1s;
          animation: ObjectScenePlayer_visualizeSound__P5m4z 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

.ObjectScenePlayer_visualiserMuted__2rrSe .ObjectScenePlayer_innerCircle__1DWXb {
  background-color: rgba(108, 108, 108, 0.07);
}
.ObjectScenePlayer_visualiserMuted__2rrSe .ObjectScenePlayer_centerCircle__3SPBJ {
  background-color: #6C6C6C;
}

.ObjectScenePlayer_titleContainer___zYw9 {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-top: 28px;
}
@media (min-width: 1024px) {
  .ObjectScenePlayer_titleContainer___zYw9 {
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_titleContainer___zYw9 {
    order: 3;
    margin-top: 21px;
  }
}

.ObjectScenePlayer_settingsIcon__2CKpR, .ObjectScenePlayer_lightningIcon__108n8, .ObjectScenePlayer_playButton__1bmB9 {
  cursor: pointer;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.ObjectScenePlayer_settingsIcon__2CKpR {
  background-image: url(/static/media/player-settings-icon.c4453e04.svg);
  height: 18px;
  width: 20px;
}
@media (min-width: 1024px) {
  .ObjectScenePlayer_settingsIcon__2CKpR {
    display: none;
  }
}

.ObjectScenePlayer_lightningIcon__108n8 {
  background-image: url(/static/media/player-lightning-icon.74aaa014.svg);
  height: 22px;
  width: 13px;
}
@media (min-width: 1024px) {
  .ObjectScenePlayer_lightningIcon__108n8 {
    display: none;
  }
}

.ObjectScenePlayer_backgroundIcon__1871w {
  background-image: url("/static/media/speaker-icon 1.a0abed87.svg");
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
  margin-right: 5px;
}
@media (min-width: 1024px) {
  .ObjectScenePlayer_backgroundIcon__1871w {
    display: none;
  }
}

.ObjectScenePlayer_playerControls__3LMdb {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33px;
}
@media (max-width: calc(1024px - 1px)) {
  .ObjectScenePlayer_playerControls__3LMdb {
    margin-top: 14px;
  }
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_playerControls__3LMdb {
    order: 2;
  }
}

.ObjectScenePlayer_playButton__1bmB9 {
  background-image: url(/static/media/player-pause-icon.75fd3a83.svg);
  height: 30px;
  width: 22px;
}
.ObjectScenePlayer_playButton__1bmB9.ObjectScenePlayer_paused__xMrF1 {
  background-image: url(/static/media/player-play-icon.c64a9ea6.svg);
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_playButton__1bmB9 {
    background-image: url(/static/media/player-pause-icon-white.5b992d25.svg), linear-gradient(44.51deg, #FF1744 0%, #FF5252 100%);
    border-radius: 50%;
    background-size: 35px 24px, cover;
    background-position: center, center;
    height: 58px;
    width: 58px;
  }
  .ObjectScenePlayer_playButton__1bmB9.ObjectScenePlayer_paused__xMrF1 {
    background-image: url(/static/media/player-play-icon-white.7524f3a4.svg), linear-gradient(44.51deg, #FF1744 0%, #FF5252 100%);
    background-position: left 14px center, center;
  }
}

.ObjectScenePlayer_plusButton__W3ad4, .ObjectScenePlayer_minusButton__19VHw {
  color: #FF344B;
  font-size: 38px;
  font-weight: 200;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .ObjectScenePlayer_plusButton__W3ad4, .ObjectScenePlayer_minusButton__19VHw {
    width: 32px;
    height: 32px;
    background-color: #FF1744;
    color: transparent;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    -webkit-user-select: none;
            user-select: none;
  }
  .ObjectScenePlayer_plusButton__W3ad4:focus, .ObjectScenePlayer_minusButton__19VHw:focus {
    outline: none;
  }
}

@media (min-width: 1440px) {
  .ObjectScenePlayer_plusButton__W3ad4 {
    background-image: url(/static/media/player-plus-white.a3ca89b7.svg);
  }
}

@media (min-width: 1440px) {
  .ObjectScenePlayer_minusButton__19VHw {
    background-image: url(/static/media/player-minus-white.c169b93e.svg);
  }
}

@media (max-width: calc(1024px - 1px)) {
  .ObjectScenePlayer_titleText__3ohx_ {
    flex-basis: 100%;
    margin-top: 24px;
    text-align: center;
  }
}

.ObjectScenePlayer_scrollingTitle__wemKt {
  white-space: nowrap;
  overflow: hidden;
}
.ObjectScenePlayer_scrollingTitle__wemKt span {
  display: inline-block;
  padding-left: 100%;
  -webkit-animation: ObjectScenePlayer_scroll__zDOA9 5s infinite linear;
          animation: ObjectScenePlayer_scroll__zDOA9 5s infinite linear;
}

.ObjectScenePlayer_microphoneIcon__3zQUu {
  background-image: url(/static/media/microphone-icon-red.f98873cc.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 130px;
  width: 90px;
  -webkit-animation: ObjectScenePlayer_shake__GYEmI 0.8s linear infinite;
          animation: ObjectScenePlayer_shake__GYEmI 0.8s linear infinite;
}

@-webkit-keyframes ObjectScenePlayer_scroll__zDOA9 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes ObjectScenePlayer_scroll__zDOA9 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
@-webkit-keyframes ObjectScenePlayer_visualizeSound__P5m4z {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  50% {
    -webkit-transform: scale(0.94);
            transform: scale(0.94);
  }
  60% {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
  75% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
@keyframes ObjectScenePlayer_visualizeSound__P5m4z {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  50% {
    -webkit-transform: scale(0.94);
            transform: scale(0.94);
  }
  60% {
    -webkit-transform: scale(0.92);
            transform: scale(0.92);
  }
  75% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
@-webkit-keyframes ObjectScenePlayer_shake__GYEmI {
  0% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, 0px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-1px, 0px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
}
@keyframes ObjectScenePlayer_shake__GYEmI {
  0% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  10% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  20% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  30% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  40% {
    -webkit-transform: translate(1px, 0px) rotate(1deg);
  }
  50% {
    -webkit-transform: translate(-1px, 0px) rotate(-1deg);
  }
  60% {
    -webkit-transform: translate(-1px, 0px) rotate(0deg);
  }
  70% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
  80% {
    -webkit-transform: translate(-1px, 0px) rotate(1deg);
  }
  90% {
    -webkit-transform: translate(1px, 0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, 0px) rotate(-1deg);
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Tabs_tabsHeadContainer__24KkB {
  display: flex;
  color: #BBBBBB;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .Tabs_tabsHeadContainer__24KkB {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 1440px) {
  .Tabs_tabsHeadContainer__24KkB {
    font-size: 14px;
    line-height: 16px;
  }
}

.Tabs_tabTitle__2tuNX {
  margin-left: 12px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.Tabs_tabTitle__2tuNX.Tabs_active__2hPHG {
  color: #000000;
}
@media (min-width: 1440px) {
  .Tabs_tabTitle__2tuNX.Tabs_active__2hPHG {
    padding-bottom: 5px;
    border-bottom: 2px solid #FF1744;
  }
}
@media (min-width: 1440px) {
  .Tabs_tabTitle__2tuNX {
    padding-bottom: 7px;
    margin-left: 40px;
  }
  .Tabs_tabTitle__2tuNX:first-of-type {
    margin-left: 0;
  }
}
.Tabs_tabTitle__2tuNX:first-of-type {
  margin-left: 0;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Tab_container__2LOy7 {
  padding-top: 24px;
}
@media (min-width: 768px) and (max-width: calc(1024px - 1px)) {
  .Tab_container__2LOy7 {
    padding-top: 31px;
    padding-left: 60px;
    padding-right: 60px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectSceneInfo_container__3TkKT {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .ObjectSceneInfo_container__3TkKT {
    justify-content: flex-start;
  }
}

.ObjectSceneInfo_infoBlock__14tXR {
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.ObjectSceneInfo_infoBlock__14tXR.ObjectSceneInfo_half__2JN7w {
  width: calc((100% - 16px) / 2);
}
@media (min-width: 1440px) {
  .ObjectSceneInfo_infoBlock__14tXR.ObjectSceneInfo_half__2JN7w {
    width: 25%;
  }
}

.ObjectSceneInfo_header__1s1xt {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  flex-direction: column;
}

.ObjectSceneInfo_name__2I-c_ {
  display: flex;
  flex-direction: row;
}

.ObjectSceneInfo_tariff__3xMYK {
  margin-left: 14px;
  font-weight: bold;
  font-size: 18px;
  line-height: 16px;
}

.ObjectSceneInfo_tariffBlock__3sAtb {
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.ObjectSceneInfo_value__2ii1D {
  color: #212121;
  margin-left: 40px;
}

.ObjectSceneInfo_tariffBlockImage__2OO7P {
  width: 23px;
  height: 18px;
  margin-top: 10px;
  margin-right: 14px;
}

.ObjectSceneInfo_documentLoad__2WTo8 {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.ObjectSceneInfo_documentBlock__1Ds2Z {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: calc(1024px - 1px)) {
  .ObjectSceneInfo_documentBlock__1Ds2Z {
    flex-direction: column;
    justify-content: center;
  }
}

.ObjectSceneInfo_payment__22zE9 {
  width: 369.34px;
  margin-top: 17px;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: calc(1024px - 1px)) {
  .ObjectSceneInfo_payment__22zE9 {
    width: 100%;
  }
}

.ObjectSceneInfo_sectionTitle__PIh_O {
  padding-top: 7px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-size: 13px;
  color: #413333;
  margin-bottom: 16px;
  width: 100%;
}

.ObjectSceneInfo_rateIcon__2qbrF, .ObjectSceneInfo_maintenanceIcon__kcTAk {
  height: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1440px) {
  .ObjectSceneInfo_rateIcon__2qbrF, .ObjectSceneInfo_maintenanceIcon__kcTAk {
    display: none;
  }
}

.ObjectSceneInfo_rateIcon__2qbrF {
  background-image: url(/static/media/rate-icon.6ba4ea8f.svg);
}

.ObjectSceneInfo_maintenanceIcon__kcTAk {
  background-image: url(/static/media/maintenance-icon.fb3af2d7.svg);
}

.ObjectSceneInfo_maintenanceLink__25wAe {
  display: block;
  width: 100%;
}
@media (min-width: 1440px) {
  .ObjectSceneInfo_maintenanceLink__25wAe {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectSceneDropdown_button__29-ie {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 11px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  background-color: #EEEEEE;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  -webkit-user-select: none;
          user-select: none;
}
@media (min-width: 1440px) {
  .ObjectSceneDropdown_button__29-ie {
    background-color: transparent;
    color: #000000;
    flex-direction: column;
    margin-top: 40px;
  }
}

.ObjectSceneDropdown_dropdownIcon__2Ttjj {
  background-image: url(/static/media/dropdown-icon-black.e44e7bd5.svg);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 10px;
}
.ObjectSceneDropdown_dropdownIcon__2Ttjj.ObjectSceneDropdown_open__1Gmny {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
@media (min-width: 1440px) {
  .ObjectSceneDropdown_dropdownIcon__2Ttjj {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .ObjectSceneDropdown_dropdownIcon__2Ttjj.ObjectSceneDropdown_open__1Gmny {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}
.Button_button__2lUo3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  padding-right: 12px;
  padding-left: 12px;
  color: #FFFFFF;
  border: none;
  background-color: #E53935;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  -webkit-user-select: none;
          user-select: none;
}
.Button_button__2lUo3:not(:first-of-type) {
  margin-left: 12px;
}
.Button_button__2lUo3:focus {
  outline: none;
}
.Button_button__2lUo3:active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.24), 0px 0px 1px rgba(0, 0, 0, 0.12);
}
.Button_button__2lUo3:hover {
  color: #FFFFFF;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastManagementScene_container__sUCQ2 {
  padding-top: 20px;
  padding-bottom: 81px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_container__sUCQ2 {
    display: flex;
    align-items: flex-start;
    padding-top: 0;
  }
}

.BroadcastManagementScene_title__3lzps {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 26px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_title__3lzps {
    display: none;
  }
}

@media (min-width: 1024px) {
  .BroadcastManagementScene_breadcrumbs__X8WsN {
    display: none;
  }
}

.BroadcastManagementScene_broadcastManagement__1rGZw {
  padding-left: 22px;
  padding-right: 22px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_broadcastManagement__1rGZw {
    width: 50%;
  }
}

.BroadcastManagementScene_objectTitle__27FYh {
  margin: 20px 0;
  padding-left: calc((100% - 245px) / 2);
  padding-right: calc((100% - 245px) / 2);
  font-weight: 300;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_objectTitle__27FYh {
    display: none;
  }
}

.BroadcastManagementScene_buttonsContainer__3eL0_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  padding-left: 24px;
  padding-right: 24px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_buttonsContainer__3eL0_ {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

.BroadcastManagementScene_button__1q70R {
  cursor: pointer;
  height: 59px;
  width: 78px;
  background: linear-gradient(265.03deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  box-shadow: 0 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_button__1q70R {
    width: 160px;
    cursor: pointer;
    justify-content: flex-start;
    padding-left: 16px;
    padding-right: 16px;
  }
  .BroadcastManagementScene_button__1q70R:not(:first-child) {
    margin-left: 26px;
  }
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_button__1q70R {
    width: 260px;
  }
}
.BroadcastManagementScene_button__1q70R .BroadcastManagementScene_buttonText__3T2BB {
  display: none;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_button__1q70R .BroadcastManagementScene_buttonText__3T2BB {
    display: block;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
    margin-left: 12px;
    color: #FFFFFF;
  }
}

.BroadcastManagementScene_timeIcon__2oh0_, .BroadcastManagementScene_pauseIcon__1_Nq7, .BroadcastManagementScene_calendarIcon__2LTIb {
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  height: 26px;
  width: 26px;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_timeIcon__2oh0_, .BroadcastManagementScene_pauseIcon__1_Nq7, .BroadcastManagementScene_calendarIcon__2LTIb {
    height: 35px;
    width: 35px;
  }
}

.BroadcastManagementScene_timeIcon__2oh0_ {
  background-image: url(/static/media/broadcast-time-icon.1c53d213.svg);
}

.BroadcastManagementScene_pauseIcon__1_Nq7 {
  background-image: url(/static/media/broadcast-pause-icon.5b992d25.svg);
}

.BroadcastManagementScene_calendarIcon__2LTIb {
  background-image: url(/static/media/broadcast-calendar-icon.6904041f.svg);
}

@media (min-width: 1024px) {
  .BroadcastManagementScene_hideDesktop__SiVZc {
    display: none;
  }
}

.BroadcastManagementScene_hideMobile__rPm5w {
  display: none;
}
@media (min-width: 1024px) {
  .BroadcastManagementScene_hideMobile__rPm5w {
    display: block;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastHoursScene_container__2_6FO {
  padding-top: 20px;
}

.BroadcastHoursScene_pageTitle__3I1nc {
  border-bottom: none;
}
@media (min-width: 1024px) {
  .BroadcastHoursScene_pageTitle__3I1nc {
    display: none;
  }
}

.BroadcastHoursScene_pageContainer__M8Z8G {
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .BroadcastHoursScene_pageContainer__M8Z8G {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.BroadcastHoursScene_currentValueContainer__186E2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 14px;
  margin-top: 30px;
}
@media (min-width: 1024px) {
  .BroadcastHoursScene_currentValueContainer__186E2 {
    width: 100%;
  }
}

.BroadcastHoursScene_currentValue__3cZxB {
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  margin-top: 16px;
}

.BroadcastHoursScene_pickerContainer__1-C_z {
  margin-top: 24px;
}
@media (min-width: 1024px) {
  .BroadcastHoursScene_pickerContainer__1-C_z {
    margin-left: 28px;
  }
}

.BroadcastHoursScene_timePicker__29TXn {
  margin-top: 24px;
}

.BroadcastHoursScene_contentLabel__1ixlN {
  font-size: 15px;
  line-height: 15px;
  color: #000000;
}
.BroadcastHoursScene_contentLabel__1ixlN .BroadcastHoursScene_selectWrapper__i4tll {
  margin-top: 0;
  display: inline-block;
}
.BroadcastHoursScene_contentLabel__1ixlN .BroadcastHoursScene_selectWrapper__i4tll select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  padding-right: 24px;
  width: 100%;
}

.BroadcastHoursScene_buttonContainer__13bfh {
  width: 180px;
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
@media (min-width: 1024px) {
  .BroadcastHoursScene_buttonContainer__13bfh {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .BroadcastHoursScene_submitButton__3XcLH {
    background: linear-gradient(79.8deg, #FF1744 0%, #FF5252 100.15%);
    width: 160px !important;
  }
}

@media (min-width: 1024px) {
  .BroadcastHoursScene_hideDesktop__3bBhI {
    display: none !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordStatus_container__1Y7qX {
  display: inline-block;
  text-transform: uppercase;
  padding-left: 6px;
}
@media (min-width: 1440px) {
  .RecordStatus_container__1Y7qX {
    padding-left: 8px;
  }
}

.RecordStatus_status__2jxZ- {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: inline-block;
  background-color: #989898;
  margin-right: 5px;
}
@media (min-width: 1440px) {
  .RecordStatus_status__2jxZ- {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
}

.RecordStatus_statusOnline__2loQ0 {
  background-color: #02E30B;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ItemListTitle_container__24NaU {
  margin-top: 31px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #6C6C6C;
}
@media (min-width: 1440px) {
  .ItemListTitle_container__24NaU {
    padding-bottom: 18px;
  }
}

.ItemListTitle_icon__ONuk9 {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 12px;
}
@media (min-width: 1440px) {
  .ItemListTitle_icon__ONuk9 {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.ItemListTitle_info__1hbLd {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
  margin-bottom: 5px;
}
@media (min-width: 1440px) {
  .ItemListTitle_info__1hbLd {
    margin-bottom: 12px;
  }
}

.ItemListTitle_title__1Twww {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #000000;
}
.TimePicker_container___pXry {
  width: 104px;
}

.TimePicker_rangesContainer__FT3pf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TimePickerRange_button__2q_g7 {
  background-position: center;
  background-size: 16px 8px;
  background-repeat: no-repeat;
  height: 8px;
  -webkit-user-select: none;
          user-select: none;
}

.TimePickerRange_buttonIncrease__3iGdU {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMCAwTDE2IC0yLjc5NzUzZS0wNkw4IDhMMCAwWiIgZmlsbD0iI0U1MzkzNSIvPgo8L3N2Zz4K");
}

.TimePickerRange_buttonDecrease__IFNtw {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgOEwwIDhMOCAtNi45OTM4MmUtMDdMMTYgOFoiIGZpbGw9IiNFNTM5MzUiLz4KPC9zdmc+Cg==");
}

.TimePickerRange_value__2_wsM, .TimePickerRange_inactiveValue__3Hp0I {
  font-size: 18px;
  line-height: 21px;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
          user-select: none;
}

.TimePickerRange_value__2_wsM {
  color: #E53935;
  border-top: 1px solid #E53935;
  border-bottom: 1px solid #E53935;
}

.TimePickerRange_inactiveValue__3Hp0I {
  color: #BBBBBB;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Select_container__1e2jS {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  margin-top: 30px;
}
.Select_container__1e2jS select {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  background-color: #FFFFFF;
  min-width: 84px;
  height: 34px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  text-align-last: center;
  background-image: url(/static/media/select-triangle.61282738.svg);
  background-size: 10px 5px;
  background-position: center right 12px;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .Select_container__1e2jS select {
    font-size: 13px;
  }
}
.Select_container__1e2jS select:focus {
  outline: none;
}
.Select_container__1e2jS label {
  color: #3C2E2E;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.VolumeRange_container__3Hz6O {
  margin-top: 16px;
}
.VolumeRange_container__3Hz6O:first-of-type {
  margin-top: 0;
}

.VolumeRange_title__2R8fp {
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.VolumeRange_rangeContainer__1Erxj {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
}

.VolumeRange_value__1POuD {
  font-weight: 500;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  justify-content: center;
  color: #FF1744;
  width: 80px;
}

.VolumeRange_range__BZt7s {
  display: block;
  -webkit-appearance: none;
  appearance: none;
  margin-left: 20px;
  flex-grow: 1;
  background-color: transparent;
}
.VolumeRange_range__BZt7s::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.VolumeRange_range__BZt7s::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.VolumeRange_range__BZt7s:focus {
  outline: none;
  background: none;
}
.VolumeRange_range__BZt7s::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.VolumeRange_range__BZt7s::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.VolumeRange_range__BZt7s::-ms-track {
  height: 2px;
  border: transparent;
}
.VolumeRange_range__BZt7s::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.VolumeRange_range__BZt7s::-ms-fill-lower {
  background: #E53935;
}
.VolumeRange_range__BZt7s::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}
.LoadingWrapper_spinnerContainer__SHhG0 {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectCreateScene_container__347aq {
  padding-top: 20px;
  padding-bottom: 48px;
}
@media (min-width: 1440px) {
  .ObjectCreateScene_container__347aq {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.ObjectCreateScene_objectForm__-z1GF {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .ObjectCreateScene_objectForm__-z1GF {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 1440px) {
  .ObjectCreateScene_objectForm__-z1GF {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .ObjectCreateScene_desktopCol__1hp-x {
    width: 45%;
  }
}
.ObjectCreateScene_desktopCol__1hp-x .ObjectCreateScene_inputWrapper__3SA9t label {
  color: #000000;
}

.ObjectCreateScene_input__3W4gw {
  font-size: 15px;
}

.ObjectCreateScene_selectWrapper__ayZjO select {
  width: 50%;
}

.ObjectCreateScene_checkboxWrapper__20cYf {
  display: flex;
  align-items: center;
}

.ObjectCreateScene_checkboxLabel__WdOhj {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
}

.ObjectCreateScene_computePriceContainer__3hf4n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  margin-top: 18px;
}

.ObjectCreateScene_computePriceButton__dV5FG {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.ObjectCreateScene_multiselect__1lj0f {
  margin-top: 18px;
}

.ObjectCreateScene_button__3YOuN {
  width: 200px;
  margin-top: 24px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.Input_container__FNiXU {
  padding-bottom: 13px;
}
.Input_container__FNiXU.Input_date__2zIdv {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU.Input_date__2zIdv {
    display: block;
  }
}
.Input_container__FNiXU.Input_date__2zIdv label {
  white-space: nowrap;
  margin-bottom: 0;
}
.Input_container__FNiXU.Input_date__2zIdv input {
  font-family: "Roboto", sans-serif;
  border-bottom: none;
  padding-bottom: 0;
  width: auto;
  font-size: 13px;
  line-height: 15px;
  background-color: transparent;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU.Input_date__2zIdv input {
    padding-left: 0;
    width: 100%;
    color: #6C6C6C;
  }
}
.Input_container__FNiXU.Input_time__1ohX0 {
  border-bottom: 1px solid #EDEDED;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU.Input_time__1ohX0 {
    display: block;
  }
}
.Input_container__FNiXU.Input_time__1ohX0 label {
  white-space: nowrap;
  margin-bottom: 0;
}
.Input_container__FNiXU.Input_time__1ohX0 input {
  font-family: "Roboto", sans-serif;
  border-bottom: none;
  padding-bottom: 0;
  width: auto;
  font-size: 13px;
  line-height: 15px;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU.Input_time__1ohX0 input {
    padding-left: 0;
    width: 100%;
    color: #6C6C6C;
  }
}
.Input_container__FNiXU:not(:first-of-type) {
  padding-top: 15px;
}
.Input_container__FNiXU label {
  display: block;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 15px;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU label {
    color: rgba(17, 0, 0, 0.4);
  }
}
.Input_container__FNiXU input {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border-width: 0;
  padding-bottom: 4px;
  border-bottom: 1px solid #D9D9D9;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU input:not([type=date]):not([type=time]) {
    background-color: #FFFFFF;
    border: 1px solid #BBBBBB;
    border-radius: 5px;
  }
}
.Input_container__FNiXU textarea {
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  resize: none;
  min-height: 94px;
  padding: 12px;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU textarea {
    min-height: 184px;
  }
}
.Input_container__FNiXU input, .Input_container__FNiXU textarea, .Input_container__FNiXU label {
  outline: none;
  display: block;
}
.Input_container__FNiXU input:focus, .Input_container__FNiXU textarea:focus {
  border-color: #E53935;
  caret-color: #E53935;
}
@media (min-width: 1440px) {
  .Input_container__FNiXU input, .Input_container__FNiXU textarea {
    padding: 8px 14px;
  }
}
.Input_container__FNiXU input[type=date], .Input_container__FNiXU input[type=time] {
  background-color: transparent;
}
.Input_container__FNiXU input[type=date]::-webkit-calendar-picker-indicator, .Input_container__FNiXU input[type=time]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-image: url(/static/media/calendar-icon.c44cc4bb.svg);
  background-repeat: no-repeat;
  height: 18px;
  width: 20px;
  background-size: contain;
  padding: 0;
  cursor: pointer;
}
.Input_container__FNiXU input[type=date]::-webkit-inner-spin-button, .Input_container__FNiXU input[type=time]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
.Input_container__FNiXU input[type=date]::-webkit-clear-button, .Input_container__FNiXU input[type=time]::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.NewCheckbox_checkbox__2vGJ8 {
  width: 100%;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.NewCheckbox_checkbox__2vGJ8:not(:first-of-type) {
  margin-left: 24px;
}
.NewCheckbox_checkbox__2vGJ8 input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.NewCheckbox_checkbox__2vGJ8 input[type=checkbox]:checked ~ .NewCheckbox_checkmark__1-hCV {
  background-color: #FF3B30;
  border: none;
}
.NewCheckbox_checkbox__2vGJ8 input[type=checkbox]:checked ~ .NewCheckbox_checkmark__1-hCV:after {
  display: block;
}
.NewCheckbox_checkbox__2vGJ8 .NewCheckbox_checkmark__1-hCV {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #FFFFFF;
  border: 1px solid #989898;
  height: 12px;
  width: 12px;
}
@media (min-width: 1440px) {
  .NewCheckbox_checkbox__2vGJ8 .NewCheckbox_checkmark__1-hCV {
    height: 16px;
    width: 16px;
  }
  .NewCheckbox_checkbox__2vGJ8 .NewCheckbox_checkmark__1-hCV:after {
    content: "";
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    background-image: url(/static/media/check-icon.6bbc8e09.svg);
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: center;
  }
}
.NewCheckbox_checkbox__2vGJ8:hover .NewCheckbox_checkmark__1-hCV {
  background-color: #ccc;
}

.NewCheckbox_filterCheckbox__1r1Vr {
  line-height: 15px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.NewCheckbox_filterCheckbox__1r1Vr input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.NewCheckbox_filterCheckbox__1r1Vr input[type=checkbox]:checked ~ .NewCheckbox_checkmark__1-hCV {
  background-color: #FF3B30;
}
.NewCheckbox_filterCheckbox__1r1Vr input[type=checkbox]:checked ~ .NewCheckbox_checkmark__1-hCV:after {
  display: block;
}
.NewCheckbox_filterCheckbox__1r1Vr .NewCheckbox_checkmark__1-hCV {
  height: 10px;
  width: 10px;
  background-color: #DFDFDF;
  border-radius: 50%;
}
@media (min-width: 1440px) {
  .NewCheckbox_filterCheckbox__1r1Vr .NewCheckbox_checkmark__1-hCV:after {
    content: "";
    display: none;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
.NewCheckbox_filterCheckbox__1r1Vr:hover .NewCheckbox_checkmark__1-hCV {
  background-color: #ccc;
}
.Multiselect_select__33orB .select__control {
  background-color: #FFFFFF;
  border: 1px solid #BBBBBB;
  border-radius: 5px;
  box-shadow: none;
}
.Multiselect_select__33orB .select__control:hover, .Multiselect_select__33orB .select__control:active {
  box-shadow: none;
  border-color: #E53935;
}
.Multiselect_select__33orB .select__option:hover, .Multiselect_select__33orB .select__option:active {
  background-color: #FAFAFA;
}
.Multiselect_select__33orB .select__option--is-focused {
  background-color: #FAFAFA;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanScene_container__KUkB- {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .MediaplanScene_container__KUkB- {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.MediaplanScene_pageContent__1h5j0 {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .MediaplanScene_pageContent__1h5j0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1440px) {
  .MediaplanScene_responsivePart__2Cwp6 {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .MediaplanScene_infoContainer__3ldT_ {
    width: 330px;
    min-width: 330px;
  }
}

@media (min-width: 1440px) {
  .MediaplanScene_descContainer__EVz0Y {
    margin-left: 112px;
    padding-top: 11px;
  }
}

.MediaplanScene_spinnerContainer__2O2fG {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MediaplanScene_flexContainer__2s1ud {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MediaplanScene_icon__dtUQ3 {
  background-image: url(/static/media/mediaplan-icon-grey.333e6b2d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 22px;
  width: 22px;
  margin-right: 14px;
}

.MediaplanScene_menuButton__1-iiQ {
  position: relative;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .MediaplanScene_menuButton__1-iiQ {
    display: none;
  }
}

.MediaplanScene_id__1WIl_ {
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: #6C6C6C;
}

.MediaplanScene_verticalCenter__2KLXS {
  display: flex;
  align-items: center;
}

.MediaplanScene_titleContainer__1yRmM {
  margin-top: 22px;
  margin-bottom: 12px;
}
@media (min-width: 1440px) {
  .MediaplanScene_titleContainer__1yRmM {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
    margin-bottom: 20px;
  }
}

.MediaplanScene_title__lSzZ8 {
  font-weight: 500;
  font-size: 19px;
  line-height: 22px;
  color: #000000;
}

.MediaplanScene_buttonsContainer__1nOXF {
  display: none;
}
@media (min-width: 1440px) {
  .MediaplanScene_buttonsContainer__1nOXF {
    display: flex;
  }
}

.MediaplanScene_button__13PgN {
  height: 36px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: linear-gradient(79.8deg, #FF1744 0%, #FF5252 100%);
  border-radius: 2px;
  margin-left: 14px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  -webkit-user-select: none;
          user-select: none;
}
@media (min-width: 768px) {
  .MediaplanScene_button__13PgN:first-child {
    margin-left: 0;
  }
}
@media (min-width: 1440px) {
  .MediaplanScene_button__13PgN:first-child {
    margin-left: 14px;
  }
}
.MediaplanScene_button__13PgN:hover {
  color: #FFFFFF;
}

.MediaplanScene_standardText__3Ts2t {
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  color: #6C6C6C;
}

.MediaplanScene_dateContainer__18ppz {
  margin-bottom: 18px;
}
@media (min-width: 1440px) {
  .MediaplanScene_dateContainer__18ppz {
    margin-bottom: 30px;
  }
}

.MediaplanScene_date__1v1qR {
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 8px;
}

.MediaplanScene_loadStatus__2huTx {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #989898;
  color: #3C2E2E;
  font-weight: 300;
  font-size: 13px;
}
@media (min-width: 1440px) {
  .MediaplanScene_loadStatus__2huTx {
    width: 40px;
    height: 40px;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
  }
}

.MediaplanScene_mediaplanContentContainer__98WKb {
  background: #FFF8F8;
  border-radius: 12px;
  border: 1px solid #FF1744;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 4px 4px rgba(255, 23, 68, 0.25);
  overflow: hidden;
  height: 92px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .MediaplanScene_mediaplanContentContainer__98WKb {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .MediaplanScene_mediaplanContentContainer__98WKb {
    margin-top: 21px;
  }
}

.MediaplanScene_mediaplanContentTitle__3JwWn {
  color: #FF1744;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
}

.MediaplanScene_mediaplanContentItem__3gixI {
  width: 50%;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  height: 48px;
  background-image: linear-gradient(286.41deg, #FF1744 0%, #FF5252 99.99%, rgba(255, 23, 68, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-user-select: none;
          user-select: none;
}
.MediaplanScene_mediaplanContentItem__3gixI:last-child {
  border-left: 1px solid #FFFFFF;
}
.MediaplanScene_mediaplanContentItem__3gixI:hover {
  color: #FFFFFF;
}

.MediaplanScene_mediaplanContentNumber__1NiOX {
  font-weight: 500;
  font-size: 32px;
  color: #FFFFFF;
}

.MediaplanScene_objectsIcon__14zlf, .MediaplanScene_recordIcon__24_KX {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 36px;
  width: 26px;
}

.MediaplanScene_objectsIcon__14zlf {
  background-image: url(/static/media/object-icon-white.e2a093e7.svg);
}

.MediaplanScene_recordIcon__24_KX {
  background-image: url(/static/media/record-icon-white.d6cdd7d6.svg);
}

.MediaplanScene_descTitle__d2UVO {
  margin-top: 21px;
  color: rgba(17, 0, 0, 0.4);
  margin-bottom: 21px;
}
@media (min-width: 1440px) {
  .MediaplanScene_descTitle__d2UVO {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
    color: #110000;
  }
}

@media (min-width: 0) and (max-width: 1440px) {
  .MediaplanScene_hideMobile__1_hSS {
    display: none;
  }
}

@media (min-width: 1440px) {
  .MediaplanScene_hideDesktop__TGVms {
    display: none;
  }
}

@media (min-width: 1440px) {
  .MediaplanScene_timelineButton__eJguF {
    width: 100%;
    margin-left: 0;
    margin-top: 36px;
  }
  .MediaplanScene_timelineButton__eJguF:hover {
    color: #FFFFFF;
  }
}

.MediaplanScene_popup__3bpGs {
  right: -16px;
  top: 30px;
}

@media (min-width: 768px) {
  .MediaplanScene_tabletColumnsContainer__JIdJ5 {
    display: flex;
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .MediaplanScene_tabletColumnsContainer__JIdJ5 {
    display: block;
    width: auto;
  }
}

@media (min-width: 768px) {
  .MediaplanScene_tabletColumn__2HKyc {
    width: 50%;
    padding-top: 22px;
  }
  .MediaplanScene_tabletColumn__2HKyc:nth-child(odd) {
    padding-right: 28px;
  }
  .MediaplanScene_tabletColumn__2HKyc:nth-child(even) {
    padding-left: 28px;
  }
}
@media (min-width: 1024px) {
  .MediaplanScene_tabletColumn__2HKyc:nth-child(odd) {
    padding-right: 100px;
  }
  .MediaplanScene_tabletColumn__2HKyc:nth-child(even) {
    padding-left: 100px;
  }
}
@media (min-width: 1440px) {
  .MediaplanScene_tabletColumn__2HKyc {
    width: auto;
    padding-top: 0;
  }
  .MediaplanScene_tabletColumn__2HKyc:nth-child(odd) {
    padding-right: 0;
  }
  .MediaplanScene_tabletColumn__2HKyc:nth-child(even) {
    padding-left: 0;
  }
}

.MediaplanScene_tabletButtons__3kK0Z {
  display: none;
}
@media (min-width: 768px) {
  .MediaplanScene_tabletButtons__3kK0Z {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
  }
}
@media (min-width: 1440px) {
  .MediaplanScene_tabletButtons__3kK0Z {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.TimelineScene_container__3d8Un {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .TimelineScene_container__3d8Un {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.TimelineScene_title__1BDEF {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 100px;
  border-bottom: 1px solid #D7D7D7;
}

.TimelineScene_hint__1ii5Y {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-left: 100px;
  color: #6C6C6C;
}
.TimelineWrapper_dateHeader__4Gyov {
  width: 100px;
}
.react-calendar-timeline .rct-header-root {
  background: transparent;
  border-bottom: 1px solid #BBBBBB;
}
.react-calendar-timeline .rct-dateHeader {
  background: transparent;
  border-bottom: none;
  border-left: 1px solid #BBBBBB;
  font-size: 16px;
  line-height: 16px;
}
.react-calendar-timeline .rct-calendar-header {
  border: none;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-bottom: 1px solid #BBBBBB;
  font-size: 14px;
  line-height: 16px;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row-even, .react-calendar-timeline .rct-sidebar .rct-sidebar-row-odd {
  background: transparent !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: transparent;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl {
  background: transparent !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl-first {
  border-left-width: 1px !important;
}
.react-calendar-timeline .rct-item {
  border-radius: 10px;
  cursor: pointer;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanCreateScene_container__2t6aE {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .MediaplanCreateScene_container__2t6aE {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.MediaplanCreateScene_form__2CEPa {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .MediaplanCreateScene_form__2CEPa {
    display: flex;
  }
}
@media (min-width: 1440px) {
  .MediaplanCreateScene_form__2CEPa {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .MediaplanCreateScene_desktopLeftCol__Hkclq {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .MediaplanCreateScene_desktopRightCol__uXXHh {
    margin-left: 128px;
    margin-top: 25px;
    width: 40%;
  }
}

.MediaplanCreateScene_nameInput__3zTPM {
  font-size: 19px;
  line-height: 22px;
  color: #000000;
  font-weight: 500;
}

.MediaplanCreateScene_objectsInfoContainer__1hYqV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.MediaplanCreateScene_objectsInfoContainer__1hYqV .MediaplanCreateScene_objectsInfo__8gR83 {
  width: 170%;
  font-size: 13px;
  line-height: 15px;
}
.MediaplanCreateScene_objectsInfoContainer__1hYqV .MediaplanCreateScene_objectsInfo__8gR83 .MediaplanCreateScene_title__1-paW {
  color: #000000;
  margin-bottom: 5px;
}
.MediaplanCreateScene_objectsInfoContainer__1hYqV .MediaplanCreateScene_objectsInfo__8gR83 .MediaplanCreateScene_value__DnoeX {
  color: #D9D9D9;
}
.MediaplanCreateScene_objectsInfoContainer__1hYqV .MediaplanCreateScene_objectsInfo__8gR83 .MediaplanCreateScene_count__33hYi {
  color: #BBBBBB;
}

.MediaplanCreateScene_buttonsContainer__ImDuk {
  margin-top: 36px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .MediaplanCreateScene_buttonsContainer__ImDuk {
    padding: 0;
    margin-top: 53px;
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .MediaplanCreateScene_createButton__3VpJ_ {
    width: 200px !important;
  }
}

@media (min-width: 1024px) {
  .MediaplanCreateScene_hideDesktop__3JhSv {
    display: none !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanEditScene_container__1Qvkr {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .MediaplanEditScene_container__1Qvkr {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.MediaplanEditScene_spinnerContainer__2xrMK {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MediaplanEditScene_form__30nGW {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .MediaplanEditScene_form__30nGW {
    padding: 0;
  }
}
.MediaplanEditScene_form__30nGW textarea {
  min-height: 287px;
}

.MediaplanEditScene_nameInput__3iHu5 {
  font-size: 19px;
  line-height: 22px;
  color: #000000;
  font-weight: 500;
}

.MediaplanEditScene_buttonsContainer__1qH_Z {
  margin-top: 12px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .MediaplanEditScene_buttonsContainer__1qH_Z {
    padding: 0;
    margin-top: 67px;
    justify-content: center;
  }
}

@media (min-width: 1440px) {
  .MediaplanEditScene_saveButton__I88dV {
    width: 200px !important;
  }
}

@media (min-width: 1440px) {
  .MediaplanEditScene_hideDesktop__2T8I5 {
    display: none !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanAddObjectsScene_container__1JR0F {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_container__1JR0F {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_controlsContainer__BXWTc {
    border-bottom: 1px solid #D7D7D7;
  }
}

.MediaplanAddObjectsScene_spinnerContainer__3BtPy {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MediaplanAddObjectsScene_searchContainer__2UTIU {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_searchContainer__2UTIU {
    padding-bottom: 20px;
  }
}

.MediaplanAddObjectsScene_searchType__37W8b {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_searchType__37W8b {
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_listContainer__1EPgb {
    padding-top: 20px;
  }
}

.MediaplanAddObjectsScene_buttonsContainer__2fTne {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  background-color: #FFFFFF;
}
.MediaplanAddObjectsScene_buttonsContainer__2fTne .MediaplanAddObjectsScene_button__1WzsQ {
  margin-left: 12px;
}
@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_buttonsContainer__2fTne {
    max-width: 320px;
    height: auto;
    padding-top: 0;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .MediaplanAddObjectsScene_hideMobile__1GpZs {
    display: none;
  }
}

@media (min-width: 1440px) {
  .MediaplanAddObjectsScene_hideDesktop__3gpkh {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.CheckboxWithLabel_container__2MABq {
  width: 100%;
  height: 32px;
  position: relative;
  padding-right: 16px;
  padding-left: 28px;
  display: flex;
  justify-content: flex-end;
  -webkit-user-select: none;
          user-select: none;
}
@media (min-width: 1440px) {
  .CheckboxWithLabel_container__2MABq {
    width: 160px;
    padding-right: 16px;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    margin-top: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.CheckboxWithLabel_checkbox__yxWAA {
  position: relative;
}
@media (min-width: 1440px) {
  .CheckboxWithLabel_checkbox__yxWAA {
    position: absolute;
    left: 8px;
  }
}

.CheckboxWithLabel_text__3hHk2 {
  display: none;
}
@media (min-width: 1440px) {
  .CheckboxWithLabel_text__3hHk2 {
    display: block;
    margin-left: 12px;
    font-size: 16px;
    line-height: 19px;
    color: #6C6C6C;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanRecordsScene_container__b5fHk {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .MediaplanRecordsScene_container__b5fHk {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .MediaplanRecordsScene_searchContainer__1PojN {
    border-bottom: 1px solid #D7D7D7;
  }
}

.MediaplanRecordsScene_search__2yBV5 {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .MediaplanRecordsScene_search__2yBV5 {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .MediaplanRecordsScene_listContainer__zXymR {
    padding-top: 20px;
  }
}

.MediaplanRecordsScene_toggleAll__2n5h_ {
  justify-content: flex-start;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordCard_card__y6VbU {
  background-color: #FAFAFA;
  height: 120px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  overflow: visible;
}
@media (min-width: 768px) {
  .RecordCard_card__y6VbU {
    width: calc((100% - 16px) / 2);
  }
  .RecordCard_card__y6VbU:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .RecordCard_card__y6VbU {
    width: calc((100% - 42px) / 2);
  }
  .RecordCard_card__y6VbU:nth-child(odd) {
    margin-right: 42px;
  }
}
@media (min-width: 1440px) {
  .RecordCard_card__y6VbU {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 190px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.RecordCard_head__2ZDDc {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
}
@media (max-width: calc(1920px - 1px)) {
  .RecordCard_head__2ZDDc {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
.RecordCard_head__2ZDDc.RecordCard_headOnline__Wb_D- {
  background-color: #FCE9D4;
}

.RecordCard_headInfo__1mVAE {
  font-size: 11px;
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .RecordCard_headInfo__1mVAE {
    font-size: 14px;
  }
}

.RecordCard_menuButton__1ImUp {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.RecordCard_icon__29Z0U {
  width: 25px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (min-width: 1440px) {
  .RecordCard_icon__29Z0U {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.RecordCard_checkboxLeftContainer__2B-Ki {
  position: relative;
  left: 14px;
  top: -16px;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .RecordCard_checkboxLeftContainer__2B-Ki {
    margin-right: 16px;
  }
}

.RecordCard_contentContainer__nxXWs {
  padding-top: 7px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .RecordCard_contentContainer__nxXWs {
    padding-top: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.RecordCard_dataContainer__3n6R1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  max-height: 50px;
}

.RecordCard_title__2j2z3 {
  color: #000000;
}
@media (min-width: 1440px) {
  .RecordCard_title__2j2z3 {
    font-size: 16px;
    line-height: 19px;
    width: 70%;
    display: block;
    min-height: 35px;
    margin-bottom: 40px;
  }
}

.RecordCard_player__3iKwZ {
  vertical-align: top;
  display: flex;
  height: 100%;
  width: 25%;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  margin-top: 16px;
}
@media (min-width: 1440px) {
  .RecordCard_player__3iKwZ {
    width: 60%;
  }
}

.RecordCard_playerIcon__2jEJr {
  height: 18px;
  width: 18px;
  background-image: url(/static/media/play-icon-active.27ea829c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.RecordCard_playerRange__3fEW6 {
  display: none;
}
@media (min-width: 1440px) {
  .RecordCard_playerRange__3fEW6 {
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    width: 70%;
    margin-left: 12px;
  }
}
.RecordCard_playerRange__3fEW6::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #E53935;
}
.RecordCard_playerRange__3fEW6::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.RecordCard_playerRange__3fEW6::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.RecordCard_playerRange__3fEW6:focus {
  outline: none;
  background: none;
}
.RecordCard_playerRange__3fEW6::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.RecordCard_playerRange__3fEW6::-ms-track {
  height: 2px;
  border: transparent;
}
.RecordCard_playerRange__3fEW6::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.RecordCard_playerRange__3fEW6::-ms-fill-lower {
  background: #E53935;
}
.RecordCard_playerRange__3fEW6::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.RecordCard_playerIconDisabled__1HOzX {
  background-image: url(/static/media/play-icon-disabled.fe71b4b5.svg);
}

.RecordCard_infoBlock__pMB11 {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  max-width: 71%;
}
@media (min-width: 1440px) {
  .RecordCard_infoBlock__pMB11 {
    max-width: 40%;
    line-height: 16px;
  }
}

.RecordCard_infoTitle__1Dece {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.RecordCard_infoValue__KIi17 {
  color: rgba(0, 0, 0, 0.5);
}

.RecordCard_recordPopup__IsjpE {
  right: -16px;
  top: 36px;
  z-index: 1000;
}
@media (min-width: 1440px) {
  .RecordCard_recordPopup__IsjpE {
    top: 36px !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanListActions_container__3nbE3 {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 1440px) {
  .MediaplanListActions_container__3nbE3 {
    width: 50%;
    justify-content: flex-end;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.MediaplanListActions_addButton__2f3vZ, .MediaplanListActions_dropdownButton__20jbA {
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}

.MediaplanListActions_addButton__2f3vZ {
  height: 44px;
  width: 44px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px;
}
@media (min-width: 1440px) {
  .MediaplanListActions_addButton__2f3vZ {
    margin-right: 25px;
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}
.MediaplanListActions_addButton__2f3vZ:hover {
  color: transparent;
}
@media (min-width: 1440px) {
  .MediaplanListActions_addButton__2f3vZ:hover {
    color: #000;
  }
}

.MediaplanListActions_dropdownButton__20jbA {
  padding: 13px 58px 14px 40px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  color: #E53935;
  background-image: url(/static/media/open-dropdown-icon.0799403a.svg);
  background-repeat: no-repeat;
  background-size: 10px 7px;
  background-position: right 40px center;
  height: 44px;
}

.MediaplanListActions_dropdownPopupContainer__34k51 {
  width: 100%;
}

.MediaplanListActions_dropdownPopup__Z_X3a {
  top: 34px !important;
  left: 0;
  border: 1px solid #D9D9D9;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}
.MediaplanListActions_dropdownPopup__Z_X3a:before {
  border: none !important;
}
.MediaplanListActions_dropdownPopup__Z_X3a:after {
  border: none !important;
}

.MediaplanListActions_popupButton__3OgB_ {
  font-size: 12px;
  line-height: 25px;
  text-align: center;
  color: #000000;
  height: 50px;
  border-top: 1px solid #B3B3B3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MediaplanListActions_popupButton__3OgB_:first-of-type {
  border-top: none;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanRecordTimeScene_container__3ErGf {
  padding-top: 20px;
  margin-bottom: 121px;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_container__3ErGf {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.MediaplanRecordTimeScene_formContainer__1t_S4 {
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_formContainer__1t_S4 {
    border-bottom: 1px solid #D7D7D7;
    padding-bottom: 20px;
  }
}

.MediaplanRecordTimeScene_formInputs__1LrQ8 {
  width: 100%;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_formInputs__1LrQ8 {
    display: flex;
    justify-content: space-between;
  }
}

.MediaplanRecordTimeScene_input__2020w:not(:first-of-type) {
  margin-top: 39px;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_input__2020w:not(:first-of-type) {
    margin-top: 0;
  }
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_input__2020w {
    padding-top: 0 !important;
    width: 450px;
  }
}

.MediaplanRecordTimeScene_addButton__1iWg0 {
  margin-top: 34px;
  width: auto;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_addButton__1iWg0 {
    width: 210px;
  }
}

.MediaplanRecordTimeScene_listContainer__1I3ip {
  padding-top: 34px;
}
@media (min-width: 1440px) {
  .MediaplanRecordTimeScene_listContainer__1I3ip {
    padding-top: 20px;
  }
}

.MediaplanRecordTimeScene_buttonContainer__2DCDT {
  display: flex;
  justify-content: center;
  margin-top: 37px;
  padding-left: calc((100% - 154px) / 2);
  padding-right: calc((100% - 154px) / 2);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RecordDateCard_card__b9KPj {
  background-color: #FAFAFA;
  height: 104px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .RecordDateCard_card__b9KPj {
    width: calc((100% - 16px) / 2);
  }
  .RecordDateCard_card__b9KPj:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .RecordDateCard_card__b9KPj {
    width: calc((100% - 40px) / 2);
  }
  .RecordDateCard_card__b9KPj:nth-child(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .RecordDateCard_card__b9KPj {
    border: 1px solid #D9D9D9;
    height: 104px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.RecordDateCard_head__26tIb {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.RecordDateCard_menuButton__11KP8 {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .RecordDateCard_menuButton__11KP8 {
    display: none;
  }
}

.RecordDateCard_popup__2rdUS {
  right: -16px;
  top: 36px;
  z-index: 1000;
}

.RecordDateCard_dataContainer__2q9wa {
  display: flex;
  max-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 16px;
}
@media (min-width: 1440px) {
  .RecordDateCard_dataContainer__2q9wa {
    padding-left: 14px;
    padding-right: 14px;
    justify-content: space-between;
  }
}

.RecordDateCard_infoContainer__1mVX6 {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.RecordDateCard_infoBlock__3uJrb {
  display: flex;
  justify-content: space-between;
  max-width: 60%;
}
.RecordDateCard_infoBlock__3uJrb:not(:first-of-type) {
  margin-top: 9px;
}
@media (min-width: 1440px) {
  .RecordDateCard_infoBlock__3uJrb {
    max-width: 40%;
    line-height: 16px;
  }
}

.RecordDateCard_infoTitle__3jBHV {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.RecordDateCard_infoValue__3zDLy {
  color: rgba(0, 0, 0, 0.5);
}

.RecordDateCard_deleteButton__2PMZV {
  display: none;
}
@media (min-width: 1440px) {
  .RecordDateCard_deleteButton__2PMZV {
    display: block;
    width: 90px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanAddRecordScene_container__29yKW {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .MediaplanAddRecordScene_container__29yKW {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .MediaplanAddRecordScene_searchContainer__1l_Dm {
    border-bottom: 1px solid #D7D7D7;
  }
}

.MediaplanAddRecordScene_search__1By0v {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .MediaplanAddRecordScene_search__1By0v {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .MediaplanAddRecordScene_listContainer__3EmFV {
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .MediaplanAddRecordScene_hideDesktop__3eE0Z {
    display: none !important;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .MediaplanAddRecordScene_hideMobile__1SlBK {
    display: none !important;
  }
}

.MediaplanAddRecordScene_buttonsContainer__2c0rX {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  background-color: #FFFFFF;
}
@media (min-width: 1440px) {
  .MediaplanAddRecordScene_buttonsContainer__2c0rX {
    max-width: 320px;
    margin-top: 0;
    height: auto;
    padding-top: 0;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanObjectsScene_container__WxkIa {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .MediaplanObjectsScene_container__WxkIa {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .MediaplanObjectsScene_controlsContainer__1aRyt {
    border-bottom: 1px solid #D7D7D7;
  }
}

.MediaplanObjectsScene_searchContainer__2uF3p {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .MediaplanObjectsScene_searchContainer__2uF3p {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 1440px) {
  .MediaplanObjectsScene_listContainer__18Tof {
    padding-top: 20px;
  }
}

.MediaplanObjectsScene_toggleAll__1-frU {
  justify-content: flex-start;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MediaplanObjectCard_card__2uBrM {
  background-color: #FAFAFA;
  height: 80px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  overflow: visible;
}
@media (min-width: 768px) {
  .MediaplanObjectCard_card__2uBrM {
    width: calc((100% - 16px) / 2);
  }
  .MediaplanObjectCard_card__2uBrM:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_card__2uBrM {
    width: calc((100% - 40px) / 2);
  }
  .MediaplanObjectCard_card__2uBrM:nth-child(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_card__2uBrM {
    border: 1px solid #D9D9D9;
    height: 104px;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.MediaplanObjectCard_head__2vp2j {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 10px;
}

.MediaplanObjectCard_icon__3V18s {
  width: 25px;
  height: 16px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/static/media/object-dark-grey-icon.8a7f039a.svg);
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_icon__3V18s {
    width: 30px;
    height: 20px;
    top: 10px;
    left: 57px;
  }
}

.MediaplanObjectCard_headInfo__3h2FQ {
  font-size: 11px;
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_headInfo__3h2FQ {
    font-size: 14px;
  }
}

.MediaplanObjectCard_checkboxContainer__3dlf4 {
  position: relative;
  left: 14px;
  top: -16px;
  margin-right: 10px;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_checkboxContainer__3dlf4 {
    margin-right: 16px;
  }
}

.MediaplanObjectCard_menuButton__St8p1 {
  height: 100%;
  width: 15px;
  position: absolute;
  right: 13px;
  top: 0;
  cursor: pointer;
  background-image: url(/static/media/dots-menu-icon.32014e18.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_menuButton__St8p1 {
    display: none;
  }
}

.MediaplanObjectCard_popup__30j_i {
  right: -16px;
  top: 36px;
  z-index: 1000;
}

.MediaplanObjectCard_dataContainer__2M0mu {
  display: flex;
  max-height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 16px;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_dataContainer__2M0mu {
    padding-left: 14px;
    padding-right: 14px;
    justify-content: space-between;
  }
}

.MediaplanObjectCard_infoContainer__2MJn1 {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.MediaplanObjectCard_infoBlock__1MD7U {
  display: flex;
  justify-content: space-between;
}
.MediaplanObjectCard_infoBlock__1MD7U:not(:first-of-type) {
  margin-top: 9px;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_infoBlock__1MD7U {
    line-height: 16px;
    font-size: 16px;
  }
}

.MediaplanObjectCard_infoTitle__1bIOu {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.MediaplanObjectCard_infoValue__2MUBF {
  color: rgba(0, 0, 0, 0.5);
}

.MediaplanObjectCard_deleteButton__E0CQO {
  display: none;
}
@media (min-width: 1440px) {
  .MediaplanObjectCard_deleteButton__E0CQO {
    display: block;
    width: 90px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PriorityCreateScene_container__3Njc3 {
  padding-top: 20px;
  min-height: 100vh;
}
@media (min-width: 1440px) {
  .PriorityCreateScene_container__3Njc3 {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .PriorityCreateScene_pageContainer__O7uFw {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
  }
}

.PriorityCreateScene_form__3ZD5d {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .PriorityCreateScene_form__3ZD5d {
    padding: 0;
    width: 50%;
  }
}
.PriorityCreateScene_form__3ZD5d textarea {
  min-height: 287px;
}

.PriorityCreateScene_input__2U1Rr {
  font-size: 13px;
}

.PriorityCreateScene_buttonsContainer__2XDzL {
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
}
@media (min-width: 768px) {
  .PriorityCreateScene_buttonsContainer__2XDzL {
    padding-top: 0;
    padding-bottom: 0;
    width: 350px;
    margin: 32px auto;
  }
}
@media (min-width: 1440px) {
  .PriorityCreateScene_buttonsContainer__2XDzL {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 13px;
  }
}

@media (min-width: 1440px) {
  .PriorityCreateScene_searchContainer__1Qg3g {
    border-bottom: 1px solid #D7D7D7;
  }
}

.PriorityCreateScene_search__NCfWm {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .PriorityCreateScene_search__NCfWm {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.PriorityCreateScene_listContainer__1FFdm {
  padding-top: 20px;
}

@media (max-width: calc(1440px - 1px)) {
  .PriorityCreateScene_hideMobile__1SF-B {
    display: none;
  }
}

@media (min-width: 1440px) {
  .PriorityCreateScene_hideDesktop__1nELQ {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PriorityRecordsScene_container__3PYMG {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_container__3PYMG {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .PriorityRecordsScene_controlsContainer__ibOBw {
    border-bottom: 1px solid #D7D7D7;
  }
}

.PriorityRecordsScene_title__wEOIz {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 6px;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_title__wEOIz {
    display: none;
  }
}

.PriorityRecordsScene_searchContainer__33F50 {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_searchContainer__33F50 {
    padding-bottom: 20px;
  }
}

.PriorityRecordsScene_searchType__2fGOd {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_searchType__2fGOd {
    padding: 0;
  }
}

.PriorityRecordsScene_listContainer__2kAeo {
  padding-top: 16px;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_listContainer__2kAeo {
    padding-top: 20px;
  }
}

.PriorityRecordsScene_buttonsContainer__2c87- {
  width: 100%;
  height: 76px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  background-color: #FFFFFF;
}
@media (min-width: 1440px) {
  .PriorityRecordsScene_buttonsContainer__2c87- {
    max-width: 320px;
    height: auto;
    padding-top: 0;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .PriorityRecordsScene_hideMobile__2aJVC {
    display: none;
  }
}

@media (min-width: 1440px) {
  .PriorityRecordsScene_hideDesktop__2LmUi {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectCard_card__1GlGT {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  height: 106px !important;
  width: 100%;
}
@media (min-width: 768px) {
  .ObjectCard_card__1GlGT.ObjectCard_responsive__3jxRx {
    width: calc((100% - 16px) / 2);
  }
  .ObjectCard_card__1GlGT.ObjectCard_responsive__3jxRx:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .ObjectCard_card__1GlGT.ObjectCard_responsive__3jxRx {
    width: calc((100% - 26px) / 2);
  }
  .ObjectCard_card__1GlGT.ObjectCard_responsive__3jxRx:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .ObjectCard_card__1GlGT {
    box-shadow: none;
    border: 1px solid #D9D9D9;
    height: 125px !important;
  }
}

.ObjectCard_headOnline__j6Uej, .ObjectCard_headOffline__38YAw {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.ObjectCard_headOnline__j6Uej {
  background-color: #7A7A7A;
  color: #FFFFFF;
}

.ObjectCard_headOnlineColorNew__17esS {
  background: #E53935;
}

.ObjectCard_headOffline__38YAw {
  background-color: #EEEEEE;
  color: #989898;
}
.ObjectCard_headOffline__38YAw .ObjectCard_icon__2iNBh {
  background-image: url(/static/media/object-icon-inactive.8a7f039a.svg);
}

.ObjectCard_icon__2iNBh {
  background-image: url(/static/media/object-icon-active.fb09f8b7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 8px;
}

.ObjectCard_status__iHEyj {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #02E30B;
  margin-right: 6px;
  margin-left: 6px;
}

.ObjectCard_contentContainer__1RqWx {
  padding: 5px 10px 13px;
}
@media (min-width: 1440px) {
  .ObjectCard_contentContainer__1RqWx {
    padding-top: 10px;
  }
}

.ObjectCard_title__Hr8bx {
  color: #000000;
}
@media (min-width: 1440px) {
  .ObjectCard_title__Hr8bx {
    font-size: 16px;
  }
}

.ObjectCard_infoBlock__2KZqY {
  margin-top: 10px;
  display: flex;
}

.ObjectCard_infoBlockPair__1ez5R {
  display: flex;
  justify-content: space-between;
}

.ObjectCard_infoTitle__1MCqa {
  color: #BBBBBB;
  margin-right: 6px;
}

.ObjectCard_infoValue__2Wezt {
  color: #7D7D7D;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BackgroundScene_container__VCV4E {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .BackgroundScene_container__VCV4E {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.BackgroundScene_spinnerContainer__1eA7A {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.BackgroundScene_content__9z80_ {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.BackgroundScene_btn__71cgP {
  display: flex;
  flex-direction: row;
  width: 10px;
  height: 10px;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  margin-right: 16px;
}

.BackgroundScene_elements__1JtPb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 21px;
}

.BackgroundScene_dates__2r0bk {
  margin-top: 48px;
  width: 100%;
}

.BackgroundScene_dateRange__1XkeW {
  display: none;
}

.BackgroundScene_calendar__3nmZ4 {
  display: block;
}

.BackgroundScene_playlistTimelineItem__19NKO {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-left: 50px;
}

.BackgroundScene_hours__2TIrk {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -25%;
  left: 0;
}

.BackgroundScene_playlistEmpty__vimM2 {
  height: 41px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #F8F8F8;
}
.BackgroundScene_playlistEmpty__vimM2:hover {
  background: #dfdfdf;
  width: 100%;
}

.BackgroundScene_add__1jmPC {
  background-color: inherit;
  outline: none;
  border: 0;
  cursor: pointer;
}

.BackgroundScene_href__dCCoI {
  width: 100%;
}

.BackgroundScene_linkPlaylist__bPuK7 {
  width: 100%;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.NewCalendar_container__30L3r {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper {
  background-color: transparent;
  border: none;
  width: 100%;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrWeekDays {
  display: none;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonthAndYearPickers {
  display: flex;
  justify-content: space-around;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrNextPrevButton {
  display: none;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth {
  width: 100%;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay {
  width: 14.2857142857% !important;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrDayWeekend {
  color: #E53935 !important;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrDayWeekend .rdrDayNumber {
  color: #E53935 !important;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrDayWeekend .rdrDayNumber span {
  color: #E53935 !important;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrDayEndPreview {
  display: none;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrDayStartPreview {
  display: none;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay .rdrSelected {
  width: 44px;
  height: 44px;
  left: calc((100% - 44px) / 2);
  border-radius: 50%;
  top: calc((44px - 100%) / 2);
  background-color: #E53935;
}
.NewCalendar_container__30L3r .rdrCalendarWrapper .rdrMonth .rdrDay :after {
  display: none;
}
.PlaylistItem_playlist__1Ckib {
  width: 100%;
  height: 41px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background: #F8F8F8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PlaylistItem_playlist__1Ckib:hover {
  background: #dfdfdf;
}

.PlaylistItem_icon__3MQ4p {
  margin-right: 10px;
}

.PlaylistItem_titleContainer__3hpNr {
  display: flex;
  align-items: center;
}
.PlaylistItem_titleContainer__3hpNr .PlaylistItem_image__3A1zO {
  margin-left: 7px;
  margin-right: 5px;
}
.PlaylistItem_titleContainer__3hpNr .PlaylistItem_image__3A1zO img {
  width: 30px;
  height: 30px;
}
.PlaylistItem_titleContainer__3hpNr p {
  cursor: pointer;
}

.PlaylistItem_buttonsContainer__7tIMW {
  display: flex;
}

.PlaylistItem_image__3A1zO {
  width: 32px;
  height: 30px;
}

.PlaylistItem_button__20Jsr {
  background-color: inherit;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 0;
}

.PlaylistItem_link__2syiI {
  width: 100%;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ListPlaylists_container__XEh4x {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .ListPlaylists_container__XEh4x {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.ListPlaylists_spinnerContainer__1m4Cc {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: calc(768px - 1px)) {
  .ListPlaylists_breadcrumbs__1Yqxs {
    padding-right: 10px;
  }
}

.ListPlaylists_search__3K2n5 {
  width: 100%;
}
@media (min-width: 1440px) {
  .ListPlaylists_search__3K2n5 {
    width: 100% !important;
  }
}

.ListPlaylists_playlists__3hv6n {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-column-gap: 38px;
  -webkit-column-gap: 38px;
          column-gap: 38px;
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .ListPlaylists_playlists__3hv6n {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media (max-width: calc(1024px - 1px)) {
  .ListPlaylists_playlists__3hv6n {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}

.ListPlaylists_playlistLink__1oqI_ {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ListPlaylists_noPlaylists__2quyu {
  padding-top: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: 0.7;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BackgroundSearch_containerSearch__1ecj0 {
  padding-top: 11px;
  padding-bottom: 25px;
}
@media (min-width: 1440px) {
  .BackgroundSearch_containerSearch__1ecj0 {
    padding-bottom: 31px;
  }
}

@media (min-width: 1440px) {
  .BackgroundSearch_controlsContainer__2Q9Yl {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.BackgroundSearch_searchContainer__TX94E {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 1440px) {
  .BackgroundSearch_searchContainer__TX94E {
    width: 60%;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.BackgroundSearch_inputContainer__3rx84 {
  flex-grow: 1;
  height: 100%;
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}

.BackgroundSearch_input__3OvMB {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  padding: 8px 30px 8px 22px;
  font-size: 14px;
  line-height: 25px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
}
.BackgroundSearch_input__3OvMB::-webkit-input-placeholder {
  color: #D9D9D9;
}
.BackgroundSearch_input__3OvMB::placeholder {
  color: #D9D9D9;
}
.BackgroundSearch_input__3OvMB:focus {
  outline: none;
  border-color: #E53935;
}

.BackgroundSearch_searchIcon__2H0Dw, .BackgroundSearch_filterIcon__2z-6x {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.BackgroundSearch_searchIcon__2H0Dw {
  background-image: url(/static/media/search-icon.bbe8b952.svg);
  position: absolute;
  right: 8px;
  top: 12px;
  height: 20px;
  width: 20px;
}

@media (min-width: 1440px) {
  .BackgroundSearch_containerFilter__1nNLK {
    width: 550px;
    right: 60px;
  }
}

.BackgroundSearch_filterIcon__2z-6x {
  margin: 11px 0 11px 16px;
  background-image: url(/static/media/icon-filter-background.70627211.svg);
  height: 22px;
  width: 22px;
}
.BackgroundSearch_filterIcon__2z-6x.BackgroundSearch_open__2VHgg {
  background-image: url(/static/media/icon-filter-background.70627211.svg);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.FilterBackground_container__W9lK6 {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  -webkit-filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));
  box-shadow: 0px;
  display: none;
  position: absolute;
  z-index: 1;
  top: 225px;
  right: 10px;
  -webkit-user-select: none;
          user-select: none;
}
.FilterBackground_container__W9lK6.FilterBackground_open__3tcDH {
  display: block;
  width: 94%;
}
@media (min-width: 768px) {
  .FilterBackground_container__W9lK6.FilterBackground_open__3tcDH {
    width: 48%;
    top: 215px;
  }
}
@media (min-width: 1024px) {
  .FilterBackground_container__W9lK6.FilterBackground_open__3tcDH {
    width: 35%;
  }
}
@media (min-width: 1440px) {
  .FilterBackground_container__W9lK6.FilterBackground_open__3tcDH {
    width: 550px;
    right: 51%;
    top: 233px;
  }
}

@media (min-width: 1440px) {
  .FilterBackground_containerFilter__29uam {
    left: 51%;
  }
}

.FilterBackground_flexContainer__2silj {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 27px;
}
@media (min-width: 1440px) {
  .FilterBackground_flexContainer__2silj {
    padding-top: 16px;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.FilterBackground_form__2Ev1U {
  width: 86%;
}
@media (min-width: 1440px) {
  .FilterBackground_form__2Ev1U {
    height: 100%;
    order: 1;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 2;
  }
}

.FilterBackground_section__ytqSb {
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  width: 100%;
}

.FilterBackground_activeSection__3IMOY {
  background-image: url(/static/media/check-icon-background.e2bac579.svg);
  background-position: right;
  background-repeat: no-repeat;
}

.FilterBackground_sortBottom__1Z8iV, .FilterBackground_sortTop__27aNW {
  background-position: left;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.FilterBackground_sortTop__27aNW {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-bottom: 3px;
}

.FilterBackground_none__3PP6U {
  opacity: 0;
}

.FilterBackground_sectionTitle__TpNva {
  margin-top: 14px;
  padding-bottom: 7px;
  border-bottom: 1px solid #D9D9D9;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.FilterBackground_filters__2dBna {
  font-size: 12px;
  margin-top: 22px;
  text-transform: uppercase;
  font-weight: 500;
  -webkit-user-select: none;
          user-select: none;
}

.FilterBackground_sectionContent__1hWUQ {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  height: 100%;
  width: 100%;
}

.FilterBackground_buttons__2vdjO {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 9px;
}

.FilterBackground_button__3qe4y {
  height: 28px;
  margin-bottom: 9px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  border-radius: 4px;
  border: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  background-color: #F4F4F4;
  outline: none;
  cursor: pointer;
}
.FilterBackground_active__1gR6h {
  color: #fff;
  background-color: #E53935;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BackgroundPlaylistItem_href__wHmW3 {
  width: 100%;
}

.BackgroundPlaylistItem_playlist__PD2Vu {
  display: flex;
  margin-bottom: 31px;
}

.BackgroundPlaylistItem_block__1ZBXP {
  display: flex;
  flex-direction: column;
}

.BackgroundPlaylistItem_image__1Xkez {
  width: 156px;
  height: 156px;
  position: relative;
}

.BackgroundPlaylistItem_imagePlaylistPlay__2Ou8B {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.BackgroundPlaylistItem_leftBlock__1qCC9 {
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
}
.BackgroundPlaylistItem_leftBlock__1qCC9 .BackgroundPlaylistItem_title__J7V0C {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 5px;
}

.BackgroundPlaylistItem_infoBlock__1xpnX {
  width: 156px;
  justify-content: space-between;
}

.BackgroundPlaylistItem_infoBlock__1xpnX,
.BackgroundPlaylistItem_infoBlockObjects__1tDYa {
  display: flex;
  flex-direction: row;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
.BackgroundPlaylistItem_infoBlock__1xpnX span,
.BackgroundPlaylistItem_infoBlockObjects__1tDYa span {
  margin-top: 0;
}
.BackgroundPlaylistItem_infoBlock__1xpnX p,
.BackgroundPlaylistItem_infoBlockObjects__1tDYa p {
  margin-top: 0;
}

.BackgroundPlaylistItem_playlistCountObjects__26gUl {
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.BackgroundPlaylistItem_icon__3YtSg {
  margin-right: 10px;
}

.BackgroundPlaylistItem_titleContainer__dviSX {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.BackgroundPlaylistItem_titleContainer__dviSX img {
  margin-right: 5px;
}

.BackgroundPlaylistItem_listContainer__SpryP {
  min-height: 0 !important;
  padding: 0 !important;
}

.BackgroundPlaylistItem_priceButton__ZeNf3 {
  width: 49px;
  height: 16px;
  background: linear-gradient(266.23deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 100px;
}
.BackgroundPlaylistItem_priceButton__ZeNf3 span {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.BackgroundPlaylistItem_colorActive__3kSb1 {
  color: #000000;
  fill: #000000;
}

.BackgroundPlaylistItem_colorInactive__CIYY- {
  color: #000000;
  fill: #000000;
  opacity: 0.5;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.MaintenanceCard_card__2uRbS {
  background-color: #FAFAFA;
  height: 126px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .MaintenanceCard_card__2uRbS {
    width: calc((100% - 16px) / 2);
  }
  .MaintenanceCard_card__2uRbS:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .MaintenanceCard_card__2uRbS {
    width: calc((100% - 40px) / 2);
  }
  .MaintenanceCard_card__2uRbS:nth-child(odd) {
    margin-right: 40px;
  }
}
@media (min-width: 1440px) {
  .MaintenanceCard_card__2uRbS {
    border: 1px solid #D9D9D9;
    height: 161px !important;
    box-shadow: none;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 30px;
  }
}

.MaintenanceCard_head__JnVg4 {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1440px) {
  .MaintenanceCard_head__JnVg4 {
    height: 38px;
    font-size: 14px;
    line-height: 16px;
  }
}

.MaintenanceCard_icon__3rAoh {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(/static/media/maintenance-card-icon.b0a2bb11.svg);
}
@media (min-width: 1440px) {
  .MaintenanceCard_icon__3rAoh {
    width: 30px;
    height: 30px;
  }
}

.MaintenanceCard_infoContainer__3M__A {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
}
@media (min-width: 1440px) {
  .MaintenanceCard_infoContainer__3M__A {
    padding-left: 18px;
    padding-right: 18px;
    justify-content: space-between;
    font-size: 16px;
    line-height: 19px;
  }
}

.MaintenanceCard_infoBlock__STjSZ {
  display: flex;
  justify-content: space-between;
}
.MaintenanceCard_infoBlock__STjSZ:not(:first-of-type) {
  margin-top: 9px;
}

.MaintenanceCard_infoTitle__14Nmf {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.MaintenanceCard_infoValue__n5CZi {
  color: rgba(0, 0, 0, 0.5);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.CalendarPlaylist_container__1oyGc {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .CalendarPlaylist_container__1oyGc {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.CalendarPlaylist_elements__1-kCb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 21px;
}

.CalendarPlaylist_btn__1zj9l {
  display: flex;
  flex-direction: row;
  width: 10px;
  height: 10px;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  margin-right: 16px;
}

.CalendarPlaylist_dates__2GqFI {
  margin-top: 48px;
  width: 100%;
}

.CalendarPlaylist_dateRange__Ht9AX {
  display: none;
}

.CalendarPlaylist_calendar__2uboX {
  display: block;
}

.CalendarPlaylist_timeBlock__1E3WF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #d9d9d9;
}

.CalendarPlaylist_choiceTime__28ULe {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CalendarPlaylist_checkboxDay__2OwMm, .CalendarPlaylist_checkboxTime__3QMtY {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.CalendarPlaylist_listOptions__1cj1m {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
}

.CalendarPlaylist_blockTime__6371b {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .CalendarPlaylist_blockTime__6371b {
    flex-direction: row;
  }
}

.CalendarPlaylist_blockCheckbox__2hJ-o {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .CalendarPlaylist_blockCheckbox__2hJ-o {
    width: 50%;
  }
}

.CalendarPlaylist_blockCheckboxTitle__1NOxZ {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.CalendarPlaylist_endBlock__2PICY {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.CalendarPlaylist_row__2pP0s {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}
.CalendarPlaylist_row__2pP0s input {
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 43.9px;
  height: 20px;
  background: #F4F4F4;
  border-radius: 5px;
  margin-right: 14px;
  margin-left: 14px;
  outline: none;
}

.CalendarPlaylist_buttons__eAlFL {
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid #d9d9d9;
  margin-top: 30px;
}
@media (max-width: calc(1024px - 1px)) {
  .CalendarPlaylist_buttons__eAlFL {
    justify-content: space-between;
  }
}

.CalendarPlaylist_button__37JsI {
  width: 100%;
  height: 62px;
  display: flex;
  flex-direction: row;
}
@media (min-width: 1440px) {
  .CalendarPlaylist_button__37JsI {
    display: none;
  }
}

.CalendarPlaylist_back__csVez, .CalendarPlaylist_ready__2LgMc, .CalendarPlaylist_save__tTdFg {
  width: 152px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  border: 0;
  cursor: pointer;
  margin-top: 11px;
}

.CalendarPlaylist_save__tTdFg {
  width: 30%;
}
@media (max-width: calc(768px - 1px)) {
  .CalendarPlaylist_save__tTdFg {
    width: 100%;
  }
}

.CalendarPlaylist_ready__2LgMc, .CalendarPlaylist_save__tTdFg {
  background: #FF3B30;
  color: #fff;
}

.CalendarPlaylist_back__csVez {
  margin-right: 10px;
}

.CalendarPlaylist_input__2mUgr {
  border: 0;
  outline: none;
  margin-left: 29px;
  margin-right: 29px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
  -webkit-user-select: none;
          user-select: none;
  padding-right: 0;
  text-align: center;
  width: 70px;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
}
@media (max-width: calc(1024px - 1px)) {
  .CalendarPlaylist_input__2mUgr {
    padding-right: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.CalendarPlaylist_input__2mUgr::-webkit-inner-spin-button, .CalendarPlaylist_input__2mUgr::-webkit-outer-spin-button, .CalendarPlaylist_input__2mUgr::-webkit-clear-button, .CalendarPlaylist_input__2mUgr::-webkit-calendar-picker-indicator {
  -webkit-user-select: none;
          user-select: none;
  -webkit-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.CalendarPlaylist_lastInput__2sF90 {
  margin-right: 0;
  width: 80px;
}

.CalendarPlaylist_listCheckbox__1LCuO {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
}
.CalendarPlaylist_listCheckbox__1LCuO:not(:last-child) {
  border-bottom: 1px solid #d9d9d9;
}

.CalendarPlaylist_dropdownIcon__36qT8 {
  background-image: url(/static/media/dropdown-icon-black.e44e7bd5.svg);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 10px;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
}
.CalendarPlaylist_dropdownIcon__36qT8.CalendarPlaylist_open__WSBbP {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
@media (min-width: 1440px) {
  .CalendarPlaylist_dropdownIcon__36qT8 {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  .CalendarPlaylist_dropdownIcon__36qT8.CalendarPlaylist_open__WSBbP {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

.CalendarPlaylist_calendarPlaylistRepeat__1j4IV {
  background: #bebdbd;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
@media (min-width: 1024px) {
  .CalendarPlaylistRepeat_container__lhXm- {
    padding-left: 32px;
  }
}
@media (min-width: 1024px) {
  .CalendarPlaylistRepeat_container__lhXm- {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding-right: 30px;
    margin-left: 28px;
    padding-bottom: 24px;
    width: 50%;
  }
}
@media (max-width: calc(1024px - 1px)) {
  .CalendarPlaylistRepeat_container__lhXm- {
    width: 100%;
  }
}

.CalendarPlaylistRepeat_title__jPReH {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.CalendarPlaylistRepeat_periodEndTitle__2hqXL {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  margin-bottom: 12px;
  margin-top: 12px;
}

.CalendarPlaylistRepeat_periodEndItem__2XuQZ {
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
}

.CalendarPlaylistRepeat_periodInput__2php2 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border: none;
  text-align: center;
  background-color: #e0e0e0;
  padding: 6px;
  border-radius: 6px;
  margin-left: 4px;
  margin-right: 4px;
  outline: none;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.CalendarPlaylistRepeat_periodInput__2php2::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
}

.CalendarPlaylistRepeat_countInput__1k_Je {
  width: 4ch;
  outline: none;
  position: relative;
}
.CalendarPlaylistRepeat_countInput__1k_Je::-webkit-outer-spin-button, .CalendarPlaylistRepeat_countInput__1k_Je::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.CalendarRepeatWeekly_title__11UkQ {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
}

.CalendarRepeatWeekly_weekDays__1QrLK {
  display: flex;
  flex-direction: row;
  padding: 8px 10px;
}

.CalendarRepeatWeekly_weekDayItem__2AlHL {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  background-color: #F4F4F4;
  text-transform: lowercase;
  cursor: pointer;
  line-height: 30;
}

.CalendarRepeatWeekly_weekDayItemActive__3UlTF {
  background-color: #FF3B30;
  color: #FFFFFF;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.CalendarRepeatMonthly_title__3BKkN {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 6px;
  margin-top: 12px;
}

.CalendarRepeatMonthly_monthRepeatSelect__WdJlW {
  display: flex;
  padding: 8px 10px;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .CalendarRepeatMonthly_monthRepeatSelect__WdJlW {
    flex-wrap: nowrap;
  }
}

.CalendarRepeatMonthly_selectionItem__1RKHZ {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: 45px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #F4F4F4;
  cursor: pointer;
}
@media (max-width: calc(1440px - 1px)) {
  .CalendarRepeatMonthly_selectionItem__1RKHZ {
    width: 40%;
  }
}

.CalendarRepeatMonthly_selectionItemActive__2zXP7 {
  color: #FFFFFF;
  background-color: #E53935;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastReferenceScene_container__3E4QP {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .BroadcastReferenceScene_container__3E4QP {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .BroadcastReferenceScene_pageTitle__2e3Kt {
    border-bottom: none;
  }
}

@media (min-width: 1440px) {
  .BroadcastReferenceScene_pageHeader__2Q2FU {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D7D7D7;
  }
}

.BroadcastReferenceScene_formControls__zLyOd {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
}
@media (min-width: 1440px) {
  .BroadcastReferenceScene_formControls__zLyOd {
    display: none;
  }
}

.BroadcastReferenceScene_formButton__-SemC {
  height: 30px;
  font-size: 14px;
  width: 110px;
}

.BroadcastReferenceScene_datepickerContainer__xVauK {
  display: none;
  flex-direction: column;
  align-items: center;
  padding-top: 14px;
  padding-left: 16px;
  padding-right: 16px;
}
.BroadcastReferenceScene_datepickerContainer__xVauK.BroadcastReferenceScene_visible__1POxE {
  display: flex;
}
@media (min-width: 1440px) {
  .BroadcastReferenceScene_datepickerContainer__xVauK {
    display: flex;
    padding-top: 0;
    width: 327px;
  }
}

.BroadcastReferenceScene_datepickerButton__1C9mJ {
  margin-top: 61px;
  width: 180px;
}

.BroadcastReferenceScene_dateInput__1YBPx {
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .BroadcastReferenceScene_dateInput__1YBPx {
    margin-top: 24px;
    max-width: 60%;
  }
}
@media (min-width: 1440px) {
  .BroadcastReferenceScene_dateInput__1YBPx {
    display: flex !important;
    align-items: baseline !important;
  }
  .BroadcastReferenceScene_dateInput__1YBPx label {
    color: #000000;
  }
  .BroadcastReferenceScene_dateInput__1YBPx input {
    text-align: center;
  }
  .BroadcastReferenceScene_dateInput__1YBPx input[type=time] {
    padding-right: 48px;
  }
}

.BroadcastReferenceScene_timePicker__1_cI3 {
  margin-top: 24px;
}
@media (min-width: 1440px) {
  .BroadcastReferenceScene_timePicker__1_cI3 {
    display: none;
  }
}

.BroadcastReferenceScene_hintText__Z-tXD {
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #8D8D8D;
  margin-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 1440px) {
  .BroadcastReferenceScene_hideDesktop__3O8Aa {
    display: none;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .BroadcastReferenceScene_hideMobile__3Ak9R {
    display: none !important;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastReference_container__3Jd8p {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
}
@media (min-width: 1440px) {
  .BroadcastReference_container__3Jd8p {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.BroadcastReferenceItem_container__1F2Ca {
  display: flex;
  align-items: center;
  height: 35px;
  padding-right: 16px;
}
.BroadcastReferenceItem_container__1F2Ca.BroadcastReferenceItem_disabled__3dB39 {
  background-color: #FFBDBB;
  height: 46px;
}
.BroadcastReferenceItem_container__1F2Ca.BroadcastReferenceItem_disabled__3dB39 .BroadcastReferenceItem_colorMarker__3HhKM {
  display: none;
}

.BroadcastReferenceItem_infoText__3G2e2 {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #000000;
  margin-left: 10px;
}

.BroadcastReferenceItem_infoTitle__2DQpN {
  flex-grow: 1.4;
}

.BroadcastReferenceItem_colorMarkerContainer__1Rb9T {
  height: 100%;
  width: 15px;
  position: relative;
}
.BroadcastReferenceItem_colorMarkerContainer__1Rb9T.BroadcastReferenceItem_first__3tkkm {
  padding-top: 10px;
  margin-bottom: -1px;
}
.BroadcastReferenceItem_colorMarkerContainer__1Rb9T.BroadcastReferenceItem_first__3tkkm .BroadcastReferenceItem_colorMarker__3HhKM {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.BroadcastReferenceItem_colorMarkerContainer__1Rb9T.BroadcastReferenceItem_last__rw26r {
  padding-bottom: 10px;
  margin-top: -1px;
}
.BroadcastReferenceItem_colorMarkerContainer__1Rb9T.BroadcastReferenceItem_last__rw26r .BroadcastReferenceItem_colorMarker__3HhKM {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.BroadcastReferenceItem_colorMarker__3HhKM {
  width: 100%;
  height: 100%;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastPauseScene_container__P3nzd {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .BroadcastPauseScene_container__P3nzd {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .BroadcastPauseScene_hideDesktop__2htvh {
    display: none;
  }
}

@media (min-width: 1440px) {
  .BroadcastPauseScene_pageContainer__2HSe7 {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1440px) {
  .BroadcastPauseScene_controlsColumn__oj7LC {
    width: 45%;
    padding-left: 48px;
    padding-right: 48px;
  }
}

.BroadcastPauseScene_broadcastPauseForm__QkJrl {
  display: none;
}
.BroadcastPauseScene_broadcastPauseForm__QkJrl.BroadcastPauseScene_visible__lKjvU {
  display: block;
}
@media (min-width: 1440px) {
  .BroadcastPauseScene_broadcastPauseForm__QkJrl {
    display: block;
  }
}

.BroadcastPauseScene_hintText__2Hchf {
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: #8D8D8D;
  margin-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
}

.BroadcastPauseScene_hintTextTitle__QXfcZ {
  color: #413333;
  font-size: 13px;
  margin-bottom: 8px;
}

.BroadcastPauseScene_formControls__1fry3 {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
}
@media (min-width: 1440px) {
  .BroadcastPauseScene_formControls__1fry3 {
    display: none;
  }
}

.BroadcastPauseScene_formButton__1-5U6 {
  height: 30px;
  font-size: 14px;
  width: 110px;
}

.BroadcastPauseScene_dateInputsContainer__3wACh {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 33px;
  padding-left: 16px;
  padding-right: 16px;
}

.BroadcastPauseScene_dateInput__222lv {
  width: 100%;
}

.BroadcastPauseScene_timePicker__1q3T6 {
  margin-top: 24px;
}

.BroadcastPauseScene_buttonContainer__1VF5S {
  padding-left: calc((100% - 180px) / 2);
  padding-right: calc((100% - 180px) / 2);
  margin-top: 50px;
}

.BroadcastPauseScene_listContainer__1Wr_P {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
}
@media (min-width: 768px) {
  .BroadcastPauseScene_listContainer__1Wr_P {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
@media (min-width: 1440px) {
  .BroadcastPauseScene_listContainer__1Wr_P {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1440px) {
  .BroadcastPauseScene_listContainer__1Wr_P {
    width: 50%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BroadcastPauseCard_card__2ngSW {
  background-color: #FAFAFA;
  height: 106px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 768px) {
  .BroadcastPauseCard_card__2ngSW {
    width: calc((100% - 16px) / 2);
  }
  .BroadcastPauseCard_card__2ngSW:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .BroadcastPauseCard_card__2ngSW {
    width: calc((100% - 30px) / 2);
  }
  .BroadcastPauseCard_card__2ngSW:nth-child(odd) {
    margin-right: 30px;
  }
}
@media (min-width: 1440px) {
  .BroadcastPauseCard_card__2ngSW {
    height: 106px !important;
    background-color: #FFFFFF;
    padding-bottom: 22px;
    margin-bottom: 13px;
  }
}

.BroadcastPauseCard_head__fG8xr {
  background-color: rgba(0, 0, 0, 0.02);
  color: #989898;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
}

.BroadcastPauseCard_icon__8PSJQ {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  background-image: url(/static/media/broadcast-card-pause-icon.1508d329.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.BroadcastPauseCard_dataContainer__2CXHO {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  position: relative;
}
@media (min-width: 1440px) {
  .BroadcastPauseCard_dataContainer__2CXHO {
    padding-left: 14px;
    padding-right: 14px;
    justify-content: space-between;
  }
}

.BroadcastPauseCard_infoContainer__HI3Fo {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.BroadcastPauseCard_infoBlock__VKoEg {
  display: flex;
  justify-content: space-between;
}
.BroadcastPauseCard_infoBlock__VKoEg:not(:first-of-type) {
  margin-top: 9px;
}

.BroadcastPauseCard_infoTitle__ZC9_x {
  color: rgba(0, 0, 0, 0.25);
  padding-right: 10px;
}

.BroadcastPauseCard_infoValue__3Sjjy {
  color: rgba(0, 0, 0, 0.5);
}

.BroadcastPauseCard_deleteButton__1DC-Z {
  position: absolute;
  height: 15px;
  width: 15px;
  top: calc(50% - 7px);
  right: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjEwLjk2IiB5MT0iMC4zNTM1NTMiIHgyPSIwLjM1MzM5NiIgeTI9IjEwLjk2MDIiIHN0cm9rZT0iYmxhY2siLz4KPGxpbmUgeDE9IjEwLjk1OTkiIHkxPSIxMC45NjAxIiB4Mj0iMC4zNTMzMjMiIHkyPSIwLjM1MzUxOCIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4K");
  cursor: pointer;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectMaintenanceScene_container__1qXpL {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceScene_container__1qXpL {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .ObjectMaintenanceScene_pageSettings__3SE3F {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7D7D7;
  }
}

.ObjectMaintenanceScene_buttonBack__1s64O {
  width: 40%;
  background: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
  margin-top: 10px;
  box-shadow: none;
  border-radius: 5px;
}
.ObjectMaintenanceScene_buttonBack__1s64O:active, .ObjectMaintenanceScene_buttonBack__1s64O:hover {
  color: #E53935;
}
@media (max-width: calc(1440px - 1px)) {
  .ObjectMaintenanceScene_buttonBack__1s64O {
    margin-left: 15px;
  }
}

.ObjectMaintenanceScene_buttonsContainer__3aHPl {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceScene_buttonsContainer__3aHPl {
    padding: 0;
    order: 2;
  }
}

.ObjectMaintenanceScene_archiveButton__2wvad {
  background-size: 30px 22px;
  background-position: 9px center;
  background-repeat: no-repeat;
  background-image: url(/static/media/maintenance-archive-icon.b9d904fe.svg);
}
@media (min-width: 1440px) {
  .ObjectMaintenanceScene_archiveButton__2wvad {
    background-position: 12px center;
    min-width: 140px;
    height: 44px;
    border-radius: 10px;
    margin-left: 15px;
    padding-left: 28px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 1440px) {
  .ObjectMaintenanceScene_addButton__1U9CA {
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    background-image: url(/static/media/plus-icon.6fd6c45d.svg);
    background-repeat: no-repeat;
    background-size: 22px;
    box-shadow: none;
    margin-right: 25px;
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
  .ObjectMaintenanceScene_addButton__1U9CA:hover {
    color: #000;
  }
}

.ObjectMaintenanceScene_dateInputContainer__1y1iT {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 20px;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceScene_dateInputContainer__1y1iT {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 1440px) {
  .ObjectMaintenanceScene_dateInput__3bheP {
    min-width: 327px;
  }
}
.ObjectMaintenanceScene_dateInput__3bheP label {
  color: #000000;
}

.ObjectMaintenanceScene_listContainer__dR5KU {
  padding-top: 16px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectMaintenanceAddScene_container__1Ub2b {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_container__1Ub2b {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.ObjectMaintenanceAddScene_spinnerContainer__1d2-f {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.ObjectMaintenanceAddScene_pageTitle__2IzI0 {
  border-bottom: none;
  margin-top: 0;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_pageTitle__2IzI0 {
    border-bottom: 1px solid #D7D7D7;
    padding-left: 0;
    padding-right: 0;
    margin-top: 20px;
  }
}

.ObjectMaintenanceAddScene_form__vdiBk {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_form__vdiBk {
    padding-left: calc((100% - 545px) / 2);
    padding-right: calc((100% - 545px) / 2);
  }
}
.ObjectMaintenanceAddScene_form__vdiBk textarea {
  min-height: 287px;
}

.ObjectMaintenanceAddScene_selectWrapper__19C7S {
  margin-top: 0;
}
.ObjectMaintenanceAddScene_selectWrapper__19C7S select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  width: 100%;
}

.ObjectMaintenanceAddScene_buttonsContainer__3XfnD {
  display: flex;
  margin-top: 48px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_buttonsContainer__3XfnD {
    justify-content: center;
  }
}

@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_submitButton__1gEC4 {
    width: 154px;
  }
}

@media (min-width: 1440px) {
  .ObjectMaintenanceAddScene_hideDesktop__3KmOt {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectPriorityAdsScene_container__3trTs {
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .ObjectPriorityAdsScene_container__3trTs {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .ObjectPriorityAdsScene_listContainer__1LpSN {
    padding-top: 24px;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectPriorityCard_card__21OR6 {
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
  padding-right: 11px;
}
@media (min-width: 768px) {
  .ObjectPriorityCard_card__21OR6 {
    width: calc((100% - 16px) / 2);
  }
  .ObjectPriorityCard_card__21OR6:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .ObjectPriorityCard_card__21OR6 {
    width: calc((100% - 33px) / 2);
  }
  .ObjectPriorityCard_card__21OR6:nth-child(odd) {
    margin-right: 33px;
  }
}
.ObjectPriorityCard_card__21OR6:not(:first-child) .ObjectPriorityCard_star__2Wkft {
  background: none;
}

.ObjectPriorityCard_head__3br7_ {
  display: none;
}

.ObjectPriorityCard_playButton__3xkns {
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #FFFFFF;
  background-image: url(/static/media/object-priority-card-play.4ac3a158.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px 20px;
}

.ObjectPriorityCard_text__3AdqG {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #989898;
  width: 169px;
  margin-left: 16px;
  flex-grow: 1;
}

.ObjectPriorityCard_star__2Wkft {
  background-image: url(/static/media/object-priority-card-star.94a45e16.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 18px;
  width: 18px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.RestorePasswordScene_backgroundContainer__1mbvw {
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(/static/media/authBackground.7b39504b.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.RestorePasswordScene_container__1JoF2 {
  position: relative;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  padding-top: 48px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RestorePasswordScene_logo__2_Q1c {
  height: 68px;
  width: 95%;
  background-image: url(/static/media/logo.4ccf4513.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.RestorePasswordScene_modalContainer__3jWJc {
  margin-top: 72px;
}

.RestorePasswordScene_formWrapper__3Sajd {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 53px;
  min-height: 130px;
}
.RestorePasswordScene_formWrapper__3Sajd.RestorePasswordScene_noMargin__1K8zG {
  margin-top: 0;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ProfileScene_container__3buLk {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .ProfileScene_container__3buLk {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.ProfileScene_spinnerContainer__2EWPq {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.ProfileScene_pageContent__1rufv {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  .ProfileScene_pageContent__1rufv {
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 1440px) {
  .ProfileScene_pageContent__1rufv {
    padding: 0;
  }
}

.ProfileScene_blockInfo__171PH {
  display: flex;
  flex-direction: column;
}
@media (max-width: calc(768px - 1px)) {
  .ProfileScene_blockInfo__171PH {
    order: 2;
  }
}
@media (min-width: 768px) {
  .ProfileScene_blockInfo__171PH {
    width: 50%;
    padding-right: 16px;
  }
}

.ProfileScene_content__2_jtk {
  height: 130px;
  border: 1px solid #FF5252;
  border-radius: 5px;
  padding: 13px 19px;
  margin-bottom: 46px;
}
.ProfileScene_content__2_jtk p {
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.ProfileScene_text__2kL6I {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  width: 100%;
}
.ProfileScene_text__2kL6I p {
  margin-right: 22px;
}

.ProfileScene_blockLinks__3fIRr {
  margin-bottom: 8px;
  padding-bottom: 6px;
}
@media (max-width: calc(1024px - 1px)) {
  .ProfileScene_blockLinks__3fIRr {
    border-bottom: 1px solid #E6E6E6;
  }
}

.ProfileScene_link__2Xi7z {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.ProfileScene_link__2Xi7z:hover {
  color: #E53935;
}
.ProfileScene_link__2Xi7z p {
  margin: 0;
  margin-left: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
.ProfileScene_linkIcon__3EfC- {
  width: 28px;
  height: 24px;
}

.ProfileScene_historyContainer__2Q1aj {
  order: 1;
}
@media (min-width: 768px) {
  .ProfileScene_historyContainer__2Q1aj {
    width: 50%;
    padding-left: 16px;
  }
}

@media (min-width: 768px) {
  .ProfileScene_historyList__D19zy {
    padding-left: 0;
    padding-right: 0;
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.ProfileScene_buttonPay__1S1PS {
  margin-bottom: 10px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.CurrentBalance_container__10xJc {
  color: #000000;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #E5E5E5;
}
@media (min-width: 768px) {
  .CurrentBalance_container__10xJc {
    height: 60px;
  }
}
@media (max-width: calc(1024px - 1px)) {
  .CurrentBalance_container__10xJc {
    border-bottom: 0px;
  }
}
@media (min-width: 1440px) {
  .CurrentBalance_container__10xJc {
    justify-content: space-between;
  }
}

.CurrentBalance_label__2LFnP {
  margin-bottom: 8px;
  width: 100%;
}

.CurrentBalance_value__2d_AD {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  width: 60%;
}

.CurrentBalance_button__1_c87 {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
}
@media (min-width: 1440px) {
  .CurrentBalance_button__1_c87 {
    border: 1px solid #000000;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
}

.CurrentBalance_buttonIcon__19uiN {
  height: 16px;
  width: 11px;
  background-image: url(/static/media/profile-balance-menu.c3cd829b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: center;
}
@media (min-width: 1440px) {
  .CurrentBalance_buttonIcon__19uiN {
    display: none;
  }
}

.CurrentBalance_buttonIconBack__3wd8c {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 12px;
}

.CurrentBalance_buttonText__xBak6 {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}
.EditInfoButton_container__RNjDW {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #181936;
  cursor: pointer;
  background-image: url(/static/media/edit-profile-icon.635f8b65.svg);
  background-position: right;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  margin-bottom: 7px;
  display: block;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BalanceHistoryCard_card__295AO {
  width: 100%;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  height: 91px;
}
@media (min-width: 768px) {
  .BalanceHistoryCard_card__295AO.BalanceHistoryCard_responsive__1x4U1 {
    width: calc((100% - 16px) / 2);
  }
  .BalanceHistoryCard_card__295AO.BalanceHistoryCard_responsive__1x4U1:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .BalanceHistoryCard_card__295AO.BalanceHistoryCard_responsive__1x4U1 {
    width: calc((100% - 32px) / 2);
  }
  .BalanceHistoryCard_card__295AO.BalanceHistoryCard_responsive__1x4U1:nth-child(odd) {
    margin-right: 32px;
  }
}

.BalanceHistoryCard_cardHead__2dEvh {
  background-color: #FFFFE7;
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.BalanceHistoryCard_cardIncome__21xwH {
  background-color: #E8EAF6;
}

.BalanceHistoryCard_cardContent__2pTCV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  line-height: 20px;
  color: #000000;
}

.BalanceHistoryCard_operationType__3RYuo {
  font-size: 13px;
}

.BalanceHistoryCard_price__1VA4k {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.BalanceHistoryCard_icon__2I6ma {
  height: 22px;
  width: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.BalanceHistoryCard_icon__2I6ma.BalanceHistoryCard_income__3enPV {
  background-image: url(/static/media/balance-income-icon.50aa8343.svg);
}
.BalanceHistoryCard_icon__2I6ma.BalanceHistoryCard_payment__3FMjy {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background-image: url(/static/media/balance-payment-icon.5d39ff1d.svg);
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BalanceHistoryFilter_container__1WyN5 {
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 15px;
  position: relative;
}

.BalanceHistoryFilter_title__7RMRf {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.BalanceHistoryFilter_filtersButton__5LTMR, .BalanceHistoryFilter_popupItem__1mdNK {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.BalanceHistoryFilter_filtersButton__5LTMR {
  cursor: pointer;
  padding-right: 16px;
  background-image: url(/static/media/select-triangle.61282738.svg);
  background-position: right 4px center;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  z-index: 9;
}

.BalanceHistoryFilter_popup__Bg4tC {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 340px;
  padding-top: 27px;
  padding-bottom: 21px;
}
.BalanceHistoryFilter_popup__Bg4tC:before, .BalanceHistoryFilter_popup__Bg4tC:after {
  display: none;
}

.BalanceHistoryFilter_popupContainer__1S-sm {
  right: -6px;
  top: -6px;
}
@media (min-width: 1440px) {
  .BalanceHistoryFilter_popupContainer__1S-sm .BalanceHistoryFilter_popup__Bg4tC {
    right: 0;
    top: 0;
  }
}

.BalanceHistoryFilter_popupItem__1mdNK {
  padding: 10px 26px;
}
.BalanceHistoryFilter_popupItem__1mdNK:not(:last-child) {
  border-bottom: 1px solid #F5F5F5;
}

.BalanceHistoryFilter_popupDates__1EPgT {
  background: #F4F4F4;
  border-radius: 5px;
  height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.BalanceHistoryFilter_dateInput__3I0sm {
  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  position: relative;
}
.BalanceHistoryFilter_dateInput__3I0sm:focus {
  outline: none;
}
.BalanceHistoryFilter_dateInput__3I0sm::-webkit-inner-spin-button, .BalanceHistoryFilter_dateInput__3I0sm::-webkit-clear-button {
  display: none;
}
.BalanceHistoryFilter_dateInput__3I0sm::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
.BalanceHistoryFilter_dateInput__3I0sm:last-child {
  text-align: right;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BalanceHistory_container__1xZ8Z {
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .BalanceHistory_container__1xZ8Z {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.BalanceHistory_spinnerContainer__1JLx4 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.BalanceHistory_payBlockInfo__2SAlV {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #F5F5F5;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
@media (max-width: calc(1024px - 1px)) {
  .BalanceHistory_payBlockInfo__2SAlV {
    max-width: 20%;
  }
}
.BalanceHistory_payBlockInfo__2SAlV p {
  font-size: 13px;
  line-height: 15px;
}
@media (max-width: calc(1024px - 1px)) {
  .BalanceHistory_payBlockInfo__2SAlV p {
    padding: 0;
    margin-right: 10px;
    font-weight: 500;
    font-size: 10px;
  }
}
.BalanceHistory_payBlockInfo__2SAlV div {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  width: 200px;
}
@media (max-width: calc(1024px - 1px)) {
  .BalanceHistory_payBlockInfo__2SAlV div {
    width: 130px;
  }
}

.BalanceHistory_active__Dq_2K {
  border-bottom: 1px solid red;
}

.BalanceHistory_listContainer__2jIc8 {
  min-height: 0 !important;
}

.BalanceHistory_payButton__3nVpv {
  background: #B6B6B6;
}

.BalanceHistory_buttons__155RM {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  border-top: 1px solid #F5F5F5;
  margin-top: 170px;
}
@media (max-width: calc(1440px - 1px)) {
  .BalanceHistory_buttons__155RM {
    margin-top: 0;
  }
}
.BalanceHistory_buttons__155RM Button {
  width: 48%;
}
@media (max-width: calc(1024px - 1px)) {
  .BalanceHistory_buttons__155RM Button {
    width: 100%;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.TechSupport_container__3uERG {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .TechSupport_container__3uERG {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 768px) {
  .TechSupport_pageContainer__3LAk4 {
    display: flex;
  }
}

.TechSupport_breadcrumbs__3dKOD {
  margin-bottom: 8px;
}

.TechSupport_spinnerContainer__250W5 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.TechSupport_formContainer__kwaG2 {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 768px) {
  .TechSupport_formContainer__kwaG2 {
    min-width: 40%;
  }
}
@media (min-width: 1440px) {
  .TechSupport_formContainer__kwaG2 {
    padding-left: 0;
    padding-right: 32px;
  }
}

.TechSupport_title__Xl_IM {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.TechSupport_techButton__3-nUC {
  box-shadow: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
}
.TechSupport_techButton__3-nUC:active, .TechSupport_techButton__3-nUC:hover {
  color: #E53935;
}
.TechSupport_techButton__3-nUC.TechSupport_primary__15PJ9 {
  background-color: #E53935;
  color: #FFFFFF;
}
.TechSupport_techButton__3-nUC.TechSupport_fullWidth__2KSb7 {
  margin: 0;
  width: 100%;
}
.TechSupport_techButton__3-nUC.TechSupport_fullWidth__2KSb7:not(:first-of-type) {
  margin-top: 16px;
}

a.TechSupport_maitenanceCard__15NjE {
  width: 100%;
}
@media (min-width: 1440px) {
  a.TechSupport_maitenanceCard__15NjE {
    margin-right: 0;
  }
}

.TechSupport_selectedObjectInfo__iRx0D {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.TechSupport_selectedObjectLabel__pLdpK {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
}

.TechSupport_selectedObjectName__35fzl {
  margin-left: 16px;
  font-weight: 400;
}

.TechSupport_stylesCard__2BW3b {
  width: 100%;
  margin-right: 0;
}

.TechSupport_selectObjectButton__2gwsy {
  border: 1px solid #FF3B30;
  border-radius: 5px;
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #FF3B30;
}
@media (min-width: 768px) {
  .TechSupport_selectObjectButton__2gwsy {
    display: none;
  }
}

.TechSupport_formElementLabel__HPO7A {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.TechSupport_selectWrapper__3XoUb {
  margin-top: 0;
}
.TechSupport_selectWrapper__3XoUb select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  width: 100%;
}

.TechSupport_textareaContainer__2U-LJ:not(:first-of-type) {
  padding-top: 0;
}

.TechSupport_textarea__2IWZJ {
  height: 170px;
}

.TechSupport_objectsListContainer__25VT6 {
  width: 100%;
  padding-bottom: 12px;
}

@media (min-width: 1440px) {
  .TechSupport_searchField__JXHvy {
    width: 100%;
  }
}

.TechSupport_maitenanceButtonContainer__1EWxB {
  padding-bottom: 16px;
}
@media (max-width: calc(1440px - 1px)) {
  .TechSupport_maitenanceButtonContainer__1EWxB {
    padding: 16px;
  }
}
.TechSupport_maitenanceButtonContainer__1EWxB a {
  font-weight: 400;
}

.TechSupport_resultContainer__2xwpv {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.TechSupport_resultTitle__3p0Lx {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.TechSupport_resultDesc__2UFUD {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.TechSupport_resultButtons__1SXmN {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .TechSupport_resultButtons__1SXmN {
    width: 40%;
  }
}

@media (max-width: calc(768px - 1px)) {
  .TechSupport_objectsListButtons__1M_-H {
    display: flex;
    align-items: center;
    height: 58px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.07);
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .TechSupport_objectsListButtons__1M_-H {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.IdeasScene_container__21Fph {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .IdeasScene_container__21Fph {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .IdeasScene_pageContainer__FQ3fg {
    display: flex;
    justify-content: center;
  }
}

.IdeasScene_breadcrumbs__1r4xh {
  margin-bottom: 8px;
}

.IdeasScene_formContainer__2mnu_ {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 1440px) {
  .IdeasScene_formContainer__2mnu_ {
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
}

.IdeasScene_formElementLabel__25JVJ {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.IdeasScene_textareaContainer__1aX0w:not(:first-of-type) {
  padding-top: 0;
}

.IdeasScene_textarea__1QR-I {
  height: 170px;
}

.IdeasScene_resultContainer__2H4EI {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.IdeasScene_resultTitle__21-0I {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.IdeasScene_resultDesc__2tM7O {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.IdeasScene_resultButtons__2BdoA {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .IdeasScene_resultButtons__2BdoA {
    width: 40%;
  }
}
.ProfileButton_profileButton__3VYf4 {
  box-shadow: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
}
.ProfileButton_profileButton__3VYf4:active, .ProfileButton_profileButton__3VYf4:hover {
  color: #E53935;
}
.ProfileButton_profileButton__3VYf4.ProfileButton_primary__3c_tW {
  background-color: #E53935;
  color: #FFFFFF;
}
.ProfileButton_profileButton__3VYf4.ProfileButton_fullWidth__3vDfY {
  margin: 0;
  width: 100%;
}
.ProfileButton_profileButton__3VYf4.ProfileButton_fullWidth__3vDfY:not(:first-of-type) {
  margin-top: 16px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.FeedbackScene_container__WqX3l {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .FeedbackScene_container__WqX3l {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .FeedbackScene_pageContainer__A6ybL {
    display: flex;
    justify-content: center;
  }
}

.FeedbackScene_breadcrumbs__3f6HL {
  margin-bottom: 8px;
}

.FeedbackScene_formContainer__2u-3J {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 1440px) {
  .FeedbackScene_formContainer__2u-3J {
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
}

.FeedbackScene_formElementLabel__3iugq {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.FeedbackScene_formInputContainer__2rG3I {
  padding-top: 0;
  margin-bottom: 16px;
}
.FeedbackScene_formInputContainer__2rG3I:not(:first-of-type) {
  padding-top: 0;
}

.FeedbackScene_formInput__1WnR1 {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}

.FeedbackScene_textareaContainer__1G6OH:not(:first-of-type) {
  padding-top: 0;
}

.FeedbackScene_textarea__1KbhP {
  height: 170px;
}

.FeedbackScene_profileButton__Pxgm3 {
  box-shadow: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
}
.FeedbackScene_profileButton__Pxgm3:active, .FeedbackScene_profileButton__Pxgm3:hover {
  color: #E53935;
}
.FeedbackScene_profileButton__Pxgm3.FeedbackScene_primary__wmUOH {
  background-color: #E53935;
  color: #FFFFFF;
}
.FeedbackScene_profileButton__Pxgm3.FeedbackScene_fullWidth__2ED0o {
  margin: 0;
  width: 100%;
}
.FeedbackScene_profileButton__Pxgm3.FeedbackScene_fullWidth__2ED0o:not(:first-of-type) {
  margin-top: 16px;
}

.FeedbackScene_resultContainer__18W7D {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.FeedbackScene_resultTitle__Pm5gV {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.FeedbackScene_resultDesc__39EiR {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.FeedbackScene_resultButtons__1Z6YR {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .FeedbackScene_resultButtons__1Z6YR {
    width: 40%;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.DocumentsScene_container__3iHtT {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .DocumentsScene_container__3iHtT {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.DocumentsScene_spinnerContainer__4O_jq {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .DocumentsScene_pageContainer__1ziKS {
    display: flex;
  }
}

.DocumentsScene_breadcrumbs__31rdZ {
  margin-bottom: 8px;
}
.Document_container__2FNtk {
  height: 46px;
  border-bottom: 1px solid #E5E5E5;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Document_linkContainer__39Mud {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Document_title__C911I {
  flex-grow: 1;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
}

.Document_documentIcon__3ycKh, .Document_openIcon__24omR {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
}

.Document_documentIcon__3ycKh {
  background-image: url(/static/media/document-icon.21b5ac98.svg);
  margin-right: 14px;
}

.Document_openIcon__24omR {
  background-image: url(/static/media/open-document-icon.1733d6db.svg);
  cursor: pointer;
  margin-left: 14px;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.EditUserDataScene_container___rnoj {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .EditUserDataScene_container___rnoj {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1024px) {
  .EditUserDataScene_pageContainer__3pX0b {
    display: flex;
  }
}

.EditUserDataScene_spinnerContainer__2CAxT {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.EditUserDataScene_formContainer__1aRDS {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 1024px) {
  .EditUserDataScene_formContainer__1aRDS {
    min-width: 50%;
  }
}
@media (min-width: 1440px) {
  .EditUserDataScene_formContainer__1aRDS {
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
}

.EditUserDataScene_formElementLabel__3sb62 {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.EditUserDataScene_formInputContainer__FHJb8 {
  padding-top: 0;
  margin-bottom: 16px;
}
.EditUserDataScene_formInputContainer__FHJb8:not(:first-of-type) {
  padding-top: 0;
}

.EditUserDataScene_formInput__hYc9S {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}

.EditUserDataScene_passwordForm__1v4Fq {
  margin-top: 28px;
  border-top: 1px solid #E5E5E5;
}

.EditUserDataScene_resultContainer__3BcqE {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.EditUserDataScene_resultTitle__11osH {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.EditUserDataScene_resultDesc__1uF1F {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.EditUserDataScene_resultButtons__3y7Ht {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .EditUserDataScene_resultButtons__3y7Ht {
    width: 40%;
  }
}

.EditUserDataScene_hintContainer__271Q2 {
  display: none;
}
@media (min-width: 1024px) {
  .EditUserDataScene_hintContainer__271Q2 {
    margin-left: 32px;
    display: block;
    max-width: 40%;
  }
}
@media (min-width: 1024px) {
  .EditUserDataScene_hintContainer__271Q2 {
    max-width: 30%;
  }
}

.EditUserDataScene_hintTitle__1udxq {
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  color: #202020;
}

.EditUserDataScene_hint__39nqm {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #505050;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BackgroundPlaylists_container__1shA_ {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .BackgroundPlaylists_container__1shA_ {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.BackgroundPlaylists_spinnerContainer__3v6sx {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.BackgroundPlaylists_link__1qTx2 {
  height: 40px;
  width: 40px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  margin-left: 30px;
  margin-top: 12px;
}
@media (max-width: calc(1440px - 1px)) {
  .BackgroundPlaylists_link__1qTx2 p {
    display: none;
  }
}
@media (min-width: 1440px) {
  .BackgroundPlaylists_link__1qTx2 {
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}
.BackgroundPlaylists_link__1qTx2:hover {
  color: #000;
}

.BackgroundPlaylists_searchContainer__2KAFA {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .BackgroundPlaylists_searchContainer__2KAFA {
    align-items: baseline;
  }
}

.BackgroundPlaylists_searchBackground__23bor {
  width: 100%;
}

.BackgroundPlaylists_playlists__2ojiB {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-column-gap: 38px;
  -webkit-column-gap: 38px;
          column-gap: 38px;
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .BackgroundPlaylists_playlists__2ojiB {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media (max-width: calc(1024px - 1px)) {
  .BackgroundPlaylists_playlists__2ojiB {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}

.BackgroundPlaylists_playlistLink__dQyEp {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.BackgroundRAO_container__3-TxA {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .BackgroundRAO_container__3-TxA {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.BackgroundRAO_spinnerContainer__2OsE8 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.BackgroundRAO_link__34Cjk {
  height: 40px;
  width: 40px;
  color: transparent;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  background-image: url(/static/media/plus-icon.6fd6c45d.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  margin-left: 30px;
  margin-top: 12px;
}
@media (max-width: calc(1440px - 1px)) {
  .BackgroundRAO_link__34Cjk p {
    display: none;
  }
}
@media (min-width: 1440px) {
  .BackgroundRAO_link__34Cjk {
    padding: 14px 30px 13px 55px;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    height: 44px;
    color: #000;
    background-position: left 15px center;
  }
}
.BackgroundRAO_link__34Cjk:hover {
  color: #000;
}

.BackgroundRAO_searchBackground__1LzVX {
  width: 100%;
}

.BackgroundRAO_searchContainer__3wrsz {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .BackgroundRAO_searchContainer__3wrsz {
    align-items: baseline;
  }
}

.BackgroundRAO_playlists__1aZQ2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-column-gap: 38px;
  -webkit-column-gap: 38px;
          column-gap: 38px;
  width: 100%;
}
@media (max-width: calc(1440px - 1px)) {
  .BackgroundRAO_playlists__1aZQ2 {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
@media (max-width: calc(1024px - 1px)) {
  .BackgroundRAO_playlists__1aZQ2 {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}

.BackgroundRAO_playlistLink__2CRkI {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PlaylistInfo_container__1J650 {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .PlaylistInfo_container__1J650 {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.PlaylistInfo_spinnerContainer__eV50P {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: calc(1024px - 1px)) {
  .PlaylistInfo_breadcrumbs__Qmia9 {
    margin-bottom: 5px;
  }
}

.PlaylistInfo_playlistInfo__2yzyX {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.PlaylistInfo_blockPlaylist__V3Bsn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.PlaylistInfo_block__2ixjL {
  display: flex;
  flex-direction: column;
}

.PlaylistInfo_image__3nbP1 {
  margin-top: 20px;
  width: 100%;
}

.PlaylistInfo_leftBlock__F9WVq {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

.PlaylistInfo_title__1iuMu {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 18px;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
}

.PlaylistInfo_titleLinks__aYW9R {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
@media (max-width: calc(768px - 1px)) {
  .PlaylistInfo_titleLinks__aYW9R {
    flex-direction: column;
  }
}
.PlaylistInfo_titleLinks__aYW9R .PlaylistInfo_link__1Qzxy {
  width: 100%;
  display: flex;
  justify-content: center;
}
.PlaylistInfo_titleLinks__aYW9R .PlaylistInfo_links__3bsYy {
  width: 100%;
  margin-left: 32px;
}
@media (max-width: calc(768px - 1px)) {
  .PlaylistInfo_titleLinks__aYW9R .PlaylistInfo_links__3bsYy {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .PlaylistInfo_text__FGGev p {
    margin-bottom: 35px;
  }
}

.PlaylistInfo_infoPlaylist__3jKer {
  display: flex;
  justify-content: space-between;
}

.PlaylistInfo_infoButton__3-2VQ {
  background: linear-gradient(266.23deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 100px;
  width: 199.03px;
  height: 39px;
  border: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
@media (max-width: calc(1024px - 1px)) {
  .PlaylistInfo_infoButton__3-2VQ {
    width: 129px;
    height: 29px;
  }
  .PlaylistInfo_infoButton__3-2VQ p {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }
}

.PlaylistInfo_infoBlock__3pqtk,
.PlaylistInfo_infoBlockObjects__31YgW,
.PlaylistInfo_play__2kRHP {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
}
.PlaylistInfo_infoBlock__3pqtk span,
.PlaylistInfo_infoBlockObjects__31YgW span,
.PlaylistInfo_play__2kRHP span {
  margin-top: 0px;
  margin-left: 4.4px;
  font-weight: 500;
}
.PlaylistInfo_infoBlock__3pqtk p,
.PlaylistInfo_infoBlockObjects__31YgW p,
.PlaylistInfo_play__2kRHP p {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.PlaylistInfo_infoBlockObjects__31YgW {
  -webkit-user-select: none;
          user-select: none;
}
.PlaylistInfo_infoBlockObjects__31YgW img {
  width: 13px;
  height: 13px;
  margin-left: 8px;
}

.PlaylistInfo_active__lajXB, .PlaylistInfo_noActive__1vS6m {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.PlaylistInfo_activeCircle__2OnI0, .PlaylistInfo_noActiveCircle__2daDd {
  margin-left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.PlaylistInfo_activeCircle__2OnI0 {
  background: #02E30B;
}

.PlaylistInfo_noActiveCircle__2daDd {
  background: #FF3B30;
}

.PlaylistInfo_icon__er-xx {
  margin-right: 10px;
}

.PlaylistInfo_player__b13jr {
  vertical-align: top;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  margin-top: 8px;
  margin-bottom: 27px;
}
@media (min-width: 1440px) {
  .PlaylistInfo_player__b13jr {
    width: 100%;
  }
}

.PlaylistInfo_imagePlaylistPlay__386x5 {
  color: #FF3B30;
}

.PlaylistInfo_playerIcon__2TCWy {
  cursor: pointer;
  height: 18px;
  width: 18px;
  background-image: url(/static/media/play-icon-active.27ea829c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
}

.PlaylistInfo_playerRange__3lE1A {
  width: 100%;
}
@media (min-width: 1440px) {
  .PlaylistInfo_playerRange__3lE1A {
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    margin-left: 12px;
  }
}
.PlaylistInfo_playerRange__3lE1A::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  background-color: #E53935;
}
.PlaylistInfo_playerRange__3lE1A::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.PlaylistInfo_playerRange__3lE1A::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  color: #E53935;
  background-color: #E53935;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-top: -5px;
  border: none;
}
.PlaylistInfo_playerRange__3lE1A:focus {
  outline: none;
  background: none;
}
.PlaylistInfo_playerRange__3lE1A::-moz-range-track {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  color: #E53935;
  background-color: #E53935;
}
.PlaylistInfo_playerRange__3lE1A::-ms-track {
  height: 2px;
  border: transparent;
}
.PlaylistInfo_playerRange__3lE1A::-ms-thumb {
  background: #E53935;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.PlaylistInfo_playerRange__3lE1A::-ms-fill-lower {
  background: #E53935;
}
.PlaylistInfo_playerRange__3lE1A::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.26);
}

.PlaylistInfo_buttonDefault__LLZxS {
  cursor: pointer;
  width: 50%;
  background-color: #FF3B30;
  color: #fff;
  border: 0;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 20px;
}
@media (max-width: calc(1024px - 1px)) {
  .PlaylistInfo_buttonDefault__LLZxS {
    width: 100%;
  }
}

.PlaylistInfo_buttonNoStart__26BcV {
  display: flex;
  align-items: center;
  width: 50%;
  background: rgba(0, 0, 0, 0.1);
  color: #B6B6B6;
  border: 0;
  height: 40px;
  border-radius: 5px;
  padding-right: 13px;
  margin-bottom: 20px;
  cursor: pointer;
}
@media (max-width: calc(1024px - 1px)) {
  .PlaylistInfo_buttonNoStart__26BcV {
    width: 100%;
  }
}
.PlaylistInfo_buttonNoStart__26BcV p {
  margin: 0 auto;
}

.PlaylistInfo_buttonRightBlock__I2iDR {
  width: 3%;
  height: 10px;
}

.PlaylistInfo_question__19dtm {
  cursor: pointer;
  color: #B6B6B6;
  border: 1px solid #9F9F9F;
  width: 13px;
  height: 13px;
  border-radius: 20px;
  font-size: 9px;
}

.PlaylistInfo_objects__2dxCW p {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  text-transform: uppercase;
}

.PlaylistInfo_listContainer__2ScUb {
  padding: 0;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PlaylistEdit_container__1Taxc {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .PlaylistEdit_container__1Taxc {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.PlaylistEdit_spinnerContainer__JzOe2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media (min-width: 1440px) {
  .PlaylistEdit_controlsContainer__1H6kL {
    border-bottom: 1px solid #D7D7D7;
  }
}

.PlaylistEdit_searchContainer__3_dhk {
  padding-bottom: 0;
}
@media (min-width: 1440px) {
  .PlaylistEdit_searchContainer__3_dhk {
    padding-bottom: 20px;
  }
}

.PlaylistEdit_searchType__hYf10 {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  background-color: transparent;
}
@media (min-width: 1440px) {
  .PlaylistEdit_searchType__hYf10 {
    padding: 0;
  }
}

@media (min-width: 1440px) {
  .PlaylistEdit_listContainer__2BhdE {
    padding-top: 20px;
  }
}

.PlaylistEdit_buttonsContainer__1E1k_ {
  width: 100%;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
  background-color: transparent;
}
@media (min-width: 1440px) {
  .PlaylistEdit_buttonsContainer__1E1k_ {
    max-width: 320px;
    height: auto;
    padding-top: 0;
  }
}

@media (max-width: calc(1440px - 1px)) {
  .PlaylistEdit_hideMobile__2vZnq {
    display: none;
  }
}

@media (min-width: 1440px) {
  .PlaylistEdit_hideDesktop__2yxK6 {
    display: none;
  }
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.ObjectEditPlaylistCard_card__39q4i {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background-color: #FAFAFA;
  height: 106px !important;
  width: 100%;
  mix-blend-mode: normal;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: normal;
}
@media (min-width: 768px) {
  .ObjectEditPlaylistCard_card__39q4i.ObjectEditPlaylistCard_responsive__1PdIG {
    width: calc((100% - 16px) / 2);
  }
  .ObjectEditPlaylistCard_card__39q4i.ObjectEditPlaylistCard_responsive__1PdIG:nth-child(odd) {
    margin-right: 16px;
  }
}
@media (min-width: 1440px) {
  .ObjectEditPlaylistCard_card__39q4i.ObjectEditPlaylistCard_responsive__1PdIG {
    width: calc((100% - 26px) / 2);
  }
  .ObjectEditPlaylistCard_card__39q4i.ObjectEditPlaylistCard_responsive__1PdIG:nth-child(odd) {
    margin-right: 26px;
  }
}
@media (min-width: 1440px) {
  .ObjectEditPlaylistCard_card__39q4i {
    box-shadow: none;
    height: 125px !important;
  }
}

.ObjectEditPlaylistCard_headOnline__3k8yf, .ObjectEditPlaylistCard_headOffline__3aTi_ {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.ObjectEditPlaylistCard_headOnline__3k8yf {
  background: #F4F4F4;
  color: #989898;
  font-size: 11px;
  line-height: 13px;
}

.ObjectEditPlaylistCard_headOffline__3aTi_ {
  background-color: #EEEEEE;
  color: #989898;
}
.ObjectEditPlaylistCard_headOffline__3aTi_ .ObjectEditPlaylistCard_icon__tnoH_ {
  background-image: url(/static/media/object-icon-inactive.8a7f039a.svg);
}

.ObjectEditPlaylistCard_icon__tnoH_ {
  background-image: url(/static/media/object-icon-active.fb09f8b7.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 23px;
  height: 19px;
  margin-right: 8px;
}

.ObjectEditPlaylistCard_status__1SZvu {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #989898;
  margin-right: 4px;
  margin-left: 4px;
}

.ObjectEditPlaylistCard_statusRate__2TMAk {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #FF3B30;
  margin-right: 6px;
  margin-left: 24px;
}

.ObjectEditPlaylistCard_rate__37pWi {
  font-size: 11px;
  line-height: 13px;
  color: #000000;
}

.ObjectEditPlaylistCard_contentContainer__3xGdl {
  padding: 5px 10px 13px;
  font-size: 12px;
  line-height: 16px;
}
@media (min-width: 1440px) {
  .ObjectEditPlaylistCard_contentContainer__3xGdl {
    padding-top: 10px;
  }
}

.ObjectEditPlaylistCard_title__1GeGm {
  color: #000000;
  font-size: 12px;
  line-height: 14px;
}

.ObjectEditPlaylistCard_infoBlock__1vpTK {
  margin-top: 10px;
  display: flex;
}

.ObjectEditPlaylistCard_infoBlockPair__1vCBu {
  display: flex;
  justify-content: space-between;
}

.ObjectEditPlaylistCard_infoTitle__CrnPk {
  color: #BBBBBB;
  margin-right: 6px;
}

.ObjectEditPlaylistCard_infoValue__1FWz1 {
  color: #7D7D7D;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.PlaylistOrder_container__2Vj87 {
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .PlaylistOrder_container__2Vj87 {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.PlaylistOrder_formContainer__xpYTs {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.PlaylistOrder_choice__sy41C {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.PlaylistOrder_choiceObjects__2PNhN {
  display: flex;
  flex-direction: column;
}

.PlaylistOrder_objects__T4Q4Z {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  margin: 0;
}

.PlaylistOrder_objectsCount__Gb48- {
  opacity: 0.5;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 15px;
}

.PlaylistOrder_choiceButton__mZUlH {
  width: 108px;
  height: 36px;
}

.PlaylistOrder_profileButton__3QItN {
  width: 100%;
}
@media (min-width: 768px) {
  .PlaylistOrder_profileButton__3QItN {
    width: 20%;
  }
}

.PlaylistOrder_resultContainer__1x2pi {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.PlaylistOrder_resultTitle__pLvZu {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.PlaylistOrder_resultDesc__3kKqR {
  width: 283px;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #6C6C6C;
  margin-top: 12px;
}

.PlaylistOrder_resultButtons__19ydX {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}

.PlaylistOrder_orderButton__oNTH5 {
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .PlaylistOrder_orderButton__oNTH5 {
    width: 343px;
  }
}

.PlaylistOrder_link__3vQVZ {
  width: 100%;
}
