.rdrCalendarWrapper {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
}

.rdrDateDisplay {
  background-color: #FFFFFF !important;
}

.rdrMonthPicker select, .rdrMonthPicker option, .rdrYearPicker select, .rdrYearPicker option {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 0 !important;
}
.rdrMonthPicker select, .rdrYearPicker select {
  text-align: right;
}
.rdrMonthPicker select:hover, .rdrYearPicker select:hover {
  background-color: #FFFFFF !important;
}

.rdrDay {
  height: 44px !important;
  width: 44px !important;
}

.rdrDayNumber span {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.867782);
}
.rdrDayNumber span:after {
  background-color: #E53935 !important;
}

.rdrStartEdge, .rdrDayStartPreview {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  left: 2px !important;
}

.rdrEndEdge, .rdrDayEndPreview {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  right: 2px !important;
}

.rdrInRange, .rdrEndEdge, .rdrStartEdge,
.rdrDayInPreview, .rdrDayStartPreview, .rdrDayEndPreview {
  top: 2px !important;
  bottom: 2px !important;
}

.rdrDayEndOfWeek .rdrInRange {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  right: 2px !important;
}

.rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  left: 2px !important;
}