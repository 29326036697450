/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 130px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.form {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .form {
    display: flex;
  }
}
@media (min-width: 1440px) {
  .form {
    padding: 0;
  }
}

@media (min-width: 1024px) {
  .desktopLeftCol {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .desktopRightCol {
    margin-left: 128px;
    margin-top: 25px;
    width: 40%;
  }
}

.nameInput {
  font-size: 19px;
  line-height: 22px;
  color: #000000;
  font-weight: 500;
}

.objectsInfoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.objectsInfoContainer .objectsInfo {
  width: 170%;
  font-size: 13px;
  line-height: 15px;
}
.objectsInfoContainer .objectsInfo .title {
  color: #000000;
  margin-bottom: 5px;
}
.objectsInfoContainer .objectsInfo .value {
  color: #D9D9D9;
}
.objectsInfoContainer .objectsInfo .count {
  color: #BBBBBB;
}

.buttonsContainer {
  margin-top: 36px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .buttonsContainer {
    padding: 0;
    margin-top: 53px;
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .createButton {
    width: 200px !important;
  }
}

@media (min-width: 1024px) {
  .hideDesktop {
    display: none !important;
  }
}