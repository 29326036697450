/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 768px) {
  .pageContainer {
    display: flex;
  }
}

.breadcrumbs {
  margin-bottom: 8px;
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.formContainer {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 768px) {
  .formContainer {
    min-width: 40%;
  }
}
@media (min-width: 1440px) {
  .formContainer {
    padding-left: 0;
    padding-right: 32px;
  }
}

.title {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}

.techButton {
  box-shadow: none;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #E53935;
  border: 1px #E53935 solid;
}
.techButton:active, .techButton:hover {
  color: #E53935;
}
.techButton.primary {
  background-color: #E53935;
  color: #FFFFFF;
}
.techButton.fullWidth {
  margin: 0;
  width: 100%;
}
.techButton.fullWidth:not(:first-of-type) {
  margin-top: 16px;
}

a.maitenanceCard {
  width: 100%;
}
@media (min-width: 1440px) {
  a.maitenanceCard {
    margin-right: 0;
  }
}

.selectedObjectInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.selectedObjectLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #000000;
}

.selectedObjectName {
  margin-left: 16px;
  font-weight: 400;
}

.stylesCard {
  width: 100%;
  margin-right: 0;
}

.selectObjectButton {
  border: 1px solid #FF3B30;
  border-radius: 5px;
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #FF3B30;
}
@media (min-width: 768px) {
  .selectObjectButton {
    display: none;
  }
}

.formElementLabel {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.selectWrapper {
  margin-top: 0;
}
.selectWrapper select {
  border-radius: 5px;
  text-align: left;
  text-align-last: left;
  width: 100%;
}

.textareaContainer:not(:first-of-type) {
  padding-top: 0;
}

.textarea {
  height: 170px;
}

.objectsListContainer {
  width: 100%;
  padding-bottom: 12px;
}

@media (min-width: 1440px) {
  .searchField {
    width: 100%;
  }
}

.maitenanceButtonContainer {
  padding-bottom: 16px;
}
@media (max-width: calc(1440px - 1px)) {
  .maitenanceButtonContainer {
    padding: 16px;
  }
}
.maitenanceButtonContainer a {
  font-weight: 400;
}

.resultContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.resultTitle {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.resultDesc {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.resultButtons {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .resultButtons {
    width: 40%;
  }
}

@media (max-width: calc(768px - 1px)) {
  .objectsListButtons {
    display: flex;
    align-items: center;
    height: 58px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.07);
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 768px) {
  .objectsListButtons {
    display: none;
  }
}