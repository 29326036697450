/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-bottom: 48px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

.objectForm {
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1024px) {
  .objectForm {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media (min-width: 1440px) {
  .objectForm {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .desktopCol {
    width: 45%;
  }
}
.desktopCol .inputWrapper label {
  color: #000000;
}

.input {
  font-size: 15px;
}

.selectWrapper select {
  width: 50%;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkboxLabel {
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
}

.computePriceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  margin-top: 18px;
}

.computePriceButton {
  width: fit-content;
}

.multiselect {
  margin-top: 18px;
}

.button {
  width: 200px;
  margin-top: 24px;
}