/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 22px;
  padding-bottom: 38px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1440px) {
  .hideDesktop {
    display: none;
  }
}

@media (min-width: 1440px) {
  .pageContainer {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 1440px) {
  .controlsColumn {
    width: 45%;
    padding-left: 48px;
    padding-right: 48px;
  }
}

.broadcastPauseForm {
  display: none;
}
.broadcastPauseForm.visible {
  display: block;
}
@media (min-width: 1440px) {
  .broadcastPauseForm {
    display: block;
  }
}

.hintText {
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: #8D8D8D;
  margin-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
}

.hintTextTitle {
  color: #413333;
  font-size: 13px;
  margin-bottom: 8px;
}

.formControls {
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
}
@media (min-width: 1440px) {
  .formControls {
    display: none;
  }
}

.formButton {
  height: 30px;
  font-size: 14px;
  width: 110px;
}

.dateInputsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 33px;
  padding-left: 16px;
  padding-right: 16px;
}

.dateInput {
  width: 100%;
}

.timePicker {
  margin-top: 24px;
}

.buttonContainer {
  padding-left: calc((100% - 180px) / 2);
  padding-right: calc((100% - 180px) / 2);
  margin-top: 50px;
}

.listContainer {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 22px;
}
@media (min-width: 768px) {
  .listContainer {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
@media (min-width: 1440px) {
  .listContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1440px) {
  .listContainer {
    width: 50%;
    height: fit-content;
  }
}