/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 21px;
  padding-right: 11px;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  background-color: #EEEEEE;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 15px;
  user-select: none;
}
@media (min-width: 1440px) {
  .button {
    background-color: transparent;
    color: #000000;
    flex-direction: column;
    margin-top: 40px;
  }
}

.dropdownIcon {
  background-image: url("../../../img/icons/dropdown-icon-black.svg");
  transform: rotate(-90deg);
  transition: transform 0.2s;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 9px;
  width: 10px;
}
.dropdownIcon.open {
  transform: rotate(0);
}
@media (min-width: 1440px) {
  .dropdownIcon {
    transform: rotate(0);
  }
  .dropdownIcon.open {
    transform: rotate(180deg);
  }
}