/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.playerContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #FF3B30;
  width: 100%;
}
.playerContainer :global(.rhap_container) {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.playerContainer :global(.rhap_container) :global(.rhap_controls-section) {
  flex: unset;
}
.playerContainer :global(.rhap_container) :global(.rhap_main-controls-button) {
  display: flex;
  align-items: center;
}
@media (min-width: 1440px) {
  .playerContainer :global(.rhap_container) :global(.rhap_main-controls-button) {
    justify-content: center;
  }
}
.playerContainer :global(.rhap_container) :global(.rhap_horizontal-reverse .rhap_controls-section) {
  margin-right: 0;
}
@media (min-width: 1440px) {
  .playerContainer :global(.rhap_container) :global(.rhap_horizontal-reverse .rhap_controls-section) {
    margin-right: 8px;
  }
}
.playerContainer :global(.rhap_container) :global(.rhap_progress-indicator), .playerContainer :global(.rhap_container) :global(.rhap_progress-filled) {
  background-color: #E53935;
}