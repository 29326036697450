/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.container {
  padding-top: 20px;
  padding-top: 20px;
}
@media (min-width: 1440px) {
  .container {
    padding-left: calc((100vw - 1150px) / 2);
    padding-right: calc((100vw - 1150px) / 2);
  }
}

@media (min-width: 1024px) {
  .pageContainer {
    display: flex;
  }
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.formContainer {
  color: #000000;
  padding: 0 16px 16px;
}
@media (min-width: 1024px) {
  .formContainer {
    min-width: 50%;
  }
}
@media (min-width: 1440px) {
  .formContainer {
    padding-left: 0;
    padding-right: 0;
    width: 50%;
  }
}

.formElementLabel {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  margin-top: 16px;
}

.formInputContainer {
  padding-top: 0;
  margin-bottom: 16px;
}
.formInputContainer:not(:first-of-type) {
  padding-top: 0;
}

.formInput {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
}

.passwordForm {
  margin-top: 28px;
  border-top: 1px solid #E5E5E5;
}

.resultContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 27px;
  width: 100%;
}

.resultTitle {
  width: 60%;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.resultDesc {
  width: 60%;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6C6C6C;
  margin-top: 12px;
}

.resultButtons {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 21px;
}
@media (min-width: 768px) {
  .resultButtons {
    width: 40%;
  }
}

.hintContainer {
  display: none;
}
@media (min-width: 1024px) {
  .hintContainer {
    margin-left: 32px;
    display: block;
    max-width: 40%;
  }
}
@media (min-width: 1024px) {
  .hintContainer {
    max-width: 30%;
  }
}

.hintTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  text-transform: uppercase;
  color: #202020;
}

.hint {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #505050;
}