@import url("https://fonts.googleapis.com/css2? family = Source + Sans + Pro: wght @ 300 & display = swap");
/*
@mixin mobile-portrait() {
    @media only screen and (orientation: landscape) and (max-width: $min-sm) {
        @content;
    }
}
*/
.href {
  width: 100%;
}

.playlist {
  display: flex;
  margin-bottom: 31px;
}

.block {
  display: flex;
  flex-direction: column;
}

.image {
  width: 156px;
  height: 156px;
  position: relative;
}

.imagePlaylistPlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.leftBlock .title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 5px;
}

.infoBlock {
  width: 156px;
  justify-content: space-between;
}

.infoBlock,
.infoBlockObjects {
  display: flex;
  flex-direction: row;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}
.infoBlock span,
.infoBlockObjects span {
  margin-top: 0;
}
.infoBlock p,
.infoBlockObjects p {
  margin-top: 0;
}

.playlistCountObjects {
  width: 13px;
  height: 13px;
  margin-right: 3px;
}

.icon {
  margin-right: 10px;
}

.titleContainer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.titleContainer img {
  margin-right: 5px;
}

.listContainer {
  min-height: 0 !important;
  padding: 0 !important;
}

.priceButton {
  width: 49px;
  height: 16px;
  background: linear-gradient(266.23deg, #FF5252 22.78%, #FF1744 80.24%), #7911CD;
  mix-blend-mode: normal;
  box-shadow: 0px 4px 15px rgba(255, 52, 75, 0.4);
  border-radius: 100px;
}
.priceButton span {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.colorActive {
  color: #000000;
  fill: #000000;
}

.colorInactive {
  color: #000000;
  fill: #000000;
  opacity: 0.5;
}